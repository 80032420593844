.jsmartIcon {
	&.jsmartMenu:before {
		filter: $fprimary;
	}
}

.jsmartRecord {
	&:before {
		filter: $fprimary;
	}
}

.jsmartChatBtn {
	background-color: $success;
	border-color: $success;

	&:hover {
		background-color: $success;
		border-color: $success;
	}
}

.dropdown-menu {
	box-shadow: $box-shadow;
	padding: 1rem 0.3rem;
	border-radius: 1.2rem;
}

.slide {
	transition: width ease-out 300ms;
}

.chatHeader,
.sendMessageModal {
	&.sendMessageModal .switchDesc {
		margin-top: 30px;
		margin-bottom: 20px;
	}
	.switchDesc {
		.wrapSwitch.toggleSwitch {
			display: flex;
			flex-direction: row;
			align-items: center;
			gap: 0.5rem;
			@media (max-width: 780px) {
				flex-direction: column;
				gap: 0.2rem;
			}

			input.form-check-input {
				margin-top: 0;
				@media (max-width: 780px) {
					transform: scale(0.7);
				}
				&:checked {
					background-color: $secondary;
					border-color: $secondary;
				}
			}

			h6 {
				max-width: 100%;
				margin-bottom: 0;
				@media (max-width: 780px) {
					width: 100%;
					font-size: 0.6rem;
					text-align: center;
					padding-inline: 10%;
					line-height: 1;
				}
			}
		}
	}
}

.chatHeader {
	position: sticky;
	top: 0;
	z-index: 1000;
	background: $white;
	padding-top: 0.6rem !important;
	padding-bottom: 0.6rem !important;
	.userDetailsChatMobile {
		display: none;
	}
	@media (max-width: 991.98px) {
		position: fixed;
		width: 100%;
		filter: drop-shadow(0px 2px 3px $lightGrey);
		padding-top: calc(0.6rem + env(safe-area-inset-top)) !important;
		.userDetailsChatMobile {
			display: block;
			&.age {
				display: inline;
			}
		}
		.headerChatName {
			font-weight: 500;
			font-size: 20px !important;
			line-height: 1.3;
			@media (max-width: 991.98px) {
				font-size: 17px !important;
			}
		}
		.userDetailsChatMobile {
			line-height: 1;
			p {
				margin-bottom: 0 !important;
			}
		}
	}
	.wrapMenuChat {
		padding-inline-end: 0.5rem;
	}
}

.chatFooter {
	.inputAndQuote {
		button.dislike {
			img {
				filter: $fdarkGrey;
			}
		}
	}
}

.user-profile-sidebar {
	.simplebar-content {
		padding: 16px 15px !important;

		.wrapMainPictureWithMainDetails {
			a {
				@media screen and (max-width: 780px) {
					color: $white;
				}
			}
		}
	}
}

.vip1 {
	&:after {
		@media screen and (max-width: 780px) {
			line-height: 1.3;
		}
	}
}

.wrapSumBtn {
	.btn.btn-sm {
		&.tagStyle {
			background-color: $white;
			color: $primary;
			border-color: $primary;
			cursor: pointer;
		}
	}
}

.listOfDesc {
	.rowOfDesc {
		.iconOfDesc {
			img {
				filter: $fprimary;
			}
		}
	}
}

div.chat-conversation {
	background: $white;

	li {
		&.template {
			.conversation-list {
				.ctext-wrap-content {
					padding-inline-start: 10px;
					padding-top: 0;
					p:nth-child(1) {
						&:before {
							filter: $fdarkGrey;
						}
					}
				}
			}

			&.right {
				.conversation-list {
					.user-chat-content {
						p:nth-child(1):before {
							filter: $finvert;
						}
					}
				}
			}
		}
		&.birthdayCake1 {
			.conversation-list {
				.ctext-wrap-content {
					padding-inline-start: 10px;
					padding-top: 0;
					p:nth-child(1) {
						&:before {
							background: url(../../images/birthday/birthdayCake1blue.png);
							background-repeat: no-repeat;
							background-position: 3px 3px;
							background-size: 1rem auto;
							filter: initial;
						}
					}
				}
			}

			&.right {
				.conversation-list {
					.user-chat-content {
						p:nth-child(1):before {
							background: url(../../images/birthday/birthdayCake1white.png);
							background-repeat: no-repeat;
							background-position: 3px;
							background-size: 1rem auto;
							filter: initial;
						}
					}
				}
			}
		}
		&.birthdayCake2 {
			.conversation-list {
				.ctext-wrap-content {
					padding-inline-start: 10px;
					padding-top: 0;
					p:nth-child(1) {
						&:before {
							background: url(../../images/birthday/birthdayCake2blue.png);
							background-repeat: no-repeat;
							background-position: 0;
							background-size: 1.5rem auto;
							filter: initial;
						}
					}
				}
			}

			&.right {
				.conversation-list {
					.user-chat-content {
						p:nth-child(1):before {
							background: url(../../images/birthday/birthdayCake2white.png);
							background-repeat: no-repeat;
							background-position: 0;
							background-size: 1.5rem auto;
							filter: initial;
						}
					}
				}
			}
		}
		&.Pesah {
			.conversation-list {
				.ctext-wrap-content {
					padding-inline-start: 10px;
					padding-top: 0;
					p:nth-child(1) {
						&:before {
							background: url(../../images/holliday/pesahBlue.png);
							background-repeat: no-repeat;
							background-position: 0;
							background-size: 1.5rem auto;
							filter: initial;
						}
					}
				}
			}
			&.right {
				.conversation-list {
					.user-chat-content {
						p:nth-child(1):before {
							background: url(../../images/holliday/pesahWhite.png);
							background-repeat: no-repeat;
							background-position: 0;
							background-size: 1.5rem auto;
							filter: initial;
						}
					}
				}
			}
		}
		&.Purim {
			.conversation-list {
				.ctext-wrap-content {
					padding-inline-start: 10px;
					padding-top: 0;
					p:nth-child(1) {
						&:before {
							background: url(../../images/holliday/purimBlue.png);
							background-repeat: no-repeat;
							background-position: 0;
							background-size: 1.5rem auto;
							filter: initial;
						}
					}
				}
			}
			&.right {
				.conversation-list {
					.user-chat-content {
						p:nth-child(1):before {
							background: url(../../images/holliday/purimWhite.png);
							background-repeat: no-repeat;
							background-position: 0;
							background-size: 1.5rem auto;
							filter: initial;
						}
					}
				}
			}
		}
		&.Shavuot {
			.conversation-list {
				.ctext-wrap-content {
					padding-inline-start: 10px;
					padding-top: 0;
					p:nth-child(1) {
						&:before {
							background: url(../../images/holliday/shavuotBlue.png);
							background-repeat: no-repeat;
							background-position: 0;
							background-size: 1.5rem auto;
							filter: initial;
						}
					}
				}
			}
			&.right {
				.conversation-list {
					.user-chat-content {
						p:nth-child(1):before {
							background: url(../../images/holliday/shavuotWhite.png);
							background-repeat: no-repeat;
							background-position: 0;
							background-size: 1.5rem auto;
							filter: initial;
						}
					}
				}
			}
		}
		&.Independence {
			.conversation-list {
				.ctext-wrap-content {
					padding-inline-start: 10px;
					padding-top: 0;
					p:nth-child(1) {
						&:before {
							background: url(../../images/holliday/independanceBlue.png);
							background-repeat: no-repeat;
							background-position: 0;
							background-size: 1.5rem auto;
							filter: initial;
						}
					}
				}
			}
			&.right {
				.conversation-list {
					.user-chat-content {
						p:nth-child(1):before {
							background: url(../../images/holliday/independanceWhite.png);
							background-repeat: no-repeat;
							background-position: 0;
							background-size: 1.5rem auto;
							filter: initial;
						}
					}
				}
			}
		}
		&.Year {
			.conversation-list {
				.ctext-wrap-content {
					padding-inline-start: 10px;
					padding-top: 0;
					p:nth-child(1) {
						&:before {
							background: url(../../images/holliday/yearBlue.png);
							background-repeat: no-repeat;
							background-position: 0;
							background-size: 1.5rem auto;
							filter: initial;
						}
					}
				}
			}
			&.right {
				.conversation-list {
					.user-chat-content {
						p:nth-child(1):before {
							background: url(../../images/holliday/yearWhite.png);
							background-repeat: no-repeat;
							background-position: 0;
							background-size: 1.5rem auto;
							filter: initial;
						}
					}
				}
			}
		}
		&.Hanuca {
			.conversation-list {
				.ctext-wrap-content {
					padding-inline-start: 10px;
					padding-top: 0;
					p:nth-child(1) {
						&:before {
							background: url(../../images/holliday/hanucaBlue.png);
							background-repeat: no-repeat;
							background-position: 0;
							background-size: 1.5rem auto;
							filter: initial;
						}
					}
				}
			}
			&.right {
				.conversation-list {
					.user-chat-content {
						p:nth-child(1):before {
							background: url(../../images/holliday/hanucaWhite.png);
							background-repeat: no-repeat;
							background-position: 0;
							background-size: 1.5rem auto;
							filter: initial;
						}
					}
				}
			}
		}
		&.Sucot {
			.conversation-list {
				.ctext-wrap-content {
					padding-inline-start: 10px;
					padding-top: 0;
					p:nth-child(1) {
						&:before {
							background: url(../../images/holliday/sucotBlue.png);
							background-repeat: no-repeat;
							background-position: 0;
							background-size: 1.5rem auto;
							filter: initial;
						}
					}
				}
			}
			&.right {
				.conversation-list {
					.user-chat-content {
						p:nth-child(1):before {
							background: url(../../images/holliday/sucotWhite.png);
							background-repeat: no-repeat;
							background-position: 0;
							background-size: 1.5rem auto;
							filter: initial;
						}
					}
				}
			}
		}
		&.Pesah1 {
			.conversation-list {
				.ctext-wrap-content {
					padding-inline-start: 10px;
					padding-top: 0;
					p:nth-child(1) {
						&:before {
							background: url(../../images/holliday/pesah1Blue.png);
							background-repeat: no-repeat;
							background-position: 0;
							background-size: 1.5rem auto;
							filter: initial;
						}
					}
				}
			}
			&.right {
				.conversation-list {
					.user-chat-content {
						p:nth-child(1):before {
							background: url(../../images/holliday/pesah1White.png);
							background-repeat: no-repeat;
							background-position: 0;
							background-size: 1.5rem auto;
							filter: initial;
						}
					}
				}
			}
		}
		&.Sucot1 {
			.conversation-list {
				.ctext-wrap-content {
					padding-inline-start: 10px;
					padding-top: 0;
					p:nth-child(1) {
						&:before {
							background: url(../../images/holliday/suco1Blue.png);
							background-repeat: no-repeat;
							background-position: 0;
							background-size: 1.5rem auto;
							filter: initial;
						}
					}
				}
			}
			&.right {
				.conversation-list {
					.user-chat-content {
						p:nth-child(1):before {
							background: url(../../images/holliday/sucot1White.png);
							background-repeat: no-repeat;
							background-position: 0;
							background-size: 1.5rem auto;
							filter: initial;
						}
					}
				}
			}
		}
		&.Shavuot1 {
			.conversation-list {
				.ctext-wrap-content {
					padding-inline-start: 10px;
					padding-top: 0;
					p:nth-child(1) {
						&:before {
							background: url(../../images/holliday/shavuot1Blue.png);
							background-repeat: no-repeat;
							background-position: 0;
							background-size: 1.5rem auto;
							filter: initial;
						}
					}
				}
			}
			&.right {
				.conversation-list {
					.user-chat-content {
						p:nth-child(1):before {
							background: url(../../images/holliday/shavuot1White.png);
							background-repeat: no-repeat;
							background-position: 0;
							background-size: 1.5rem auto;
							filter: initial;
						}
					}
				}
			}
		}
		&.Purim1 {
			.conversation-list {
				.ctext-wrap-content {
					padding-inline-start: 10px;
					padding-top: 0;
					p:nth-child(1) {
						&:before {
							background: url(../../images/holliday/purim1Blue.png);
							background-repeat: no-repeat;
							background-position: 0;
							background-size: 1.5rem auto;
							filter: initial;
						}
					}
				}
			}
			&.right {
				.conversation-list {
					.user-chat-content {
						p:nth-child(1):before {
							background: url(../../images/holliday/purim1White.png);
							background-repeat: no-repeat;
							background-position: 0;
							background-size: 1.5rem auto;
							filter: initial;
						}
					}
				}
			}
		}
		&.Hanuca1 {
			.conversation-list {
				.ctext-wrap-content {
					padding-inline-start: 10px;
					padding-top: 0;
					p:nth-child(1) {
						&:before {
							background: url(../../images/holliday/hanuca1Blue.png);
							background-repeat: no-repeat;
							background-position: 0;
							background-size: 1.5rem auto;
							filter: initial;
						}
					}
				}
			}
			&.right {
				.conversation-list {
					.user-chat-content {
						p:nth-child(1):before {
							background: url(../../images/holliday/hanuca1White.png);
							background-repeat: no-repeat;
							background-position: 0;
							background-size: 1.5rem auto;
							filter: initial;
						}
					}
				}
			}
		}
		&.Year1 {
			.conversation-list {
				.ctext-wrap-content {
					padding-inline-start: 10px;
					padding-top: 0;
					p:nth-child(1) {
						&:before {
							background: url(../../images/holliday/year1Blue.png);
							background-repeat: no-repeat;
							background-position: 0;
							background-size: 1.5rem auto;
							filter: initial;
						}
					}
				}
			}
			&.right {
				.conversation-list {
					.user-chat-content {
						p:nth-child(1):before {
							background: url(../../images/holliday/year1White.png);
							background-repeat: no-repeat;
							background-position: 0;
							background-size: 1.5rem auto;
							filter: initial;
						}
					}
				}
			}
		}
		&.Year2 {
			.conversation-list {
				.ctext-wrap-content {
					padding-inline-start: 10px;
					padding-top: 0;
					p:nth-child(1) {
						&:before {
							background: url(../../images/holliday/year2Blue.png);
							background-repeat: no-repeat;
							background-position: 0;
							background-size: 1.5rem auto;
							filter: initial;
						}
					}
				}
			}
			&.right {
				.conversation-list {
					.user-chat-content {
						p:nth-child(1):before {
							background: url(../../images/holliday/year2White.png);
							background-repeat: no-repeat;
							background-position: 0;
							background-size: 1.5rem auto;
							filter: initial;
						}
					}
				}
			}
		}
		&.Independence1 {
			.conversation-list {
				.ctext-wrap-content {
					padding-inline-start: 10px;
					padding-top: 0;
					p:nth-child(1) {
						&:before {
							background: url(../../images/holliday/independance1Blue.png);
							background-repeat: no-repeat;
							background-position: 0;
							background-size: 1.5rem auto;
							filter: initial;
						}
					}
				}
			}
			&.right {
				.conversation-list {
					.user-chat-content {
						p:nth-child(1):before {
							background: url(../../images/holliday/independance1White.png);
							background-repeat: no-repeat;
							background-position: 0;
							background-size: 1.5rem auto;
							filter: initial;
						}
					}
				}
			}
		}
	}
}

.modal-dialog.simpleDialog {
	&.spreadModal {
		.modal-content {
			@media screen and (max-width: 780px) {
				width: 90%;
				height: 90%;

				top: 5%;
			}

			.modal-header {
				.modal-title {
					h4.mainTitle {
						&:before {
							background-image: initial;
						}
					}
				}
			}
		}
	}
}

.bigSlideDialog {
	.modal-content {
		.modal-header {
			.titleBig {
				h5 {
					.jsmartDrop.bigIcon {
						&:before {
							@media screen and (max-width: 480px) {
								background-size: 70%;
								background-size: 80%;
							}
						}
					}
				}
			}
		}
	}
}

.sideBarPanel {
	.userEdit {
		.mainUserImage {
			&.hasSubscription:after {
				content: "GOLD";
			}
		}
	}
}

.bottomStrip {
	@media screen and (max-width: 780px) {
		gap: 48px;

		height: 48px !important;
	}
}

.header {
	background: $jsmartGradientHeader;

	@media screen and (max-width: 1450px) {
		padding-inline-end: 50px;
	}

	@media screen and (max-width: 1200px) {
		.nav-link {
			flex-direction: column;
		}
	}

	@media screen and (max-width: 1100px) {
		.innerHeader .nav {
			grid-gap: 1rem;
			gap: 1rem;
		}
	}

	@media screen and (max-width: 1000px) {
		.innerHeader .nav .nav-item {
			display: flex;
			align-items: center;
			.nav-link {
				height: auto;
				padding-bottom: 0;
			}
		}
	}

	@media screen and (max-width: 780px) {
		height: 80px;
		min-height: 0;
		background: $white;
		z-index: 1020;
		box-shadow: 0px 3px 1px -2px #00000033, 0px 2px 2px 0px #00000024, 0px 1px 5px 0px #0000001f;
		border-radius: 0;
		padding-inline-end: 0;
		background: $white;
		padding-bottom: 1rem;
		max-width: 100%;
		padding-inline-end: 0;
		padding-top: env(safe-area-inset-top);
		height: calc(80px + (env(safe-area-inset-top)));

		.innerHeader {
			.purchaseMinuy {
				display: none;
			}
			.siteLogo {
				display: none;
			}

			.nav {
				width: 100%;
				gap: 0;
				justify-content: space-between;
				padding-right: 0.5rem;
				padding-left: 0.5rem;
				margin-top: 1rem;

				.userTab {
					display: none;
				}

				li:nth-child(2) {
					padding-inline-start: 0;
				}

				> li:nth-child(2) {
					width: auto;
					display: flex;
				}

				> li:nth-child(3) {
					padding-inline-start: 0;
				}

				.nav-item {
					opacity: 1;
					.nav-link {
						padding-inline: 0;
						padding-block-end: 0;
						.wrapBadge {
							margin-right: 0;
							&.mobileOnly {
								display: flex;
							}
						}

						img {
							height: 80%;
						}
					}
					&.active {
					}
				}
			}
		}
	}

	.innerHeader {
		.nav-item {
			&:active,
			&:hover,
			&.active {
				.nav-link {
					p {
						@media screen and (max-width: 780px) {
							color: $primary !important;
						}

						&:before {
							background: $white;
						}
					}
				}
			}

			.nav-link {
				.wrapBadge {
					span {
						background-color: $secondary !important;

						filter: $finvert;
					}
					img {
						@media screen and (max-width: 780px) {
							filter: $fgrey;
						}
					}

					&.searchIconNav {
						&:before {
							background-image: initial;
						}
					}
				}
			}
		}
	}

	@media screen and (max-width: 780px) {
		.innerHeader .nav {
			gap: 0;
			margin-inline: 5%;
			.nav-item {
				transform: scale(1.1);
				&.active {
					.nav-link .wrapBadge {
						filter: none;
						background-image: none;
						img {
							filter: $fprimary;
						}
						span {
							filter: invert(0);
						}
					}
				}
				.nav-link p {
					font-size: 12px;
					color: $darkGrey;
					.wrapBadge span {
						span {
							filter: invert(100%);
						}
					}
				}
			}
		}
	}
}

.topBar {
	&.likedMeTopBar {
		@media screen and (max-width: 780px) {
			height: auto;
		}
	}
	Button.tagsBtn.newTag {
		@media screen and (max-width: 780px) {
			position: relative;
			&:after {
				content: "";
				background-image: url("../../images/icons/filter.svg");
				filter: $fprimary;
				position: absolute;
				width: 1.3rem;
				height: 1.5rem;
				background-repeat: no-repeat;
				background-position: center;
				top: 0;
				bottom: 0;
				margin: auto;
				background-size: 90%;
			}
		}
	}
}

.searchPage,
.likeMePage,
.matchesPage,
.viewedMePage {
	.wrapAllResults {
		.card {
			.cardImgBtnTxt {
				.vip1 {
					font-size: 19px;
					@media screen and (max-width: 780px) {
						&:after {
							line-height: initial;
							padding: initial;
							width: initial;
						}
						// &:after {
						// 	transform: rotate(-135deg) translate(45%, -56px) scale(-1);
						// }
					}
				}

				.btn-group {
					button {
						&:hover {
						}

						&.btnText {
							&.send-hello,
							&.hello-sent,
							&.chatButton {
								> div img {
									filter: $fsuccess;
								}

								&:hover > div img {
									filter: $finvert;
								}
							}

							background: $white;
							color: $success;

							&.chatButton.redirect {
								background: $white;

								@media screen and (max-width: 780px) {
									background: $white;
								}

								&:before {
									background: $success;
									color: $white;
								}
							}

							&:hover {
								color: $white;
								background-color: $success;
							}
						}

						&.like {
							img {
								filter: $fspecialBtn;
							}
						}
						&.dislike {
							img {
								filter: $fdarkGrey;
							}

							&:hover {
								background: $darkGrey;
							}
						}
					}
				}
			}
		}
	}
}

.modal-dialog.bigSlideDialog.cardUserDialog {
	.modal-content {
		.modal-header {
			background: $jsmartGradientHeader;
		}

		.modal-body {
			.galleryChat {
				.wrapBtnSend {
					.wrapTextDecoration {
						textarea.form-control {
							background-color: $mint;
						}

						button.dislike {
							img {
								filter: $fdarkGrey;
							}
						}

						textarea {
							background: $mint;
						}
					}

					> .btn {
						background-color: $success !important;
					}

					.helloAction {
						width: 60%;
						button.chatButton {
							font-size: 1rem;
							color: $white;
							width: 100%;
							border-radius: 5rem;
							display: flex;
							flex-direction: row-reverse;
							background: $success;
							border: $success 1px solid;
							justify-content: center;
							align-items: center;
							gap: 0.7rem;
							padding: 0.6rem 0;
							box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 10px 0px rgb(0 0 0 / 14%),
								0px 1px 10px 0px rgb(0 0 0 / 12%);

							@media screen and (max-width: 1450px) {
								font-size: 0.55rem;
							}
							@media screen and (max-width: 1300px) {
								font-size: 1rem;
							}
							@media screen and (max-width: 1200px) {
								font-size: 1rem;
							}
							@media screen and (max-width: 1100px) {
								font-size: 1rem;
							}
							@media screen and (max-width: 1000px) {
								font-size: 1rem;
							}
							@media screen and (max-width: 780px) {
								font-size: 1rem;
							}

							> div img {
								filter: invert(1);
								width: 80%;
								scale: -1 1;
							}
							&:hover {
								color: $success;
								background: $white;
								border: $success 1px solid;
								> div img {
									filter: $fsuccess;
								}
							}
						}
					}
				}
			}
		}
	}
}
.searchFilterWrapper {
	padding-right: 0;
	padding-left: 0;
}
.buttonsSection2 {
	width: 70%;
	display: flex;
	flex-wrap: wrap;
	gap: 8px;
	margin-top: 1rem;
	justify-content: center;
	align-items: center;
	.btn {
		width: 48%;
		border-radius: 5rem;
		background-color: $white;
		color: $primary;
		line-height: 0.9;
		padding: 0.5rem;
		border: 1px solid;
		@media (hover: hover) {
			&:hover {
				background: $primary;
				color: white;
			}
		}
		&[active="true"] {
			background-color: $primary;
			color: white;
		}
	}
}

@media screen and (max-width: 991.98px) {
	.user-profile-sidebar.user-profile-sidebarOpen {
		.buttonsActions {
			.btn-group {
				button {
					&.btnText {
						&.chatButton.redirect {
							background: $success;
							@media screen and (max-width: 780px) {
								&:hover {
									background: $success;
								}
							}
						}
					}

					&.like {
						img {
							filter: $fspecialBtn;
						}
					}

					&.dislike {
						img {
							filter: $fdarkGrey;
						}
						&:hover {
							background: $darkGrey;
						}
					}
				}
			}
		}
	}
}

.loginPagesWrapper {
	.loginPopup {
		.loginHolder {
			&.nameHolder {
				@media screen and (max-width: 780px) {
					.imageForPage.fullWidth.left {
						height: 22%;
						position: absolute;
						top: 0;
						width: 100%;
						margin: 0;
						img {
							height: 100%;
						}
					}

					.imageForPage.left {
						height: 30%;
						position: absolute;
						top: 0;
						width: 53%;
						margin: 0;
						left: 0;
						img {
							height: 100%;
						}
					}
					.innerLoginWrapper {
						padding-top: 8rem;
					}
				}
			}
			&.phoneEnter,
			&.verifyHolder {
				@media screen and (max-width: 780px) {
					.imageForPage {
						height: 15%;
						margin-top: 0;
						img {
						}
					}
				}
			}
			textarea {
				font-size: 1.6rem;
				min-height: 58px;
				border: 1px solid;
				padding: 1.5rem 1.2rem;
			}
			.form-control {
				min-height: 58px;
				padding: 1.5rem 1.2rem;
				font-size: 1.6rem;
			}

			@media screen and (max-width: 780px) {
				justify-content: flex-start;
			}

			.imageForPage {
				img {
					@media screen and (max-width: 780px) {
						height: 100%;
					}
				}
			}

			&.phoneEnter,
			&.verifyHolder,
			&.nameHolder {
				@media screen and (max-width: 780px) {
					.imageForPage {
						img {
							height: 100%;
						}
					}
				}
			}

			&.phoneEnter,
			&.verifyHolder,
			&.nameHolder {
				@media screen and (max-width: 428px) {
					padding: 7.5rem 1.8rem 1.8rem;
				}
				.imageForPage {
					@media screen and (max-width: 428px) {
						height: 7%;
						position: absolute;
						transform: translateY(-100%);
						&.left {
							left: 0;
							position: absolute;
							height: 15%;
							transform: translate(0);
							&.fullWidth {
								height: 15%;
							}
						}
					}
				}
				.innerLoginWrapper {
					height: auto;
					@media screen and (max-width: 428px) {
						padding-top: 0;
					}
					.wrapTitleOfQuest {
						margin-bottom: 2rem;

						h1 {
							@media screen and (max-width: 780px) {
								font-size: 2.7rem;
							}
							@media screen and (max-width: 428px) {
								font-size: 2.7rem;
							}
						}
						h5 {
							@media screen and (max-width: 428px) {
								font-size: 1.8rem;
							}
						}
					}
					button {
						@media screen and (max-width: 428px) {
							font-size: 2rem;
							margin-top: 3rem;
						}
					}
					.input-group {
						@media screen and (max-width: 428px) {
							span {
								font-size: 1.6rem;
							}
							input.form-control,
							input::placeholder {
								padding: 1rem 1.2rem;
								min-height: 0;
								font-size: 1.6rem;
							}
						}
					}

					.wrapInputValidation {
						margin-bottom: 1rem;
					}
				}
			}
		}
	}
}

.loginStepsClass.loginPopup {
	.imageForPage {
		&.fullWidth {
			width: calc(100% + 8rem);
			margin: 0 -8rem;
		}
	}
}

.modal {
	.subscriptionDialog {
		width: 100%;
		max-width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		min-height: 100vh;
		@media only screen and (max-width: 428px) {
			min-height: 0;
			height: 100%;
		}
		.modal-content {
			.wrapTopPopupSub {
				.imageForSub {
					padding: 1rem 0;
				}
			}

			.modal-body {
				.wrapPlans {
					@media only screen and (max-width: 428px) {
						padding: 0;
					}
				}
			}
		}
	}
}

.endeavorWrapper {
	.popover {
		.popover-inner {
			.popover-body {
				.wrapEndeavorButtons {
					button {
						background: url(../../images/icons/quotePLtr.svg);
					}
				}
			}
		}
	}
}

.modal-dialog.endeavorWrapper {
	.modal-content {
		.modal-body {
			.wrapEndeavorButtons {
				button {
					background: url(../../images/icons/quotePLtr.svg);
				}
				.birthdayCake1 {
					background: url(../../images/birthday/birthdayCake1pink.png);
					background-repeat: no-repeat;
					background-position: calc(100% - 8px) 0.17rem;
					background-size: 1.3rem auto;
					@media only screen and (max-width: 428px) {
						background-position: calc(100% - 8px) 0.5rem;
					}
				}
				.birthdayCake2 {
					background: url(../../images/birthday/birthdayCake2pink.png);
					background-repeat: no-repeat;
					background-position: calc(100% - 8px) 0.17rem;
					background-size: 1.5rem auto;
				}
				.Pesah {
					background: url(../../images/holliday/pesahPink.png);
					background-repeat: no-repeat;
					background-position: calc(100% - 10px) 0.37rem;
					background-size: 1.2rem auto;
				}
				.Pesah1 {
					background: url(../../images/holliday/pesah1Pink.png);
					background-repeat: no-repeat;
					background-position: calc(100% - 10px) 0.37rem;
					background-size: 1.2rem auto;
				}
				.Purim {
					background: url(../../images/holliday/purimPink.png);
					background-repeat: no-repeat;
					background-position: calc(100% - 8px) 0.17rem;
					background-size: 1.5rem auto;
				}
				.Purim1 {
					background: url(../../images/holliday/purim1Pink.png);
					background-repeat: no-repeat;
					background-position: calc(100% - 8px) 0.17rem;
					background-size: 1.5rem auto;
				}
				.Shavuot {
					background: url(../../images/holliday/shavuotPink.png);
					background-repeat: no-repeat;
					background-position: calc(100% - 8px) 0.17rem;
					background-size: 1.5rem auto;
				}
				.Shavuot1 {
					background: url(../../images/holliday/shavuot1Pink.png);
					background-repeat: no-repeat;
					background-position: calc(100% - 8px) 0.17rem;
					background-size: 1.5rem auto;
				}
				.Independence {
					background: url(../../images/holliday/independancePink.png);
					background-repeat: no-repeat;
					background-position: calc(100% - 8px) 0.17rem;
					background-size: 1.5rem auto;
				}
				.Independence1 {
					background: url(../../images/holliday/independance1Pink.png);
					background-repeat: no-repeat;
					background-position: calc(100% - 8px) 0.17rem;
					background-size: 1.5rem auto;
				}
				.Year {
					background: url(../../images/holliday/yearPink.png);
					background-repeat: no-repeat;
					background-position: calc(100% - 8px) 0.17rem;
					background-size: 1.5rem auto;
				}
				.Year1 {
					background: url(../../images/holliday/year1Pink.png);
					background-repeat: no-repeat;
					background-position: calc(100% - 8px) 0.17rem;
					background-size: 1.5rem auto;
				}
				.Year2 {
					background: url(../../images/holliday/year2Pink.png);
					background-repeat: no-repeat;
					background-position: calc(100% - 8px) 0.17rem;
					background-size: 1.5rem auto;
				}
				.Hanuca {
					background: url(../../images/holliday/hanucaPink.png);
					background-repeat: no-repeat;
					background-position: calc(100% - 8px) 0.17rem;
					background-size: 1.5rem auto;
				}
				.Hanuca1 {
					background: url(../../images/holliday/hanuca1Pink.png);
					background-repeat: no-repeat;
					background-position: calc(100% - 8px) 0.17rem;
					background-size: 1.5rem auto;
				}
				.Sucot {
					background: url(../../images/holliday/sucotPink.png);
					background-repeat: no-repeat;
					background-position: calc(100% - 8px) 0.17rem;
					background-size: 1.5rem auto;
				}
				.Sucot1 {
					background: url(../../images/holliday/sucot1Pink.png);
					background-repeat: no-repeat;
					background-position: calc(100% - 8px) 0.17rem;
					background-size: 1.5rem auto;
				}
			}
		}
	}
}

.loginPagesWrapper.homeConnect {
	@media screen and (max-width: 780px) {
		align-items: center;

		.siteLogo {
			width: 87%;
			height: 20vh;
		}

		.wrapImgBtn {
			.wrapGradient {
				.imgBtn {
					&:after {
						@media screen and (max-width: 420px) {
							height: 32vh;
							width: 32vh;
						}
					}
					.circleImage {
						@media screen and (max-width: 420px) {
							height: 32vh;
							width: 32vh;
						}
					}
				}
			}
		}
	}

	.modal-dialog.recievedFilter {
		.modal-content {
			.modal-body {
				.section {
					.buttonsSection2 {
						display: flex;
						flex-wrap: wrap;
						justify-content: center;
						gap: 0.6rem;
						.btn {
							border: 0px solid $textLightGrey;
							border-radius: 5rem;
							text-align: center;
							padding: 0.5rem 1rem;
							font-size: 0.9rem;
							font-weight: 500;
							filter: drop-shadow(0px 2px 3px #ccc);
							background: #fff;
							color: $primary;
							@media (hover: hover) {
								&:hover {
									background: $primary;
									color: #fff;
								}
							}
							&[active="true"] {
								background: $primary;
								color: #fff;
							}
						}
					}
				}
			}
		}
	}

	.wrapMaintenance {
		width: 100%;
		height: 100vh;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		.wrapGradient {
			width: 330px;
			height: 714px;
			background-image: $jsmartGradientMain;
			border-radius: 40px;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: flex-start;
			padding-top: 7rem;
			gap: 10%;
			@media screen and (max-width: 780px) {
				width: 100%;
				height: 100%;
				justify-content: center;
				border-radius: 0;
				padding: 0 4% 4rem 4%;
			}
			.siteLogo {
				width: 70%;
				img {
					width: 100%;
				}
			}
			.messageMain {
				padding-inline: 1rem;
				display: inline-flex;
				flex-direction: column;
				gap: 3rem;
				justify-content: center;
				align-items: center;
				h3 {
					color: $white;
					font-weight: 500;
					text-align: center;
					@media screen and (max-width: 780px) {
						font-size: 1.8rem;
					}
				}
				h4 {
					color: $white;
					font-weight: 400;
					text-align: center;
					@media screen and (max-width: 780px) {
						font-size: 1.5rem;
					}
					&.desktop {
						display: none;
						@media screen and (max-width: 780px) {
							display: block;
						}
					}
				}
				.heartWhite {
					text-align: center;
					width: 100%;
					img {
						width: 2rem;
					}
				}
				a {
					color: #fff;
					text-align: center;
					font-size: 1.6rem;
					font-weight: 500;
					text-decoration: underline;
					border-bottom: 1px solid;
					display: inline-block;
					width: fit-content;
					@media screen and (max-width: 780px) {
						font-size: 1.8rem;
					}
					&.desktop {
						display: none;
						@media screen and (max-width: 780px) {
							display: block;
						}
					}
				}
			}
		}
		&.forcePage {
			.wrapGradient {
				padding: 0 4% 5.5rem 4%;
				.messageMain {
					gap: 4rem;
					h3.noGap {
						margin-bottom: -1.8rem;
					}
					a {
						background: #fff;
						color: $primary;
						border-radius: 5rem;
						padding: 0.3rem 2.5rem;
					}
				}
			}
		}
	}

	.subscriptionLoader {
		display: flex;
		align-items: center;
		height: 100%;

		.innerMsg {
			display: flex;
			position: absolute;
			right: 0;
			justify-content: center;
			width: 100%;
			font-size: 1.2em;
			margin-bottom: 20%;
		}
	}

	.developEnv {
		z-index: 5000;
		display: block;
		position: absolute;
		bottom: 0;
		left: 0;
		height: 20px;
		width: 20px;
		color: red;
	}
}
