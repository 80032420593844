/*Ayelet*/
.popover {
  border: 1px solid #4068c08c;
  border-radius: 1.2rem;
  z-index: 1050;
}
.popover-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  .popover-header {
    border-top-left-radius: 1.2rem;
    border-top-right-radius: 1.2rem;
    background-color: $light;
    border-bottom: 0;
    width: 100%;
  }

  .popover-body {
    font-family: $font-family-base;
    color: $primary;
  }
}
