.registerSpeedDateWrapper {
	width: 100vw;
	height: 100vh;
	position: relative;
	background-color: antiquewhite;
	padding: 80px;
	.wrapDescAndRegBtn {
		display: flex;
		justify-content: center;
		justify-content: space-around;
		gap: 30px;
		.wrapRegDesc {
			margin: 30px 0;
			border: 1px solid;
			padding: 20px;
			width: 100%;
		}
		.wrapRegSpeed {
			width: 100%;
			margin: 30px 0;
			padding: 20px;
			border: 1px solid;
			.regSpeedBtn {
				background-color: rgb(255, 84, 113);
				color: #fff;
				&:hover {
					background-color: #000;
				}
			}
		}
	}

	.verificationSpeedDateWrapper {
		.regSpeedBtn {
			color: #fff;
			background-color: pink;
			margin: 10px;
			&:hover {
				background-color: #000;
			}
		}
	}
}
