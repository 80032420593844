@font-face {
	font-family: "gveret_levin_alefalefalefRg";
	src: url("../../fonts/gveret_levin_alefalefalef_regular-webfont.ttf") format("TrueType");
	font-weight: normal;
	font-style: normal;
}

body {
	font-family: $font-family-base;
	background-color: $white;
}

/* width */
::-webkit-scrollbar {
	background-color: transparent !important;
	width: 0px;
	border-radius: 5rem;
	@media screen and (max-width: 780px) {
		display: none;
		appearance: none;
		-webkit-appearance: none;
	}
}

/* Track */
::-webkit-scrollbar-track {
	background-color: transparent !important;
	width: 0px;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background-color: transparent !important;
	width: 0px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background-color: transparent !important;
	width: 0px;
}

::-webkit-scrollbar {
	width: 0;
	height: 0;
	@media screen and (max-width: 780px) {
		display: none;
	}
}
::-webkit-scrollbar-button:start:decrement,
::-webkit-scrollbar-button:end:increment {
	display: none;
}

::-webkit-scrollbar-track-piece {
	background-color: transparent;
}

::-webkit-scrollbar-thumb:vertical {
	background: transparent;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: $font-family-base;
	font-weight: $font-weight-normal;
	color: $primary;
}

p {
	font-weight: $font-weight-thin;
	color: $primary;
}

.conversation-list p,
.conversation-list span {
	font-weight: $font-weight-thin;
	color: $primary;
	text-align: left;
	&.p-link {
		cursor: pointer;
		text-decoration: underline;
	}
}

.right .conversation-list p,
.right .conversation-list span {
	color: $white;
}

.bg-light {
	background-color: $mint !important;
}

.border-light {
	border-color: $mint !important;
}

.form-control {
	border-radius: 5rem;
}
.wrapMailVerified {
	.wrapInputValidation {
		.mb-3 {
			display: flex;
			flex-direction: column;

			.vi__wrapper {
				width: 100%;
				margin: 10px 0;
				.vi__container {
					height: 60px;
					width: 100%;
					display: flex;
					gap: 20px;
					direction: ltr;
					padding: 0 20px 10px;
					border-radius: 20px;
					box-shadow: 1px 1px 10px 2px#00000020;
					.character {
						color: #b1b1b1;
						border-bottom: 1px #b1b1b1 solid !important;
					}

					.vi__character {
						margin: 0;
						font-size: 1.8rem;
						border: 0px;
						border-bottom: 1px #b1b1b1 solid;
						border-radius: 0px;
						line-height: 60px;
						color: #b1b1b1;
					}

					.vi__character--inactive {
						color: #b1b1b1;
						border-bottom: 1px #b1b1b1 solid;
					}
					.vi__character--inactive--default {
						border-bottom: 1px #b1b1b1 solid;
						background-color: inherit;
						opacity: 0.5;
					}

					.vi__character--selected {
						outline: 0px solid #b1b1b1;
						color: #b1b1b1;
						border-bottom: 1px #b1b1b1 solid;
						background-color: #f1f6f7;
					}
					.vi__character--selected--default {
						border-bottom: 1px #b1b1b1 solid;
						background-color: inherit;
						outline: 0px;
					}
				}
			}
		}
	}
}

.dropdown-item {
	display: flex;
	justify-content: flex-start;
	padding-inline-start: 2.5rem;
}

/*new icons*/

.jsmartIcon {
	&:before {
		content: "";
		background-repeat: no-repeat;
		background-position: center;
		background-size: auto 19px;
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
	}

	&.jsmartMenu:before {
		background-image: url("../../images/icons/menu.svg");
	}

	&.jsmartUser:before {
		background-image: url("../../images/icons/user.svg");
	}

	&.jsmartVideo:before {
		background-image: url("../../images/icons/video.svg");
		background-size: 21px;
	}

	&.jsmartPhone:before {
		background-image: url("../../images/icons/phone.svg");
		background-size: 17px;
	}
}

.jsmartDrop {
	position: relative;
	width: 1.4rem;
	height: 1.3rem;

	&:before {
		content: "";
		background-repeat: no-repeat;
		background-position: center;
		background-size: contain;
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		transform: translateX(-35%);
	}

	&.jsmartDislike:before {
		background-image: url("../../images/icons/brokenHeart1.svg");
		background-size: contain;
		filter: $fprimary;
	}

	&.jsmartBlock:before {
		background-image: url("../../images/icons/block.svg");
		background-size: 85%;
		filter: $fprimary;
	}

	&.jsmartReport:before {
		background-image: url("../../images/icons/report.svg");
		filter: $fprimary;
	}
}

.jsmartRecord {
	position: relative;
	width: 40px;
	height: 40px;
	&:before {
		content: "";
		position: absolute;
		width: 100%;
		height: 100%;
		background-size: 16px;
		background-image: url("../../images/icons/microphone.svg");
		background-position: center;
		background-repeat: no-repeat;
		top: 0px;
		right: 0;
		left: 0;
	}
}

.ri-emotion-happy-line:before {
	content: "";
}

.jsmartEmuji {
	position: relative;
	width: 40px;
	height: 40px;
	&:before {
		content: "";
		position: absolute;
		width: 100%;
		height: 100%;
		background-size: 18px;
		background-image: url("../../images/icons/emoji.svg");
		background-position: center;
		background-repeat: no-repeat;
		top: 0px;
		right: 0;
		left: 0;
	}
}

.jsmartChatBtn {
	background-color: $secondary;
	border-color: $secondary;
	border-radius: 5rem;
	width: 3.5rem;
	height: 3.5rem;
	color: $white;
	font-family: "Rubik";
	font-weight: 500;
	text-align: center;
	line-height: 1;
	font-size: 0.8rem !important;
	padding: 0;
	@media screen and (max-width: 780px) {
		font-size: 0.7rem !important;
	}

	&:hover {
		background-color: $secondary;
		border-color: $secondary;
		color: $white;
	}

	&:focus {
		background-color: $secondary;
		border-color: $secondary;
		color: $white;
	}

	&:active {
		background-color: $secondary;
		border-color: $secondary;
		color: $white;
	}

	&:active:focus {
		//box-shadow: 0 0 0 0.15rem #1dcf237d;
		color: $white;
	}

	i:before {
		content: "";
	}

	@media (max-width: 991.98px) {
		width: 2.5rem;
		height: 2.5rem;
	}
}

.dropdown-menu {
	box-shadow: $box-shadow;
	padding: 1rem 0.3rem;
	border-radius: 1.2rem;
}

.slide {
	transition: width ease-out 300ms;
}

.chatHeader,
.sendMessageModal {
	&.sendMessageModal .switchDesc {
		margin-top: 30px;
		margin-bottom: 20px;
	}
	.switchDesc {
		.wrapSwitch.toggleSwitch {
			display: flex;
			flex-direction: row;
			align-items: center;
			gap: 0.5rem;
			@media (max-width: 780px) {
				flex-direction: column;
				gap: 0.2rem;
			}

			input.form-check-input {
				margin-top: 0;
				@media (max-width: 780px) {
					transform: scale(0.7);
				}
				&:checked {
					background-color: $secondary;
					border-color: $secondary;
				}
			}

			h6 {
				max-width: 100%;
				margin-bottom: 0;
				@media (max-width: 780px) {
					width: 100%;
					font-size: 0.6rem;
					text-align: center;
					padding-inline: 10%;
					line-height: 1;
				}
			}
		}
	}
}

.chatHeader {
	position: sticky;
	top: 0;
	z-index: 1000;
	background: $white;
	padding-top: 0.6rem !important;
	padding-bottom: 0.6rem !important;
	.userDetailsChatMobile {
		display: none;
	}
	@media (max-width: 991.98px) {
		position: fixed;
		width: 100%;
		filter: drop-shadow(0px 2px 3px $lightGrey);
		padding-top: calc(0.6rem + env(safe-area-inset-top)) !important;
		.userDetailsChatMobile {
			display: block;
			&.age {
				display: inline;
			}
		}
		.headerChatName {
			font-weight: 500;
			font-size: 20px !important;
			line-height: 1.3;
			@media (max-width: 991.98px) {
				font-size: 17px !important;
			}
		}
		.userDetailsChatMobile {
			line-height: 1;
			p {
				margin-bottom: 0 !important;
			}
		}
		.user-head-let-pictures-container {
			.backArrowChat {
				font-size: 42px;
				width: 25px;
				display: flex;
				justify-content: center;
				align-items: center;
				height: 40px;
				color: $primary;
			}
		}
	}
	.wrapMenuChat {
		padding-inline-end: 0.5rem;
	}
}

.chatFooter {
	position: sticky;
	bottom: 0;
	z-index: 1000;
	background: $white;
	@media (max-width: 991.98px) {
		padding-bottom: 1rem !important;
		padding-top: 1rem !important;
		position: fixed;
		width: 100%;
	}
	.inputAndQuote {
		position: relative;
		button.dislike {
			background: $white;
			// transition: all 0.4s ease-in;
			border-radius: 50rem;
			height: 38px;
			width: 38px;
			border: 0;
			display: flex;
			flex: none;
			justify-content: center;
			align-items: center;
			position: absolute;
			top: 0;
			right: 0.3rem;
			bottom: 0;
			transform: scale(0.8);
			margin: auto;
			img {
				position: absolute;
				z-index: 1000;
				width: 25px;
				height: 25px;
				cursor: pointer;
				filter: $fprimary;
			}
		}
		.form-control {
			border-radius: 30rem;
			background-color: $mint !important;
		}
	}
}

.wrapContactsPanel {
	/*  width: 30rem;
  max-width: 380px;
  border-top-right-radius: 1.5rem;
  border-top: 0px solid $lightGrey;
  border-right: 0px solid $lightGrey;
  height: calc(100vh - 5rem);
  bottom: 0;
  position: relative;
  margin-top: 5rem;
  box-shadow: $big-box-shadow;
  z-index:1010;
  background:$white;*/

	@media (max-width: 991.98px) {
		/*  width: 100%;
    max-width: 100%;*/

		& h4 {
			margin-bottom: 0.5rem !important;
		}
	}
}

.recommendedUsers .text-truncate {
	text-align: center;
	margin-top: 0.5rem !important;
}

.form-control,
.form-control:focus {
	color: $primary;
}

@each $color, $value in $theme-colors {
	.text-#{$color} {
		color: $value !important;
	}
}

.btn-group.chatsTabs {
	width: 100%;
	padding: 5px;
	background: $mint;
	border-radius: 50rem;

	.wrapBadge {
		position: relative;
		span {
			position: absolute;
			top: -25px;
			left: -20px;
			background-color: $alert !important;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 18px;
			height: 18px;
		}
	}

	button.btn.btn-light {
		background-color: $mint !important;
		&:hover {
			background-color: $mint !important;
		}
	}

	button.btn,
	button.btn.active {
		padding: 0rem 1rem;
		border-radius: 50rem !important;
		height: auto;
		line-height: 1.5;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 13px;
		font-weight: 500;
		@media screen and (max-width: 780px) {
			font-size: 12px;
		}
	}

	button.btn {
		color: $primary;
		border: 0;
	}

	button.btn.active {
		color: $white;
		padding: 4px 30px;
		box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 10px 0px rgb(0 0 0 / 14%),
			0px 1px 10px 0px rgb(0 0 0 / 12%);
		@media screen and (max-width: 780px) {
			font-size: 12px;
			padding: 2px 30px;
		}
	}

	button.btn:active:focus,
	button.btn.active:active:focus,
	button.btn.active:focus,
	.btn-light:focus,
	.btn-light:active:focus,
	.btn-light:active,
	.btn-check:checked + .btn-outline-primary:focus,
	.btn-check:active + .btn-outline-primary:focus,
	.btn-outline-primary:active:focus,
	.btn-outline-primary.active:focus,
	.btn-outline-primary.dropdown-toggle.show:focus {
		box-shadow: none !important;
	}

	.btn-light:hover {
		background-color: rgba(68, 107, 195, 0.05) !important;
	}
}

@each $color, $value in $theme-colors {
	.btn-#{$color} {
		background-color: $value !important;
		border-color: $value !important;
	}
}

@each $color, $value in $theme-colors {
	.btn-#{$color}.active {
		background-color: $value !important;
		border-color: $value !important;
	}
}

.user-profile-sidebar {
	.simplebar-content {
		padding: 16px 15px !important;

		.wrapMainPictureWithMainDetails {
			position: relative;
			width: fit-content;
			height: fit-content;
			margin-right: calc(50% - 200px);
			@media screen and (max-width: 780px) {
				margin-right: 0;
			}

			.wrapBigImage {
				width: 100%;
				height: 100%;
				border-radius: 20px;
				position: relative;
				background: linear-gradient(#00012020 15%, transparent 65%, #000000b0);

				.jsmartProfileImg {
					z-index: -1;
					width: 400px;
					height: 481px;
					object-fit: cover;
					border-radius: 20px;
					padding: 0;
					border: none;
					cursor: pointer;
					transition: border-color 0.3s linear;
					&:hover {
						border-color: $textGrey;
						transition: border-color 0.3s linear;
					}
				}
			}

			a {
				.ri-arrow-left-s-line {
					transform: scaleX(-1);
					display: inline-block;
				}
				display: none;
				@media screen and (max-width: 780px) {
					display: flex;
					align-items: center;
					justify-content: center;
					border-radius: 10px;
					width: 40px;
					height: 46px;
					color: #fff;
					background-color: $primary;
					position: fixed;
					top: calc(27px + env(safe-area-inset-top));
					z-index: 1;
					font-size: 38px;
					font-weight: 500;
					left: -8px;
					transform: scale(-1);
				}
			}

			.distance {
				width: fit-content;
				position: absolute;
				display: flex;
				top: 37px;
				right: 50px;
				font-size: 14px;
				z-index: 1;
				background-color: #fff0;
				padding: 4px 7px;
				color: $white;
				gap: 0.4rem;
				&:before {
					content: "\eea9";
					font-family: "remixicon";
					color: $white;
				}
			}

			.user-status {
				position: absolute;
				top: 43px;
				right: 36px;
				width: 15px;
				height: 15px;
				background-color: $gray-500;
				border-radius: 50%;
				border: 1px solid $white;
				display: none;
				&.online {
					background-color: $success;
					@media screen and (max-width: 780px) {
						display: block;
					}
				}
			}

			.basicDetails {
				width: 100%;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				position: absolute;
				bottom: 85px;
				div:nth-child(1) {
					height: 30px;
				}
				span {
					color: white;
				}
			}

			.shareButton {
				position: absolute;
				bottom: 16px;
				left: 20px;
				width: 39px;
				height: 36px;
				cursor: pointer;
			}

			.vip {
				top: -21.5px;
				left: -21.5px;
			}

			@media screen and (max-width: 780px) {
				.vip1 {
					transform: scale(1);
					&:after {
						transform: rotate(-135deg) translate(45%, -56px) scale(-1);
					}
				}
			}

			.imageTapIndicator {
				position: absolute;
				bottom: 60px;
				display: flex;
				align-items: center;
				justify-content: center;
				width: 100%;

				.dotsContainer {
					display: flex;
					.dot {
						margin: 2px;
						width: 10px;
						height: 10px;
						background: white;
						border-radius: 50%;

						&.selected {
							background: $secondary;
						}
					}
				}
			}
		}
		.userProfileDetails {
			padding: 20px;
			.oneLiner {
				margin-top: 45px;
				margin-bottom: 42px;
				text-align: center;
				font-family: "gveret_levin_alefalefalefRg";
				font-weight: normal;
				font-style: normal;
				font-size: 20px;
			}
			h4 {
				font-weight: 600;
				@media screen and (max-width: 780px) {
					font-size: 17px !important;
				}
			}
			.reportUsers {
				p {
					color: $primary;
				}
			}
		}
	}
}

.userDetailsCollapse {
	position: relative;
	margin-bottom: 1rem;
	margin-top: 1rem;
	@media screen and (max-width: 375px) {
		margin-bottom: 2.5rem;
	}
	.littleToggleIconBtn {
		position: absolute;
		bottom: -24px;
		right: 0;
		left: auto;
		border-color: $lightGrey;
		font-family: "Material Design Icons";
		display: flex;
		align-items: center;
		justify-content: center;

		span {
			font-size: 21px;
		}
		&:hover {
			background-color: $secondary;
			@media screen and (max-width: 991.98px) {
				background-color: $white;
				color: $secondary;
				box-shadow: none;
			}
		}
	}

	/*custom collapse*/

	.collapseMe,
	.collapseMeTo {
		overflow: hidden;
		transition: height linear 200ms;
		span {
			display: inline-block;
			color: $textGrey;
			font-weight: 300;
			@media screen and (max-width: 780px) {
				font-size: 17px;
				font-weight: 400;
			}
		}
	}
}

.jsmartProfileName {
	color: $primary;
	font-weight: 500;
	overflow: visible;
}

.detailsProfile {
	line-height: 1;
	margin-bottom: 0.5rem;
	text-align: start;
	padding-inline-end: 0rem;
}

.user-chat {
	.detailsProfile {
		text-align: center;
		padding-inline-end: 0rem;
	}
	.chatFooter .switchDesc {
		display: none;
	}
}

.vip {
	z-index: 1;
	position: absolute;

	.container {
		width: 110px;
		height: 110px;
		position: relative;
		margin: 20px;
		overflow: hidden;
		transform: rotate(-90deg);

		.stack-top {
			padding-top: 2px;
			font-size: 12px;
			text-align: center;
			width: 100%;
			height: 100%;
			position: absolute;
			top: -13px;
			left: 0px;
			height: 23px;
			z-index: 9;
			margin: 29px;
			transform: rotateY(0deg) rotate(45deg);
			color: $white;
			background: $alert;
		}
	}
}

.vip1 {
	background-size: contain;
	position: absolute;
	right: 0;
	top: 34px;
	transform: scale(1);
	z-index: 5;
	text-align: center;
	font-weight: 500;
	background-image: url("../../images/icons/searcVipBadge.svg");
	width: 82px;
	height: 51px;
	pointer-events: none;
	~ .statusBarCard {
		width: 100% !important;
		@media screen and (max-width: 780px) {
			width: 100% !important;
		}
	}
	@media screen and (max-width: 780px) {
		font-size: 1.1rem;
		transform: scale(1);
		background-image: url("../../images/icons/vipBadgeMobile.svg");
		width: 60px;
		background-repeat: no-repeat;
		top: 6px;
	}
	&:after {
		// content: attr(data-vip);
		// background: #0000;
		// top: 0;
		// left: 0;
		// position: absolute;
		// transform: rotate(-135deg) translate(46%, -56px) scale(-1);
		// color: $white;
		// padding: 0.1rem 0.1rem 0.15rem 0.1rem;
		// line-height: 1.5;
		// border-bottom: 25px solid $alert;
		// border-left: 25px solid #0000;
		// border-right: 25px solid #0000;
		// height: 0;
		// width: 96px;
		// border-radius: 0;
		// filter: drop-shadow(0px 3px 4px rgba(64, 64, 64, 0.3));

		@media screen and (max-width: 780px) {
			line-height: 1.7;
		}
	}
}

.user-profile-sidebar {
	.detailsProfile {
		text-align: center;
		padding-inline-end: 0rem;
		display: flex;
		align-items: baseline;
		justify-content: center;
		gap: 7px;
	}

	@media screen and (max-width: 780px) {
		.simplebar-content .wrapMainPictureWithMainDetails .statusBarCard {
			position: absolute;
			top: 1.7rem;
			width: auto;
			display: flex;
			flex-direction: row-reverse;
			left: 2.2rem;
			align-items: center;
			height: 3rem;
			.user-status {
				position: relative;
				top: auto;
				right: auto;
				width: 15px;
				height: 15px;
				border: 0px solid $white;
				margin-inline-end: 0.5rem;
				&.offline {
					display: none;
					top: auto;
					right: auto;
					border: 0;
				}
			}
			.distance {
				position: relative;
				display: flex;
				top: auto;
				right: auto;
			}
		}
	}
}

.detailsProfile p {
	display: inline;
	@media (min-width: 992px) {
		&.city {
			max-width: 160px;
		}
	}
}

.shareButtonProfile {
	border-radius: 50rem;
	width: 2rem;
	height: 2rem;
	background: $mint !important;
	display: flex;
	justify-content: center;
	align-items: center;
	border: 0px solid $primary !important ;
	margin-inline-start: 0.6rem;
	position: relative;
}

.btn-check:focus + .btn,
.btn:focus {
	box-shadow: none;
}

.leftSidebarNew {
	.side-menu {
		min-width: 0;
		max-width: 100%;
		height: auto;
		min-height: 0;
		background-color: #0000;
		display: flex;
		z-index: 9;
		box-shadow: none;

		.side-menu-nav .nav-item {
			margin: 0;

			.nav-link {
				color: $primary;
				width: 40px;
				height: 40px;
				line-height: 0;
				display: flex;
				justify-content: center;
				align-items: center;
				font-size: 22px;
			}

			.dropdown-menu button {
				align-items: center;
				gap: 0.5rem;
				&.active {
					color: $primary;
					background-color: $mint;
				}
			}
		}
	}
}

.micPopover {
	.popover {
		width: 100%;
		.qrGp,
		.qrAs {
			padding: 1rem 0;

			img {
				width: 150px;
			}
		}
		.closeBtn,
		.closeBtn:hover,
		.closeBtn:active,
		.closeBtn:focus,
		.closeBtn:focus:active {
			border-color: #0000;
			background-color: #0000;
			color: $primary;
			border-radius: 5rem;
			padding: 0;
			display: flex;
			justify-content: center;
			align-items: center;
			position: absolute;
			top: 3px;
			left: 0.5rem;
			font-size: 1.2rem;
			box-shadow: none;
		}
	}
}

.wrapStoreBtns {
	position: relative;
	width: 100%;
	display: flex;
	justify-content: center;
	gap: 1rem;
	margin-bottom: 1rem;

	button {
		border-radius: 1rem !important;
		padding-right: 1rem;
		padding-left: 1rem;
	}

	button.gpBtn {
		padding-left: 2.3rem;
		background-image: url("../../images/icons/gplay.svg");
		background-size: auto 60%;
		background-repeat: no-repeat;
		background-position: 0.7rem center;
	}

	button.asBtn {
		padding-left: 2.3rem;
		background-image: url("../../images/icons/apstore.svg");
		background-size: auto 60%;
		background-repeat: no-repeat;
		background-position: 0.7rem center;
	}
}
.user-chat {
	.audio-player {
		--player-button-width: 3em;
		--sound-button-width: 2em;
		--space: 0.5em;
	}

	.icon-container {
		width: 100%;
		height: 100%;
		background-color: $specialBtn;
		color: $white;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.audio-icon {
		width: 90%;
		height: 90%;
	}

	.controls {
		display: flex;
		flex-direction: row;
		align-items: center;
		width: 100%;
		margin-top: 10px;
	}

	.player-button {
		background-color: transparent;
		border: 0;
		width: var(--player-button-width);
		height: var(--player-button-width);
		cursor: pointer;
		padding: 0;
	}

	.timeline {
		-webkit-appearance: none;
		width: calc(100% - (var(--player-button-width) + var(--sound-button-width) + var(--space)));
		height: 0.2em;
		background-color: $lightGrey;
		border-radius: 5px;
		background-size: 0% 100%;
		background-image: linear-gradient($secondary, $secondary);
		background-repeat: no-repeat;
		margin-right: var(--space);
	}

	.timeline::-webkit-slider-thumb {
		-webkit-appearance: none;
		width: 0.7rem;
		height: 0.7rem;
		border-radius: 50rem;
		cursor: pointer;
		opacity: 1;
		transition: all 0.1s;
		background-color: $secondary;
	}

	.timeline::-moz-range-thumb {
		-webkit-appearance: none;
		width: 1em;
		height: 1em;
		border-radius: 50%;
		cursor: pointer;
		opacity: 1;
		transition: all 0.1s;
		background-color: $secondary;
	}

	.timeline::-ms-thumb {
		-webkit-appearance: none;
		width: 1em;
		height: 1em;
		border-radius: 50%;
		cursor: pointer;
		opacity: 1;
		transition: all 0.1s;
		background-color: $secondary;
	}

	.timeline::-webkit-slider-thumb:hover {
		background-color: #943f65;
	}

	.timeline:hover::-webkit-slider-thumb {
		opacity: 1;
	}

	.timeline::-moz-range-thumb:hover {
		background-color: #943f65;
	}

	.timeline:hover::-moz-range-thumb {
		opacity: 1;
	}

	.timeline::-ms-thumb:hover {
		background-color: #943f65;
	}

	.timeline:hover::-ms-thumb {
		opacity: 1;
	}

	.timeline::-webkit-slider-runnable-track {
		-webkit-appearance: none;
		box-shadow: none;
		border: none;
		background: transparent;
	}

	.timeline::-moz-range-track {
		-webkit-appearance: none;
		box-shadow: none;
		border: none;
		background: transparent;
	}

	.timeline::-ms-track {
		-webkit-appearance: none;
		box-shadow: none;
		border: none;
		background: transparent;
	}

	.sound-button {
		background-color: transparent;
		border: 0;
		width: var(--sound-button-width);
		height: var(--sound-button-width);
		cursor: pointer;
		padding: 0;
	}

	.right {
		svg {
			fill: $mint;

			.volumeLine {
				stroke: $mint;
			}
		}
	}

	svg {
		fill: $primary;

		.volumeLine {
			stroke: $primary;
		}
	}
}

/*current recommended user in chat }*/

.recommendedUsers {
	padding-bottom: 0.5rem !important;
	min-height: 75.19px;
	ul {
		li {
			padding-top: 5px;
			border-radius: 4px;
			transition: all 0.4s;
			&:hover {
				background: $lightGrey;
				transition: all 0.4s;
			}
		}
	}
	.current {
		background: $lightGrey;
		transition: all 0.4s;
	}

	.alice-carousel__prev-btn,
	.alice-carousel__next-btn {
		width: 0px;
	}
	.alice-carousel__prev-btn {
		position: absolute;
		top: 0px;
		left: -15px;
	}
	.alice-carousel__next-btn {
		position: absolute;
		top: 0;
		right: -10px;
	}
}

.wrapSumBtn {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	justify-content: center;
	gap: 0.3rem;
	margin-top: 4rem;

	.btn.btn-sm {
		padding: 0.2rem 0.7rem !important;
		box-shadow: #446ac336 0 2px 10px;
	}
}

.listOfDesc {
	margin-top: 2.5rem;
	.rowOfDesc {
		display: flex;
		flex-direction: row;
		gap: 0.7rem;

		.iconOfDesc {
			width: 1.2rem;
			display: flex;
			justify-content: center;
			align-items: baseline;
			margin-bottom: 1rem;
			img {
				filter: $fsecondary;
			}
		}

		p {
			color: $textGrey;
			margin-top: -2px;
			font-size: 15px;
			@media screen and (max-width: 780px) {
				font-weight: 400;
				font-size: 16px;
			}

			&.army {
				margin-top: 0px;
			}
		}
	}
}

.imagesGallery {
	margin-top: 1.5rem;

	.imageGalleryMedium {
		display: flex;
		flex-wrap: wrap;
		justify-content: start;
		gap: 21px;
		margin-top: 15px;

		img {
			width: 70px;
			height: 70px;
			border-radius: 10px;
			object-fit: cover;
			cursor: pointer;
			transition: all 0.3s linear;
			filter: grayscale(60%);

			&:hover {
				transition: all 0.3s linear;
				filter: grayscale(0);
			}
		}
	}
}

.shareContacts {
	margin-top: 2rem;
	display: flex;
	flex-direction: column;
	div {
		p.shareContactsText {
			width: 70%;
			margin-top: 4px;
		}
	}
	.btnCenter {
		align-self: start;
		padding-right: 2rem;
		padding-left: 2rem;
	}
	.wrapAcquaintances {
		margin: 20px 0;
		.mySwiper {
			display: flex;
			overflow: auto;
			.swiper-wrapper {
				display: flex;
				@media screen and (max-width: 780px) {
					overflow: auto;
					transform: translate3d(0px, 0px, 0px) !important;
				}
				.swiper-slide {
					a {
						display: flex;
						justify-content: center;
						align-items: center;
						flex-direction: column;
						img {
							border-radius: 50%;
							padding: 2px;
						}
						span {
							text-align: center;
							font-size: 13px;
							overflow: hidden;
							white-space: pre;
							width: 70px;
							text-overflow: ellipsis;
							color: #8b8b8d;
							padding: 0 5px;
						}
					}
				}
			}
		}
	}
}

.reportUsers {
	.rowOfDesc {
		justify-content: center;
		cursor: pointer;
		&:hover p {
			color: $primary;
			transition: color 0.5s ease;
		}
	}
	p {
		color: $primary;
		transition: color 0.5s ease;
		font-size: 14px;
		font-weight: 400;
	}
}

.wrapContactsPanel .message {
	margin-top: -1rem;
	font-weight: 300;
	padding-right: 2rem !important;

	@media screen and (max-width: 991.98px) {
		margin-top: -0.5rem;
	}
}

.simplebar-content-wrapper {
	opacity: 1;
	transition: opacity 0.5s ease;
}

.simplebar-content-wrapper.fade-out {
	opacity: 0;
	transition: opacity 0.01s linear;
}

.user-chat .simplebar-content-wrapper {
	display: flex;
	flex-direction: column;
}

.user-chat .simplebar-content-wrapper .simplebar-content {
	justify-self: flex-end;
	margin-top: auto;
}

.chat-time i {
	margin-left: 0.3rem;
	margin-right: 0;
}

div.chat-conversation {
	background: $white url("../../images/birds.svg");
	background-size: 10%;
	@media screen and (max-width: 780px) {
		background: $white url("../../images/birds.svg");
		background-size: 20%;
	}
	li {
		.conversation-list .chat-time {
			display: flex;
			flex-direction: row-reverse;

			.is-read-message {
				display: flex;
				color: white;
			}
		}
		&.template {
			.conversation-list {
				.ctext-wrap-content {
					padding-inline-start: 10px;
					padding-top: 0;
					p:nth-child(1) {
						text-indent: 0rem;
						padding-inline-start: 28px;
						padding-top: 12px;
						position: relative;
						&:before {
							content: "";
							background-image: url("../../images/icons/quoteB.svg");
							filter: $fsecondary;
							background-repeat: no-repeat;
							background-size: 1.3rem;
							background-position: 0;
							position: absolute;
							top: 4px;
							width: 1.5rem;
							height: 1.7rem;
							left: 0px;
						}
					}
				}
			}
		}
	}
}

.modal-dialog {
	margin: 0 auto;
}

.modal-dialog.galleryModal {
	@media screen and (max-width: 780px) {
		justify-content: center;
		.modal-content {
			background: #0000;
			width: 80%;
			.modal-body {
				padding: 0;
				margin-bottom: 0;
				.carousel.galleryCarousel .carousel-inner .carousel-item img {
					width: 100%;
				}
			}
		}
	}
}

.modal-dialog.qrModal {
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 100vh;
	margin: auto;
}

.modal-dialog.qrModal .modal-content {
	width: 70%;
}

.closeBtn {
	border: 0;
	font-size: 1.3rem;
	&:hover {
		background: $mint;
		color: $primary;
	}
}

.modal-dialog.qrModal {
	max-width: 500px;

	.modal-header {
		padding-top: 0;
		padding-right: 0;
		padding-bottom: 0;
		.modal-title {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
	}

	.modal-body {
		display: flex;
		flex-direction: column;
		gap: 1rem;
		align-items: center;
		color: $primary;
		font-size: 1.1rem;
		text-align: center;
		font-weight: 400;
		line-height: 1.2;
		padding-right: 3rem;
		padding-left: 3rem;

		img {
			width: 60%;
			margin-bottom: 1rem;
			margin-top: 1rem;
		}
	}
}

.user-chat-nav {
	justify-content: flex-start;
	display: flex;
}

.modal-dialog.simpleDialog {
	min-height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	max-width: 350px;
	.modal-content {
		border-radius: 20px;
		.modal-header {
			display: flex;
			justify-content: center;
			border-bottom: 0;
			padding-top: 1.5rem;
			&.freezeAccount {
				padding-top: 0;
			}
			.modal-title {
				display: flex;
				justify-content: center;
				align-items: center;
				font-size: 1.4rem;
				font-weight: 400;
				line-height: 1.2;
				text-align: center;
				.jsmartDrop {
					width: 1.6rem;
					height: 2.1rem;
					margin-left: 7px;
					&.jsmartDislike:before {
						background-size: 24px;
						transform: translateX(-25%);
					}
					&.jsmartBlock:before {
						background-size: 24px;
						transform: translateX(-25%);
					}
				}
			}
		}

		.modal-body {
			padding-top: 0;
			text-align: center;
			span {
				color: $primary;
				font-size: 1.2rem;
				display: inline-block;
				max-width: 70%;
				font-weight: 300;
				line-height: 1.3;
			}
		}

		.modal-footer {
			display: flex;
			justify-content: center;
			gap: 1rem;
			border-top: 0;
			padding-bottom: 1.5rem;
			flex-direction: row-reverse;
			.littleBtn {
				padding: 0.1rem 1.6rem 0.1rem !important;
				box-shadow: #446ac336 0 2px 10px;
				font-size: 17px;
				&.mainBtn {
					color: $white;
					background: $primary;
				}
			}
		}
	}

	&.spreadModal {
		max-width: 520px;
		color: $primary;
		.modal-content {
			background: $jsmartGradientDiagonal;
			padding: 1rem 3rem;
			border: 0;
			@media screen and (max-width: 780px) {
				padding: 1rem 1.5rem;
				width: 95%;
				height: 95%;
				position: fixed;
				top: 2.5%;
			}
			.user-chat-nav {
				justify-content: flex-end;
				display: flex;
				position: relative;
				width: calc(100% + 6rem);
				margin-inline: -3rem;
				top: -15px;
				@media screen and (max-width: 780px) {
					width: 100%;
					margin-inline: -1.5rem;
					position: absolute;
					top: 0;
					z-index: 15;
				}
				.ri-close-line:before {
					color: $white;
				}
			}
			.modal-header {
				padding: 0;
				.modal-title {
					flex-direction: column;
					text-align: start;
					h4 {
						color: $white;
						width: 100%;
						font-size: 1.1rem;
						line-height: 1.4;
						@media screen and (max-width: 780px) {
							font-size: 1rem;
							line-height: 1.2;
						}
					}
					h4.mainTitle {
						font-weight: bold;
						margin-bottom: 2rem;
						margin-top: 3rem;
						position: relative;
						font-size: 1.4rem;
						@media screen and (max-width: 780px) {
							margin-bottom: 0.5rem;
						}
						&:before {
							content: "";
							position: absolute;
							background-image: url("../../images/icons/birdsBig.svg");
							width: 9rem;
							height: 4.5rem;
							background-size: 100%;
							background-repeat: no-repeat;
							transform: translateX(-50%) translateY(-100%);
							left: 40%;
							@media screen and (max-width: 780px) {
								width: 6rem;
								height: 2.5rem;
							}
						}
					}
				}
			}

			.modal-body {
				padding: 0;
				.wrapTxtArea {
					position: relative;
					.form-inline {
						display: flex;
						flex-direction: column;
						align-items: flex-end;
						.formIn {
							display: flex;
							align-items: flex-start;
							flex-direction: column;
							margin-bottom: 0px !important;
							width: 100%;
							.grow-wrap {
								width: 100%;
								position: relative;
								textarea.form-control {
									-webkit-user-select: text;
									user-select: text;
									background-clip: border-box;
									position: relative;
									z-index: 1000;
									font-size: 17px;
									font-family: "gveret_levin_alefalefalefRg";
									border-color: transparent;
									border-radius: 7px;
									white-space: pre-line;
									padding-bottom: 1rem;

									&::-webkit-scrollbar {
										width: 5px;
										overflow: hidden;
									}
									&::-webkit-scrollbar-track {
										background-color: #ffffff00 !important;
										border-radius: 7px;
									}
									&::-webkit-scrollbar-thumb {
										background-color: #00000025 !important;
										border-radius: 7px;
									}
									&::placeholder {
										font-size: 17px;
										opacity: 0.5;
										white-space: pre-line;
									}

									&:focus {
										z-index: 1000;
									}
									&:empty {
										z-index: 1;
									}
								}
							}
						}
						span.numrest {
							font-size: 0.8rem;
							font-weight: 500;
						}
					}
				}
				.noteMass {
					line-height: 1;
				}
				.wrapInputsOfCentered {
					width: 70%;
					margin-right: 15%;
					display: flex;
					flex-direction: column;
					@media screen and (max-width: 780px) {
						width: 100%;
						margin-right: 0;
					}
					.section.range {
						display: flex;
						flex-direction: row;
						color: $white;
						margin-top: 1rem;
						justify-content: space-between;
						@media screen and (max-width: 780px) {
							margin-top: 0;
						}
						> div {
							width: 45%;
							@media screen and (max-width: 780px) {
								width: 48%;
								margin-bottom: 0.7rem !important;
							}
							.input-group {
								display: flex;
								justify-content: center;
								.wrapInputNumber {
									display: flex;
									flex-direction: row;
									.btn {
										background: #fffc !important;
										border: 0;
										color: $secondary !important;
										border-radius: 7px;
										font-weight: 600;
										font-size: 20px;
										padding: 0 0.75rem;
										@media screen and (max-width: 780px) {
											padding: 0 1rem;
										}
										&.plus {
											border-top-right-radius: 0;
											border-bottom-right-radius: 0;
										}
										&.minus {
											border-top-left-radius: 0;
											border-bottom-left-radius: 0;
										}
									}
									input.form-control {
										border: 0;
										border-radius: 0;
										background: #fffc;
										text-align: center;
										font-weight: 600;
										font-size: 20px;
										line-height: 0;
										padding: 0.4rem 1rem;
									}
								}
							}
						}
					}

					.wraptxt {
						@media screen and (max-width: 780px) {
							margin-bottom: 0.7rem !important;
						}
						.multiSelect {
							.nav-link {
								border: 0px solid;
								background: #fffc;
							}
						}
					}
				}
				.placeholder1::after {
					content: attr(attr);
					position: absolute;
					color: $primary;
					top: 0;
					z-index: 500;
					width: 100%;
					height: 100%;
					right: 0;
					font-size: 17px;
					background: transparent;
					white-space: pre-line;
					text-align: start;
					padding: 1rem;
					line-height: 1.3;
					opacity: 0.3;
					font-family: "gveret_levin_alefalefalefRg";
					pointer-events: none;
				}
			}

			.modal-footer {
				@media screen and (max-width: 780px) {
					padding: 0;
				}

				button {
					background: $primary;
					color: $white;
					font-weight: bold;
					font-size: 20px;
					width: 50%;
					border: 0;
					&:hover {
						background: $white;
						color: $primary;
					}
				}
			}
		}

		.dropdown.multiSelect .dropdown-menu {
			&::-webkit-scrollbar {
				width: 5px;
				overflow: hidden;
			}
			&::-webkit-scrollbar-track {
				background-color: #ffffff00 !important;
				border-radius: 7px;
			}
			&::-webkit-scrollbar-thumb {
				background-color: #00000025 !important;
				border-radius: 7px;
			}
		}
	}
}

/*הפופאפ של הדיווח*/

.bigSlideDialog {
	/* width */
	::-webkit-scrollbar {
		width: 10px;
	}

	/* Track */
	::-webkit-scrollbar-track {
		background: $lightGrey;
	}

	/* Handle */
	::-webkit-scrollbar-thumb {
		background: rgb(224, 224, 224);
	}

	/* Handle on hover */
	::-webkit-scrollbar-thumb:hover {
		background: rgb(224, 224, 224);
	}
	width: 1000px;
	max-width: 800px;
	min-height: 90vh;
	height: 90vh;
	top: 10vh;
	background: $white;
	border-top-right-radius: 30px;
	border-top-left-radius: 30px;

	@media screen and (max-width: 991.98px) {
		width: 100%;
		top: 0;
		border-top-right-radius: 0;
		border-top-left-radius: 0rem;
		height: 100vh;
	}

	.closeButton {
		position: absolute;
		right: 0.7rem;
		color: $white;
		top: 0.6rem;
		font-size: 1.2rem;
		@media screen and (max-width: 991.98px) {
			font-size: 1.2rem;
			padding-top: 0;
			line-height: 0.4;
			padding-right: 0;
			right: 1.8rem;
			left: auto;
			top: 1.5rem;
		}
	}
	.modal-content {
		border: 0;
		border-top-right-radius: 40px;
		border-top-left-radius: 40px;
		height: 100%;
		@media screen and (max-width: 991.98px) {
			border-top-left-radius: 85px;
		}

		.modal-title {
			display: flex;
			align-items: flex-start;
			flex-direction: column;
			width: 100%;
		}

		.jsmartDrop:before {
			transform: translateX(-20%);
			filter: brightness(20);
		}

		.modal-header {
			background: $primary;
			padding-bottom: 2rem;
			border-top-right-radius: 30px;
			border-top-left-radius: 30px;

			@media screen and (max-width: 991.98px) {
				border-top-right-radius: 0;
				border-top-left-radius: 0rem;
			}

			@media screen and (max-width: 480px) {
				border-top-right-radius: 0;
				border-top-left-radius: 0;
				padding-bottom: 1rem;
			}

			.topHeader {
				display: flex;
				flex-direction: row;
				align-items: center;
				color: $white;
				font-weight: 300;
				margin-left: 1rem;
				margin-top: 0.5rem;
				@media screen and (max-width: 991.98px) {
					margin-top: 3px;
				}
				@media screen and (max-width: 480px) {
					font-size: 0.9rem;
					display: none;
				}

				.jsmartDrop {
					filter: $finvert;
				}
			}
			span {
				display: inline-block;
				line-height: 1.3;
				font-weight: 300;
				@media screen and (max-width: 480px) {
					font-size: 1rem;
				}
			}

			.titleBig {
				width: 100%;
				display: flex;
				justify-content: center;
				align-items: center;
				flex-direction: column;
				text-align: center;
				color: $white;
				h5 {
					display: flex;
					justify-content: center;
					align-items: center;
					flex-direction: column;
					margin-top: 1rem;
					margin-top: 1rem;
					font-weight: 400;
					font-size: 1.7rem;
					margin-bottom: 1.3rem;
					color: $white;
					@media screen and (max-width: 480px) {
						font-size: 1.4rem;
						margin-top: 0;
						margin-bottom: 0.6rem;
					}
					.jsmartDrop.bigIcon {
						width: 2.5rem;
						height: 2.5rem;
						transform: translateX(0%);
						&:before {
							left: 0;
							width: 2.5rem;
							height: 2.5rem;
							top: 0;
							transform: translateX(0%);
							background-size: 90%;
							filter: $finvert;
							@media screen and (max-width: 480px) {
								background-size: 80%;
								background-position: top;
								width: 1.5rem;
								height: 1.5rem;
								filter: $finvert;
							}
						}
						@media screen and (max-width: 480px) {
							width: 1.5rem;
							height: 1.5rem;
						}
					}
				}
			}
		}

		.modal-body {
			padding: 1rem 0;
			position: relative;
			width: 100%;
			overflow-y: auto;
			padding-bottom: 75px;
			@media screen and (max-width: 480px) {
				padding-top: 0;
			}
		}

		.modal-footer {
			justify-content: center;
			position: absolute;
			bottom: 0;
			width: 100%;
			background: $white;

			@media screen and (max-width: 991.98px) {
				position: fixed;
			}

			.reportBtn {
				font-size: 1.2rem;
				font-weight: 500;
				padding: 0.4rem 1.8rem;
				border: 0;
				&:hover {
					background: $white;
					color: $primary;
				}
				&.mainBtn {
					background: $primary;
					color: $white;
				}
			}
		}

		/*report accordion*/
		.accordion-item {
			border-bottom: 1px solid $primary;
			padding: 0;
			.accordion-button {
				color: $primary;
				background-color: $white;
				font-size: 1.2rem;
				font-weight: 500;
				padding-right: 20%;
				padding-left: 20%;
				&::after {
					filter: invert(45%) sepia(14%) saturate(4772%) hue-rotate(196deg) brightness(82%)
						contrast(83%);
					background-image: url("../../images/icons/arrow_down.svg");
					background-size: 100%;
					width: 1.8rem;
					height: 1.8rem;
				}

				@media screen and (max-width: 780px) {
					padding-right: 4%;
					padding-left: 4%;
				}
			}

			.wrapradio {
				padding: 0;
				padding-right: 19%;
				padding-left: 18%;

				@media screen and (max-width: 780px) {
					padding-right: 1%;
					padding-left: 1%;
				}

				input[type="text"] {
					width: 100%;
					border-color: $lightGrey;
					border: 1px solid $primary;
					border-radius: 46px;
					padding: 0.3rem 1rem;
					background: $lightGrey;
					color: $primary;
					padding-left: 1.4rem;
					&:focus-visible {
						border: 2px solid $primary;
						outline: none;
					}
				}
			}

			.accordion-body {
				padding-top: 0;
			}

			.subAccordion {
				.accordion-item {
					border-bottom: 0px solid $primary;
					.accordion-button {
						font-weight: 400;
						@media screen and (max-width: 480px) {
							font-size: 1rem;
							padding-top: 0.5rem;
							padding-bottom: 0.5rem;
						}
						&::after {
							background-image: none;
						}
						&::before {
							filter: invert(45%) sepia(14%) saturate(4772%) hue-rotate(196deg) brightness(82%)
								contrast(83%);
							background-image: url("../../images/icons/add_plus.svg");
							content: "";
							background-size: 100%;
							width: 1.8rem;
							height: 1.8rem;
							margin-right: 0.2rem;
							transition: transform 0.2s ease-in-out;
							transform: rotate(0deg);
						}
						&:not(.collapsed)::after {
							background-image: none;
							content: "";
						}
						&:not(.collapsed)::before {
							background-image: url("../../images/icons/close_minus.svg");
							content: "";
							background-size: 100%;
							width: 1.8rem;
							height: 1.8rem;
							margin-right: 0.2rem;
							transition: transform 0.2s ease-in-out;
							transform: rotate(180deg);
						}
					}
					.accordion-body {
						padding-right: 21%;
						padding-left: 21%;
						padding-bottom: 0;
						color: $primary;
						@media screen and (max-width: 780px) {
							padding-right: 6%;
							padding-left: 6%;
						}

						.wrapradio {
							padding: 0.5rem 0rem;
							@media screen and (max-width: 780px) {
								padding-right: 1%;
								padding-left: 1%;
							}

							input[type="text"] {
								width: 100%;
								border-color: $lightGrey;
								border: 1px solid $primary;
								border-radius: 46px;
								padding: 0.3rem 1rem;
								background: $lightGrey;
								color: $primary;
								padding-left: 1.4rem;
								@media screen and (max-width: 480px) {
									margin-top: 0.5rem;
								}
								&:focus-visible {
									border: 2px solid $primary;
									outline: none;
								}
							}
							span {
								width: 100%;
								display: inline-block;
								font-size: 1.1rem;
								@media screen and (max-width: 480px) {
									font-size: 1rem;
									padding-left: 1.5rem;
									padding-right: 0rem;
									position: relative;
									display: flex;
									align-items: center;
									line-height: 1;
								}
								input[type="radio"] {
									margin-right: 0.5rem;
									margin-left: 0rem;
									@media screen and (max-width: 480px) {
										position: absolute;
										left: 0rem;
										right: auto;
									}
								}
							}
						}

						.wrapText {
							@media screen and (max-width: 480px) {
								padding: 0;
							}
						}
					}
				}
			}
		}
	}
}

.alignLoader {
	position: absolute;
	width: 100vw;
	height: 100vh;
	justify-content: center;
	align-items: center;
	right: 0;
	left: 0;
	display: flex;
	top: 0;
	pointer-events: none;
	z-index: 1;

	.loaderPosition {
		width: 100%;

		& > div.col-1 {
			display: flex;
			justify-content: center;

			@media screen and (max-width: 780px) {
				width: 15%;
			}
		}
	}
	@media screen and (max-width: 780px) {
		&.loaderBeforeResults {
			height: 100vh;
		}
	}
	&.desktopPhonePage {
		align-items: flex-start;
		.loaderPosition.justify-content-center {
			justify-content: flex-start !important;
			z-index: 4;
			position: absolute;
			top: -25px;
		}
	}
}

.sideBarPanel {
	width: 100%;
	max-width: 350px;
	border-top-right-radius: 1.5rem;
	border-top: 0px solid $lightGrey;
	border-right: 0px solid $lightGrey;
	height: calc(100vh - 5rem);
	bottom: 0;
	position: relative;
	top: 0rem;
	box-shadow: $big-box-shadow;
	background: $white;
	margin-top: 5rem;
	z-index: 1010;

	&.sideBarSearchFilter {
		position: sticky;
		right: 0;
		bottom: 0;
		top: 5rem;
		.section {
			&.fixed {
				position: absolute;
				top: 0;
				width: 100%;
				background: $white;
				padding: 2.5rem 0;
				z-index: 500;
				border-top-right-radius: 5rem;
				left: 0;
				right: 0;

				&.bottom {
					bottom: 0;
					top: auto;
					padding-top: 0;
					border-top-right-radius: 0;
					padding-bottom: 1rem;
					height: auto;
					display: flex;
					justify-content: center;
					&:after {
						content: "";
						background: linear-gradient(0deg, $white 0%, $white 37%, #fff0 100%);
						position: absolute;
						top: 0;
						width: 100%;
						height: 100%;
						z-index: -1;
						pointer-events: none;
						transform: translateY(-100%);
					}
					.mainAccountBtn {
						border-radius: 5rem;
						padding: 0.3rem 4.2rem 0.3rem 3rem;
						position: relative;
						margin-block-start: 0.5rem;
						font-weight: 500;
						&:after {
							content: "";
							background-image: url("../../images/icons/searchUser.svg");
							width: 20px;
							height: 20px;
							background-size: 100%;
							position: absolute;
							right: 15px;
							top: 0;
							bottom: 0;
							margin: auto;
						}

						@media screen and (max-width: 780px) {
							//  bottom: 1.5rem;
							margin-block-start: 0.5rem !important;
							width: 60%;
							right: 0;
							left: 0;
							margin-right: auto;
							margin-left: auto;
							position: relative;
							z-index: 50;
						}
					}
				}
			}
		}
	}

	@media screen and (max-width: 1450px) {
	}

	@media screen and (max-width: 780px) {
		width: 100%;
		max-width: 100%;
		border-top-right-radius: 0;
		margin-top: 80px;
		height: calc(100vh - 80px);
		z-index: 1010;
		padding-top: env(safe-area-inset-top);

		&.sideBarSearchFilter {
			z-index: 1012;
			position: fixed;
			transform: translateX(100%);
			transition: 300ms ease-out transform;
			right: 0;
			top: 0;
			height: calc(100% - 65px);
			width: 90%;
			margin-top: 65px;
			z-index: 5000;
			border-top-left-radius: 1.5rem;
		}

		&.showSearchFilterSlide {
			transform: translateX(0%);
			transition: 300ms ease-out transform;
		}
	}

	.sidebar {
		padding-top: 2rem;
	}

	.sidebar-header {
		padding-top: 2rem;
		padding-bottom: 1rem;
		display: none;
	}

	h3 {
		text-align: center;
		font-weight: 500;
	}

	.side-menu {
		max-width: 100%;
		background-color: $white;
		box-shadow: none;
		width: 100%;
		position: relative;
		overflow-y: auto;
		height: calc(100vh - 5rem - 411px);
		overflow-x: hidden;
		display: flex;
		flex-wrap: wrap;
		min-height: 0;
		padding-top: 0.7rem;

		@media screen and (max-width: 1450px) {
			height: calc(100vh - 2rem - 371px);
		}

		@media screen and (max-width: 780px) {
			height: calc(100vh - 2rem - 214px);
		}
		ul {
			width: 100%;
			display: flex;
			padding-left: 0rem;
			box-sizing: border-box;
			row-gap: 0.5rem;
			@media screen and (max-width: 780px) {
				flex-wrap: nowrap;
			}

			.nav-link {
				color: $primary;
				//font-size: 16px;
				//font-weight: 400;
				justify-content: flex-start;
				width: 100%;
				padding-left: 7rem;
				transition: none;
				@media only screen and (max-width: 780px) {
					// font-size:1rem;
					padding-inline-start: calc(21% + 45px - 0.55rem);
					//font-weight: 500;
				}
			}

			.nav-item + .items-menu li .nav-link {
				font-weight: normal;
				// font-size: 15px;
				border-top-left-radius: 50rem;
				border-bottom-left-radius: 50rem;
				padding-left: 7rem;
				//font-weight: 300;
				@media only screen and (max-width: 780px) {
					//font-size:0.9rem;
					padding-left: 0;
					padding-inline-start: calc(21% + 45px - 0.55rem);
				}
			}

			.nav-item {
				position: relative;
				padding-top: 0.5rem;
				padding-bottom: 0.5rem;
				background-color: $white;
				transition: background-color 0.15s ease, color 0.15s ease;
				width: 100%;
				&:hover,
				&.active {
					padding-top: 0.5rem;
					padding-bottom: 0.5rem;
					transition: background-color 0.15s ease, color 0.15s ease;
					background-color: $primary;
					.dropdown-toggle {
						color: $white;
						img {
							filter: $finvert;
						}
					}
					.nav-link {
						color: $white;
					}
				}
				.dropdown-toggle {
					position: relative;
					display: inline-flex;
					padding-left: 7rem;
					color: $primary;
					//font-size:16px;
					@media screen and (max-width: 780px) {
						padding-inline-start: calc(21% + 45px - 0.55rem);
						//font-size: 1rem;
						//font-weight: 500;
						align-items: center;
					}
					img {
						position: absolute;
						width: 1.1rem;
						min-height: 1.5rem;
						left: 5rem;
						filter: $fprimary;

						@media screen and (max-width: 780px) {
							left: calc(8% + 45px - 0.55rem);
						}
					}
				}

				.dropdown-toggle::after {
					content: "\ea4c";
					position: absolute;
					font-family: "remixicon" !important;
					right: 4rem;
					transition: transform ease 200ms;
					transform: rotate(0deg);
					transform-origin: center;
					@media screen and (max-width: 780px) {
						right: 15%;
						font-size: 1.3rem;
					}
				}

				&.menu-open {
					.dropdown-toggle::after {
						content: "\ea4c";
						transition: transform ease 200ms;
						transform: rotate(180deg);
						transform-origin: center;
					}
				}

				&.imagesIcon:before {
					content: "";
					position: absolute;
					width: 1.1rem;
					height: 1.1rem;
					background-image: url("../../images/icons/camera.svg");
					left: 5rem;
					top: 0;
					bottom: 0;
					background-repeat: no-repeat;
					background-size: 100%;
					margin: auto;
					filter: $fprimary;

					@media screen and (max-width: 780px) {
						left: calc(8% + 45px - 0.55rem);
					}
				}

				&.imagesIcon:hover:before,
				&.imagesIcon.active:before {
					filter: $finvert;
				}

				&.profileIcon:before {
					content: "";
					position: absolute;
					width: 1.1rem;
					height: 1.1rem;
					background-image: url("../../images/icons/userList.svg");
					left: 5rem;
					top: 0;
					bottom: 0;
					background-repeat: no-repeat;
					background-size: 100%;
					margin: auto;
					filter: $fprimary;

					@media screen and (max-width: 780px) {
						left: calc(8% + 45px - 0.55rem);
					}
				}

				&.profileIcon:hover:before,
				&.profileIcon.active:before {
					filter: $finvert;
				}

				&.langIcon:before {
					content: "";
					position: absolute;
					width: 1.1rem;
					height: 1.1rem;
					background-image: url("../../images/icons/translate.svg");
					left: 5rem;
					top: 0;
					bottom: 0;
					background-repeat: no-repeat;
					background-size: 100%;
					margin: auto;
					filter: $fprimary;

					@media screen and (max-width: 780px) {
						left: calc(8% + 45px - 0.55rem);
					}
				}

				&.langIcon:hover:before,
				&.langIcon.active:before {
					filter: $finvert;
				}

				&.contactIcon:before {
					content: "";
					position: absolute;
					width: 1.1rem;
					height: 1.1rem;
					background-image: url("../../images/icons/mail.svg");
					left: 5rem;
					top: 0;
					bottom: 0;
					background-repeat: no-repeat;
					background-size: 100%;
					margin: auto;
					filter: $fprimary;

					@media screen and (max-width: 780px) {
						left: calc(8% + 45px - 0.55rem);
					}
				}

				&.contactIcon:hover:before,
				&.contactIcon.active:before {
					filter: $finvert;
				}

				&.takanonIcon:before {
					content: "";
					position: absolute;
					width: 1.1rem;
					height: 1.2rem;
					background-image: url("../../images/icons/takanon.svg");
					left: 5rem;
					top: 0;
					bottom: 0;
					background-repeat: no-repeat;
					background-size: 100%;
					margin: auto;
					filter: $fprimary;

					@media screen and (max-width: 780px) {
						left: calc(8% + 45px - 0.55rem);
					}
				}

				&.takanonIcon:hover:before,
				&.takanonIcon.active:before {
					filter: $finvert;
				}

				&.signOutIcon:before {
					content: "";
					position: absolute;
					width: 1.1rem;
					height: 1.2rem;
					background-image: url("../../images/icons/signOut.svg");
					left: 5rem;
					top: 0;
					bottom: 0;
					background-repeat: no-repeat;
					background-size: 100%;
					margin: auto;
					filter: $fprimary;

					@media screen and (max-width: 780px) {
						left: calc(8% + 45px - 0.55rem);
					}
				}

				&.signOutIcon:hover:before,
				&.signOutIcon.active:before {
					filter: $finvert;
				}
			}
		}
	}

	.userEdit {
		text-align: center;
		padding-top: 0px;
		padding-bottom: 20px;
		display: flex;
		flex-direction: column;
		align-items: center;

		@media screen and (max-width: 780px) {
			flex-direction: row-reverse;
			width: 100%;
			justify-content: flex-end;
			gap: 0;
			padding-inline-start: 8%;
		}

		.personalDetails {
			display: flex;
			flex-direction: column;

			@media screen and (max-width: 780px) {
				justify-content: center;
				align-items: flex-start;
			}

			h3 {
				display: inline-block;
				font-weight: 500;
				line-height: 1;
			}

			h4 {
				display: inline-block;
				font-size: 14px;
				font-weight: 300;
				@media screen and (max-width: 780px) {
					font-size: 1rem;
					text-align: start;
					font-weight: 400;
				}
			}
		}

		.mainUserImage {
			width: 110px;
			height: 110px;
			border-radius: 50rem;
			border: 5px solid $mint;
			margin-top: 0.8rem;
			margin-bottom: 0.6rem;
			position: relative;
			display: flex;
			justify-content: center;
			align-items: flex-end;
			&.hasSubscription:after {
				content: "VIP";
				width: 82%;
				height: 1.2rem;
				background: $lightBlue;
				position: absolute;
				border-radius: 3px;
				bottom: -9px;
				font-weight: bold;
				font-size: 0.8rem;
				color: #fff;
				text-shadow: 0 0 2px #000;
				filter: drop-shadow(0 2px 2px #ccc);
			}
			&.hasPrivatePictures:before {
				content: "Private Image";
				position: absolute;
				font-size: 0.8rem;
				color: #fff;
				background: #00000054;
				width: 100%;
				height: 100%;
				border-radius: 5rem;
				display: flex;
				justify-content: center;
				align-items: flex-end;
				background-image: url("../../images/icons/lockedW.svg");
				background-repeat: no-repeat;
				background-size: 10px auto;
				background-position: center;
				position: absolute;
				padding-bottom: 18px;
				@media screen and (max-width: 780px) {
					font-size: 0.6rem;
					padding-bottom: 15px;
				}
			}
			@media screen and (max-width: 780px) {
				width: 90px;
				height: 90px;
				margin-top: 0;
				margin-bottom: 0;
				margin-inline-end: 6%;
			}
			a {
				overflow: hidden;
				width: 100%;
				height: 100%;
				border-radius: 5rem;
				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
					object-position: center;
					cursor: pointer;
				}
			}
		}
	}

	.joinUs {
		position: absolute;
		bottom: 0;
		height: 180px;
		left: 0;
		width: 100%;
		background: $primary;
		border-top-left-radius: 0rem;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		color: $white;
		grid-gap: 0.5rem;
		gap: 0.5rem;
		font-size: 18px;
		font-weight: 600;
		padding-inline: 2rem;
		border-top: 5px solid $mint;
		border-left: 5px solid $mint;
		z-index: 50;
		text-align: center;
		line-height: 1.4;
		gap: 1rem;
		padding-inline-end: calc(2rem + 5px);

		button {
			background: $success;
			color: $white;
			font-size: 18px;
			font-weight: 600;
			border: 0;
			border-radius: 5rem;
			padding: 0.5rem 1.5rem;
			padding-right: 4rem;
			background-image: url("../../images/icons/like_active.svg");
			background-repeat: no-repeat;
			background-size: 25px auto;
			background-position: 85% center;
			width: auto;
			&:hover {
				background-color: $white;
				background-image: url("../../images/icons/like_active_g.svg");
				color: $success;
			}
		}
	}

	.chatPanel {
		.wrapChatLine {
			line-height: 1.3;
			a {
				.wrapLiChatTxt {
					display: flex;
					flex-direction: row;
					width: 100%;
					div {
						//align-items: flex-end;
						p {
							margin-bottom: 0 !important;
						}
						p:nth-child(4) {
							display: none;
						}
					}

					div.wrapDetailsAvatar {
						align-items: flex-start;
						flex-direction: column;
						justify-content: center;
						display: flex;
					}

					div.beforeTime {
						align-items: flex-end;
						flex-direction: column;
						display: flex;
						justify-content: flex-end;
					}
				}
			}
			&.active {
				.checkDelete {
					background: $mint;
					padding-right: 1rem;
					margin-right: -1rem;
				}
			}
			&:hover {
				.checkDelete {
					background: $mint;
					padding-right: 1rem;
					margin-right: -1rem;
				}
			}
		}
		.px-2 {
			padding-inline-end: 0.1rem !important;
		}

		h6.centeredTitle {
			display: inline-block;
			width: 100%;
			padding: 1rem;
			text-align: center;
			a {
				margin-inline-start: 0.3rem;
				border-bottom: 1px $primary solid;
			}
		}
		.loadMoreChatBtn {
			background: #fff !important;
			border-color: #fff !important;
			color: #5863be !important;
			padding: 0 16px;
		}
	}
}

.bottomStrip {
	bottom: 0;
	height: 180px;
	left: 0;
	width: 100%;
	justify-content: center;
	align-items: center;
	color: $white;
	grid-gap: 0.5rem;
	gap: 0.5rem;
	font-size: 18px;
	font-weight: 600;
	padding-inline: 2rem;
	z-index: 50;
	text-align: center;
	line-height: 1.4;
	gap: 1rem;
	padding-inline-end: calc(2rem + 5px);

	@media screen and (max-width: 780px) {
		div {
			width: 190px;
			animation-duration: 1s;
		}
		font-size: 16px;
		gap: 33px;
		position: fixed;
		display: flex;
		flex-direction: row;
		height: 38px !important;
		border-top-right-radius: 0px !important;
		border-top-left-radius: 0px !important;
		border-top: 0px !important;
		border-right: 0px !important;
		border-left: 0px !important;
		overflow: hidden;
	}
}

.contactPage.Feedback {
	padding: 30px;
	a {
		padding: 0 !important;
		display: flex;
		gap: 0.5rem;
		padding: 0 !important;
		color: $secondary !important;
		font-weight: 700;
		-webkit-margin-start: -11px;
		margin-inline-start: -11px;
		h5.mainTitle {
			font-weight: 500;
			margin-bottom: 2rem;
			color: $secondary;
		}
	}
	.form-label {
		margin-bottom: 0.2rem;
		margin-top: 1rem;
		color: $primary;
	}
	button {
		border-radius: 5rem;
		padding-inline: 1.5rem;
		margin-top: 2rem;
	}
	.wrapUploadImg {
		display: flex;
		justify-content: space-between;
		align-items: start;
		flex-direction: column;
		gap: 10px;
		img {
			max-width: 300px;
		}
	}
}

.accountPage {
	width: 100vw;
	min-height: 100vh;
	background: $mint; // #f7f9fd
	display: flex;
	justify-content: stretch;
	position: relative;
	align-items: flex-end;

	button.selected {
		background: $primary;
		color: $white;
	}

	.detailsArea {
		width: calc(100% - 22rem);
		position: relative;
		top: 0rem;
		display: flex;
		padding-inline-start: 100px;
		align-items: flex-end;
		height: 100vh;
		padding-top: 100px;

		.uneditable-message {
			position: relative;
			width: 355px;
			background: $primary;
			color: white;
			margin-top: 4px;
			padding: 10px;
			border-radius: 13px;
			right: -5px;
			a {
				color: white;
				text-decoration: underline !important;
			}
			button {
				position: absolute;
				top: 0;
				right: 0;
				left: auto;
				.ri-close-line:before {
					color: $white;
				}
			}
			div {
				padding: 0 7px;
				&.title {
					margin: 10px 0;
				}
				&.message {
					margin-bottom: 10px;
					line-height: 1.4;
				}
			}
		}

		@media screen and (max-width: 1450px) {
			padding-inline-start: 4rem;
		}

		@media screen and (max-width: 780px) {
			padding-inline-start: 0;
			width: 100%;
			position: fixed;
			transform: translateX(100%);
			right: 0;
			transition: 300ms ease-out transform;
			height: calc(100% - 80px);
			top: 0;
			z-index: 1010;
			align-items: flex-start;
			background: $mint; //#f7f9fd;
			padding-top: env(safe-area-inset-top);
			margin-top: 80px;
		}

		&.showDetails {
			transform: translateX(0%);
			transition: 300ms ease-in transform;
		}

		.paperDetails {
			background: $white;
			border-radius: 1.5rem 1.5rem 0 0;
			width: 920px;
			position: relative;
			box-shadow: 0 0rem 36px #e1e6f1;
			padding: 2rem 4rem 0;

			@media screen and (max-width: 780px) {
				width: 100%;
				box-shadow: none;
				border-radius: 0;
				height: 100%;
				padding: 1rem 0 1rem 0;
			}

			&:after {
				content: "";
				position: absolute;
				width: 110%;
				right: -5%;
				height: 10rem;
				bottom: 0;
				background: $mint; //#f7f9fd
				background: linear-gradient(0deg, $mint 0%, $mint 37%, #f1f6f700 100%);
				z-index: 50;
				pointer-events: none;
				display: none;
				@media screen and (max-width: 1450px) {
					width: 100%;
					right: 0;
					left: 0;
				}

				@media screen and (max-width: 780px) {
					width: 100%;
					right: 0;
					left: 0;
					display: none;
				}
			}

			.wrapMainTitle {
				display: flex;
				align-items: baseline;
				gap: 0;
				flex-direction: row;
				justify-content: space-between;
				width: 63%;
				@media screen and (max-width: 780px) {
					padding-inline: 9%;
					width: 100%;
				}

				a {
					display: none;

					@media screen and (max-width: 780px) {
						padding: 0 !important;
						margin-bottom: 1rem;
						color: $secondary !important;
						font-weight: bold;
						display: inline-block;
						margin-inline-start: -11px;
					}
				}

				h5.mainTitle {
					color: $secondary;
					font-weight: 500;
					margin-bottom: 2rem;
					@media screen and (max-width: 780px) {
						justify-self: flex-start;
						margin-inline-end: auto;
						padding-inline-start: 8px;
						margin-bottom: 1rem;
					}
				}
				span.requiredTitle {
					color: $require;
					font-size: 13px;
					position: relative;
					&:before {
						content: "";
						position: absolute;
						left: -15px;
						top: 6px;
						width: 8px;
						height: 8px;
						background: $require;
						border-radius: 50%;
					}
				}
			}
			.wrapAllForms {
				width: 100%;
				display: flex;
				flex-direction: column;
				grid-gap: 1.5rem;
				gap: 1.5rem;
				max-height: 74vh;
				height: 74vh;
				overflow-y: auto;
				padding-inline-end: 9%;
				padding-inline-start: 2%;
				margin-inline-start: -1%;
				padding-bottom: 8rem;
				align-items: flex-start;

				@media screen and (max-width: 780px) {
					padding-inline: 9%;
					padding-bottom: 6rem;
					max-height: 100%;
					height: auto;
				}

				.form-label {
					color: $primary;
				}

				.form-inline {
					width: 100%;
					@media screen and (max-width: 780px) {
						width: 100%;
					}
					.form-label {
						// font-weight: 500;
						color: $primary;
						// font-size: 1.17188rem;
					}
					.form-control {
						border-radius: 7px;
						border-color: $primary;
					}

					.form-floating > label {
						color: $primary;
					}

					.numrest {
						float: right;
						font-size: 0.6rem;
						margin-top: -0.8rem;
						color: $textGrey;
						text-align: end;
					}
				}

				.tagsWrap {
					.tags {
						width: 100%;
						margin-top: 0rem;
						display: flex;
						flex-wrap: wrap;
						gap: 0.5rem;
						max-height: 6.6rem;
						position: relative;
						overflow: hidden;

						&.ellipsis:after {
							content: "";
							position: absolute;
							right: 0;
							bottom: 0px;
							font-size: 3rem;
							color: $secondary;
							line-height: 0.8;
							background: linear-gradient(270deg, $white 0%, $white 37%, #f1f6f700 100%);
							height: auto;
							padding-inline: 0;
							width: 50%;
							height: 33px;
							pointer-events: none;
						}

						.tagsInline {
							display: inline-flex;
							min-height: 0;
							flex-wrap: wrap;
							gap: 0.5rem;

							h5 {
								font-weight: 500;
								color: $primary;
								display: inline-block;
								width: 100%;
							}

							.tagsBtn {
								border-radius: 3rem;
								padding-inline-start: 0.5rem;
								margin-inline-end: 0rem;
								display: inline-flex;
								flex-direction: row;
								justify-content: space-between;
								align-items: baseline;
								gap: 0.2rem;
								padding-inline-end: 1rem;
								background: $primary;
								color: $white;
								cursor: default;

								&.closeMe {
									display: none;
								}
								&.selected {
									background: $primary;
									color: $white;
								}
								&:hover .btn-close,
								&.selected .btn-close,
								.btn-close {
									opacity: 0.7;
									filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(87deg) brightness(103%)
										contrast(103%);
								}

								&.newTag {
									padding-inline: 1rem;
									color: $primary;
									background: white;

									@media screen and (max-width: 420px) {
										flex: 1 auto;
										display: flex;
										justify-content: center;
									}

									&.selected {
										background: $primary;
										color: white;
									}
								}
							}
						}
					}
				}

				span {
					color: $primary;
					font-size: 12px;
					margin-top: 10px;
					width: 100%;
					display: inline-block;
					a {
						text-decoration: underline !important;
						&:hover {
							color: $primary;
							cursor: pointer;
						}
					}
				}

				.wraptxt {
					margin-bottom: 1rem;
					width: 70%;

					@media screen and (max-width: 780px) {
						width: 100%;
					}

					h5 {
						font-weight: 500;
						color: $primary;
					}

					.wrapBtns {
						display: flex;
						row-gap: 0.5rem;
						flex-wrap: wrap;
						justify-content: flex-start;

						.regBtn {
							border-radius: 3rem;
							padding: 0 1.5rem;
							display: inline-flex;
							flex-direction: row;
							justify-content: space-between;
							align-items: baseline;
							background: white;
							color: $primary;
							border-color: $primary;
							outline: 0;
							border-style: solid;
							border-width: 1px;
							line-height: 1.5;
							margin-inline-start: 0rem;
							margin-inline-end: 0.4rem;

							&.selected,
							&:hover {
								background: $primary;
								color: white;
							}
						}
					}

					.form-select {
						border-radius: 7px;
						border-color: $primary;
					}

					.nav-link {
						border-radius: 7px;
						border-color: $primary;
						border: 1px solid;
						color: $primary;
						position: relative;

						&:after {
							content: "\ea4c";
							position: absolute;
							font-family: "remixicon" !important;
							right: 1rem;
							transition: transform ease 200ms;
							transform: rotate(0deg);
							transform-origin: center;
						}
					}
					.show {
						.nav-link:after {
							transform: rotate(180deg);
						}
					}
				}

				/*General*/
				.dropdown {
					.dropdown-menu {
						width: 100%;
						box-shadow: none;
						border-color: $primary;
						border-radius: 7px;
						padding-left: 1rem;
						padding-right: 1rem;
						max-height: 12rem;
						overflow: hidden;
						overflow-y: auto;
						background: $white;
						box-shadow: 0 5px 15px #c2d0f194;

						.dropdown-item {
							padding-inline-start: 0.5rem;
							color: $primary;
							white-space: normal;
						}
					}
				}

				.dropdown.multiSelect {
					.dropdown-menu {
						width: 100%;
						box-shadow: none;
						border-color: $primary;
						border-radius: 7px;
						padding-left: 1rem;
						padding-right: 1rem;
						max-height: 12rem;
						overflow: hidden;
						overflow-y: auto;
						background: $white;
						box-shadow: 0 5px 15px #c2d0f194;

						.dropdown-item {
							white-space: normal;
						}

						.form-check {
							display: flex;
							margin-bottom: 0.5rem;
							align-items: center;
							grid-gap: 0.5rem;
							gap: 0.5rem;
							padding-top: 0.2rem;
							padding-bottom: 0.2rem;
							padding-left: 2rem;

							&:hover {
								background: #d9e4fa3a;
							}
						}
						a,
						div {
							color: $primary;
							margin-bottom: 0 !important;
						}

						.form-label {
							color: $primary;
							font-weight: 400;
							margin-bottom: 0;
							width: 100%;
							cursor: pointer;
							display: flex;
							align-items: center;
							gap: 0.6rem;
							padding-block: 0.5rem;
							padding-inline-start: 0.5rem;
							&:hover {
								background-color: $mint;
							}
						}

						.form-check-input {
							margin-top: 0;
						}

						.form-check-input:checked {
							background-color: $primary;
							border-color: $primary;
						}
					}
				}

				.tagsWrap {
					width: 70%;
					@media screen and (max-width: 780px) {
						width: 100%;
					}
				}

				.wrapForBlockedUsers {
					width: calc(100% + 9%);
					display: flex;
					flex-direction: row;
					align-items: center;
					justify-content: flex-start;
					flex-wrap: wrap;
					grid-row-gap: 5vh;
					row-gap: 5vh;
					grid-column-gap: calc((100% - 36rem) / 6);
					column-gap: calc((100% - 36rem) / 6);
					margin-top: 5vh;
					margin-inline-end: -9%;
					@media only screen and (max-width: 780px) {
						margin-inline-end: 0;
						width: 100%;
						gap: 2rem;
						margin-top: 0;
					}

					.oneBlockedUser {
						display: flex;
						flex-direction: column;
						@media only screen and (max-width: 780px) {
							flex-direction: row;
							justify-content: flex-start;
							align-items: center;
							position: relative;
							width: 100%;
							padding-bottom: 2rem;
							border-bottom: 1px solid $lightGrey;
						}
						.imageUserBtn {
							display: flex;
							flex-direction: column;
							justify-content: flex-end;
							align-items: flex-end;
							position: relative;
							@media only screen and (max-width: 780px) {
								flex-direction: row;
								justify-content: space-between;
								align-items: center;
								width: 100%;
								display: contents;
							}
							.imageUser {
								width: 6rem;
								height: auto;
								border-radius: 50rem;
								aspect-ratio: auto 1 / 1;
								overflow: hidden;
								img {
									width: 100%;
									height: auto;
									object-fit: cover;
									object-position: center;
								}
							}
							button.nav-btn.closeButton.btn.btn-none {
								position: absolute;
								background: $textGrey;
								color: $white;
								font-size: 1.5rem;
								padding: 0;
								line-height: 0;
								border-radius: 15rem;
								border: 2px solid $white;
								width: 34px;
								height: 34px;
								display: flex;
								align-items: center;
								justify-content: center;
								left: -11px;
								bottom: -2px;
								@media only screen and (max-width: 780px) {
									left: 0;
									bottom: 0;
									top: -2rem;
									margin: auto;
								}
							}
						}
						.blockedDetails {
							margin-top: 0.9rem;
							@media only screen and (max-width: 780px) {
								margin-top: 0;
								display: flex;
								flex-direction: row;
								gap: 12%;
								width: 60%;
								padding-right: 5%;
							}
							h4 {
								font-weight: 500;
								text-align: center;
								line-height: 0.8;
								@media only screen and (max-width: 780px) {
									margin-bottom: 0;
								}
							}
							h5 {
								text-align: center;
								line-height: 1;
								font-size: 18px;
								@media only screen and (max-width: 780px) {
									margin-bottom: 0;
								}
							}
						}
					}
				}

				.wrapSwitchForEdirImages {
					width: 100%;
					display: flex;
					justify-content: center;
					align-items: flex-start;
					flex-direction: column;
					@media only screen and (max-width: 780px) {
						align-items: center;
						text-align: center;
						font-size: 10px;
					}
					.switchDesc {
						width: 100%;
						.wrapSwitch {
							@media screen and (max-width: 780px) {
								flex-direction: column;
								display: flex;
							}
							h6 {
								color: $darkGrey;
								@media only screen and (max-width: 780px) {
									font-size: 13px;
									line-height: 1;
									color: $darkGrey;
								}
							}
						}
					}
					h6 {
						color: $darkGrey;
						@media only screen and (max-width: 780px) {
							font-size: 13px;
							line-height: 1;
							color: $darkGrey;
						}
					}
				}
				.wrapUploadImg {
					display: flex;
					justify-content: space-between;
					align-items: start;
					flex-direction: column;
					gap: 10px;
					img {
						max-width: 300px;
					}
				}
			}

			.saveDataBtn {
				opacity: 1;
				transition: 200ms linear opacity;
				position: absolute;
				width: 100%;
				height: 6rem;
				bottom: 0;
				left: 0;
				z-index: 50;
				pointer-events: none;
				opacity: 1;
				transition: 200ms linear opacity;
				&:after {
					content: "";
					position: absolute;
					width: 110%;
					right: -5%;
					height: 100%;
					bottom: 0;
					background: $mint;
					background: linear-gradient(0deg, $mint 0%, $mint 65%, #f1f6f700 100%);
					z-index: 50;
					pointer-events: none;
				}
				@media screen and (max-width: 780px) {
					position: absolute;
					width: 100%;
					height: 6rem;
					bottom: 0;
					left: 0;
					z-index: 50;
					pointer-events: none;
					opacity: 1;
					transition: 200ms linear opacity;
					&:after {
						content: "";
						position: absolute;
						width: 100%;
						right: 0;
						height: 100%;
						bottom: 0;
						background: $mint;
						background: linear-gradient(0deg, $mint 0%, $mint 65%, #f1f6f700 100%);
						z-index: 50;
						pointer-events: none;
					}
				}
				&.disabled {
					opacity: 0;
					transition: 200ms linear opacity;
				}
				.mainAccountBtn {
					border-radius: 5rem;
					padding: 0.3rem 3rem;
					position: absolute;
					bottom: 2rem;
					right: 4rem;
					z-index: 500;
					opacity: 1;
					transition: 200ms linear opacity;
					pointer-events: all;
					&.btn-secondary:disabled,
					&.btn-secondary.disabled {
						opacity: 0;
						transition: 200ms linear opacity;
					}
					@media screen and (max-width: 780px) {
						bottom: 1rem;
						width: 60%;
						right: 0;
						left: 0;
						margin: auto;
					}
				}
			}

			.wrapInputValidation {
				display: flex;
				flex-direction: column;
				position: relative;
				width: 70%;

				@media screen and (max-width: 780px) {
					width: 100%;
				}

				.vi__wrapper {
					margin: 10px 0;
					.vi__container {
						height: 60px;
						width: 100%;
						display: flex;
						gap: 20px;
						direction: ltr;
						padding: 0 20px 10px;
						border-radius: 20px;
						box-shadow: 1px 1px 10px 2px#00000020;
						.character {
							color: #b1b1b1;
							border-bottom: 1px #b1b1b1 solid !important;
						}

						.vi__character {
							margin: 0;
							font-size: 1.8rem;
							border: 0px;
							border-bottom: 1px #b1b1b1 solid;
							border-radius: 0px;
							line-height: 60px;
							color: #b1b1b1;
						}

						.vi__character--inactive {
							color: #b1b1b1;
							border-bottom: 1px #b1b1b1 solid;
						}
						.vi__character--inactive--default {
							border-bottom: 1px #b1b1b1 solid;
							background-color: inherit;
							opacity: 0.5;
						}

						.vi__character--selected {
							outline: 0px solid #b1b1b1;
							color: #b1b1b1;
							border-bottom: 1px #b1b1b1 solid;
							background-color: #f1f6f7;
						}
						.vi__character--selected--default {
							border-bottom: 1px #b1b1b1 solid;
							background-color: inherit;
							outline: 0px;
						}
					}
				}

				.validation {
					position: absolute;
					bottom: -4px;
					width: 100%;
					float: left;

					span.warning {
						color: $require;
						font-weight: 300;
						padding-inline-start: 1.5rem;
						&:before {
							content: "";
							background-image: url("../../images/icons/warning.svg");
							width: 13px;
							height: 13px;
							position: absolute;
							left: 5px;
							top: 12px;
							background-size: 100%;
							background-repeat: no-repeat;
							background-position: center;
						}
					}

					span.error {
						color: $alert;
						font-weight: 300;
						padding-inline-start: 1.5rem;
						&:before {
							content: "";
							background-image: url("../../images/icons/error.svg");
							width: 13px;
							height: 13px;
							position: absolute;
							left: 5px;
							top: 12px;
							background-size: 100%;
							background-repeat: no-repeat;
							background-position: center;
						}

						&.email:before {
							content: "\ee85";
							background-image: none;
							width: auto;
							height: auto;
							font-family: "remixicon" !important;
							font-size: 0.9rem;
							top: 9px;
						}

						&.name:before {
							content: "\ea1f";
							background-image: none;
							width: auto;
							height: auto;
							font-family: "remixicon" !important;
							font-size: 0.9rem;
							top: 9px;
						}

						&.birthday:before {
							content: "\f1b0";
							background-image: none;
							width: auto;
							height: auto;
							font-family: "remixicon" !important;
							font-size: 0.9rem;
							top: 9px;
						}
					}
				}
				&.city {
					.validation {
						bottom: -20px;
					}
				}
			}

			.grow-wrap {
				display: grid;

				&::after {
					content: attr(data-replicated-value) " ";
					white-space: pre-wrap;
					visibility: hidden;
				}
				& > textarea {
					resize: none;
					overflow: hidden;
				}
				& > textarea,
				&::after {
					border-radius: 7px;
					border-color: $primary;
					white-space: pre-wrap;
					padding: 0.5rem;
					font: inherit;
					grid-area: 1 / 1 / 2 / 2;
				}
			}
		}

		&.interestArea .paperDetails .wrapAllForms {
			.tagsWrap {
				.tags {
					max-height: none;

					.tagsBtn {
						border-radius: 3rem;
						padding-left: 0.5rem;
						margin-right: 0rem;
						display: inline-flex;
						flex-direction: row;
						justify-content: space-between;
						align-items: baseline;
						gap: 0.2rem;
						padding-right: 1rem;
						background: $primary;
						color: $white;
						cursor: default;

						&.closeMe {
							display: none;
						}
						&.selected {
							background: $primary;
							color: $white;
						}
						&:hover .btn-close,
						&.selected .btn-close,
						.btn-close {
							opacity: 0.7;
							filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(87deg) brightness(103%)
								contrast(103%);
						}

						&.newTag {
							padding-inline: 1rem;
							color: $primary;
							background: white;
							&:hover {
								background: $primary;
								color: white;
								cursor: pointer;
							}

							@media screen and (max-width: 420px) {
								flex: 1 auto;
								display: flex;
								justify-content: center;
							}

							&.selected {
								background: $primary;
								color: white;
							}
						}
					}
				}
			}
		}

		.wrapTakanon {
			text-align: justify;
		}

		&.contactPage {
			.wrapAllForms {
				gap: 0;
			}
		}

		&.wrapAllImages {
			.paperDetails {
				padding-left: 3rem;
				@media screen and (max-width: 780px) {
					padding-left: 6%;
				}
				.wrapAllForms {
					display: inline-flex;
					flex-direction: row;
					flex-wrap: wrap;
					padding-inline-end: 3rem;
					row-gap: 4rem;
					justify-content: space-between;
					padding-top: 2rem;
					padding-bottom: 12rem;

					@media screen and (max-width: 780px) {
						padding-inline-end: 8%;
						gap: 0;
						row-gap: 1.5rem;
						justify-content: space-between;
						padding-top: 0;
						align-items: flex-start;
						height: auto;
						padding-left: 3%;
					}

					.wrapOneImage {
						border-radius: 20px;
						padding: 5px;
						overflow: visible;
						width: 220px;
						height: 261.9px;
						position: relative;
						filter: drop-shadow(0x 0px 0px #446ac300);
						transition: ease-out all 300ms;
						background: $white;
						opacity: 0.9;
						cursor: pointer;
						border: 1px solid $lightGrey;
						display: flex;
						align-items: center;
						justify-content: center;

						@media screen and (max-width: 780px) {
							border-radius: 10px;
							padding: 3px;
							height: auto;
							justify-content: center;
							width: 25vw;
							height: 30.125vw;
							.noFace {
								font-size: 11px;
							}
						}

						img {
							filter: brightness(1);
							transition: ease-out filter 400ms;
							object-fit: cover;
							width: 100%;
							height: 100%;
							border-radius: 16px;
							@media screen and (max-width: 780px) {
								border-radius: 8px;
							}
						}

						&:hover {
							filter: drop-shadow(2px 4px 6px #4068c04d);
							opacity: 1;
							transition: ease-out all 300ms;
							background: $white;
						}

						&.mainImg {
							border: 3px solid $secondary;
							@media screen and (max-width: 780px) {
								border: 2px solid $secondary;
							}
						}

						&.empty {
							border: 1px dashed #cfcfcf;
							position: relative;
							display: flex;
							justify-content: center;
							align-items: center;
							line-height: 3;
							span {
								font-size: 1rem;
								position: absolute;
								width: 100%;
								height: 100%;
								display: flex;
								justify-content: center;
								align-items: center;
								margin-top: 0;
								flex-direction: column;
								@media screen and (max-width: 780px) {
									font-size: 0.6rem;
									height: 3.2rem;
									pointer-events: none;
								}

								&:before {
									content: "+";
									font-size: 2rem;
									position: relative;
									width: 3rem;
									height: 3rem;
									border: 1px solid;
									display: flex;
									justify-content: center;
									align-items: center;
									border-radius: 30rem;
									top: 0;
									right: 0;
									left: 0;
									margin: auto;
									margin-bottom: 0;
									margin-top: 0;
									@media screen and (max-width: 780px) {
										font-size: 1rem;
										width: 1.5rem;
										height: 1.5rem;
									}
								}
							}

							input#contained-button-file {
								position: absolute;
								height: 100%;
								width: 100%;
								top: 0;
								left: 0;
								z-index: 100;
								opacity: 0;
								cursor: pointer;
								max-width: 100%;
							}
						}

						&.waiting {
							position: relative;
							&:after {
								content: "";
								position: absolute;
								background-image: url("../../images/icons/waiting.svg");
							}
						}

						&.blocked {
							overflow: visible;
							position: relative;

							h6 {
								color: $white;
								position: absolute;
								width: calc(100% - 25px);
								line-height: 1;
								display: inline-block;
								background: #404040;
								left: 0.3rem;
								top: 0.3rem;
								display: flex;
								justify-content: center;
								align-items: center;
								height: 2rem;
								z-index: 2;

								@media screen and (max-width: 780px) {
									left: calc(1% + 2px);
									top: calc(1% + 2px);
									font-size: 0.5rem;
									text-align: center;
									width: calc(100% - (4px + 2%));
									padding-right: 1rem;
									padding-left: 0.5rem;
									height: 1.3rem;
								}

								&:after {
									content: "";
									width: 50px;
									height: 50px;
									background-image: url("../../images/icons/blocked.svg");
									background-size: 60%;
									background-color: $alert;
									background-position: center;
									background-repeat: no-repeat;
									border-radius: 50rem;
									right: 0;
									z-index: 4;
									top: 0;
									transform: translate(50%, -3px);
									position: absolute;
									@media screen and (max-width: 780px) {
										width: 1.5rem;
										height: 1.5rem;
										transform: translate(50%, 0);
										bottom: 0;
										margin: auto;
									}
								}
							}

							img {
								filter: grayscale(1) opacity(0.5);
							}

							input#contained-button-file {
								position: absolute;
								height: 100%;
								top: 0;
								left: 0;
								z-index: 100;
								opacity: 0;
								cursor: pointer;
							}
						}

						&.waiting {
							overflow: visible;
							position: relative;

							h6 {
								color: $white;
								position: absolute;
								width: calc(100% - 25px);
								line-height: 1;
								display: inline-block;
								background: #404040;
								left: 0.3rem;
								top: 0.3rem;
								display: flex;
								justify-content: center;
								align-items: center;
								height: 2rem;
								z-index: 2;

								@media screen and (max-width: 780px) {
									left: calc(1% + 2px);
									top: calc(1% + 2px);
									font-size: 0.5rem;
									text-align: center;
									width: calc(100% - (4px + 2%));
									padding-right: 1rem;
									padding-left: 0.5rem;
									height: 1.3rem;
								}

								&:after {
									content: "";
									width: 50px;
									height: 50px;
									background-image: url("../../images/icons/waiting.svg");
									background-size: 60%;
									background-color: #ffb300;
									background-position: center;
									background-repeat: no-repeat;
									border-radius: 50rem;
									right: 0;
									z-index: 4;
									top: 0;
									transform: translate(50%, -3px);
									position: absolute;

									@media screen and (max-width: 780px) {
										width: 1.5rem;
										height: 1.5rem;
										transform: translate(50%, 0);
										bottom: 0;
										margin: auto;
									}
								}
							}
						}

						/*  .editButtons {
              position: absolute;
              z-index: 50;
              // top: 0;
              // left: 0;
              width: 100%;
              height: 3rem;
              background: #0000;
              display: flex;
              flex-direction: row;
              gap: 1rem;
              justify-content: center;
              //  transform: translateY(0%);
              opacity: 0;
              transition: ease-out opacity 300ms;*/

						.roundBtn {
							z-index: 50;
							position: absolute;
							width: 3rem;
							height: 3rem;
							background-color: rgba(255, 255, 255, 0.7);
							filter: drop-shadow(2px 3px 5px #00000050);
							border-radius: 5rem;
							left: 1rem;
							bottom: 1rem;
							border: 0;

							@media screen and (max-width: 780px) {
								width: 1.8rem;
								height: 1.8rem;
								left: 0.6rem;
								bottom: 0.6rem;
							}

							&.edit {
								background-image: url("../../images/icons/edit.svg");
								background-size: 50%;
								background-position: center;
								background-repeat: no-repeat;
								transition: all ease-in 200ms;
								&:hover {
									background-color: $white;
									transition: all ease-in 200ms;
								}
							}
						}
						/*}*/

						&.active {
							filter: drop-shadow(2px 4px 6px #4068c04d);
							transition: ease-out all 200ms;
							background: $white;

							.editButtons {
								// transform: translateY(-110%);
								opacity: 1;
								transition: ease-out all 200ms;
							}

							img {
								filter: brightness(0.8);
								transition: ease-in filter 200ms;
							}
						}
					}
				}
			}
		}
	}

	input[type="date" i] {
		display: flex;
		flex-direction: row;
	}

	input[type="date" i].datepickerRtl {
		flex-direction: row-reverse;
	}

	input[type="date" i]::-webkit-calendar-picker-indicator {
		background-image: url("../../images/icons/calB.svg");
		cursor: pointer;
		margin: 0;
		width: 100%;
		background-position: 100%;
	}

	input[type="date" i].datepickerRtl::-webkit-calendar-picker-indicator {
		background-image: url("../../images/icons/calB.svg");
		background-position: 100%;
	}

	input::-webkit-datetime-edit {
		position: absolute;
		z-index: 0;
		pointer-events: none;
		margin-inline-start: 0;
		text-align: left;
	}

	input.datepickerRtl::-webkit-datetime-edit {
		text-align: left;
	}

	.wrapNotes h6 {
		font-weight: 300;
		border: 0px solid;
		border-radius: 7px;
		padding: 0.7rem;
		background: $white;
		font-size: 0.7rem;
		margin-top: 0.5rem;
	}

	.form-check-input {
		margin-top: 0;
	}

	.wrapSwitch {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 0.5rem;
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
		h6 {
			margin-bottom: 0;
		}

		.form-check-input:checked {
			background-color: $primary;
			border-color: $primary;
		}

		.form-check-input:focus {
			box-shadow: none;
		}
	}
}

/*GLOBAL CLASSES*/

.dropdown {
	.nav-link {
		border-radius: 7px;
		border-color: $primary;
		border: 1px solid;
		color: $primary;
		position: relative;
		&:after {
			content: "\ea4c";
			position: absolute;
			font-family: "remixicon" !important;
			right: 1rem;
			transition: transform ease 200ms;
			transform: rotate(0deg);
			transform-origin: center;
		}
	}
	.dropdown-menu {
		width: 100%;
		box-shadow: none;
		border-color: $primary;
		border-radius: 7px;
		padding-left: 1rem;
		padding-right: 1rem;
		max-height: 12rem;
		overflow: hidden;
		overflow-y: auto;
		background: $white;
		box-shadow: 0 5px 15px #c2d0f194;

		.dropdown-item {
			padding-inline: 0rem;
			color: $primary;
			white-space: normal;
			font-size: 15px;
		}
	}

	&.show .nav-link:after {
		content: "\ea4c";
		transition: transform ease 200ms;
		transform: rotate(180deg);
		transform-origin: center;
	}
}

.dropdown.multiSelect {
	.dropdown-menu {
		width: 100%;
		box-shadow: none;
		border-color: $primary;
		border-radius: 7px;
		padding-left: 1rem;
		padding-right: 1rem;
		max-height: 12rem;
		overflow: hidden;
		overflow-y: auto;
		background: $white;
		box-shadow: 0 5px 15px #c2d0f194;

		.dropdown-item {
			white-space: normal;
		}

		.form-check {
			display: flex;
			margin-bottom: 0.5rem;
			align-items: center;
			grid-gap: 0.5rem;
			gap: 0.5rem;
			padding-top: 0.2rem;
			padding-bottom: 0.2rem;
			padding-left: 2rem;

			&:hover {
				background: #d9e4fa3a;
			}
		}
		a,
		div {
			color: $primary;
			margin-bottom: 0 !important;
		}

		.form-label {
			color: $primary;
			font-weight: 400;
			margin-bottom: 0;
			width: 100%;
			cursor: pointer;
			display: flex;
			align-items: center;
			gap: 0.6rem;
			padding-block: 0.5rem;
			padding-inline-start: 0.5rem;
			&:hover {
				background-color: $mint;
			}
		}

		.form-check-input {
			margin-top: 0;
		}

		.form-check-input:checked {
			background-color: $primary;
			border-color: $primary;
		}
	}
}

.btn-outline-primary:disabled,
.btn-outline-primary.disabled,
a:hover {
	color: $primary;
}

.popover {
	border-radius: 7px;
	border-color: $primary;
	.popover-inner {
		text-align: start;
		.nav-link {
			color: $primary;
			padding-inline-start: 1.7rem;
			display: inline-block;
			&.edit,
			&.pin,
			&.trash {
				position: relative;
				&:before {
					content: "";
					position: absolute;
					top: 0;
					bottom: 0;
					left: 0;
					width: 1rem;
					height: 100%;
					background-image: url("../../images/icons/edit.svg");
					background-size: 100%;
					background-repeat: no-repeat;
					background-position: center;
				}
			}
			&.pin:before {
				background-image: url("../../images/icons/pin.svg");
				background-size: 130%;
			}
			&.trash:before {
				background-image: url("../../images/icons/trash.svg");
				background-size: 90%;
			}

			&:hover {
				background: #efefef41;
			}
		}
	}
}

.freezeAccount {
	// border-bottom:1px solid ;
	width: auto;
	display: inline-block;
}

a.deleteAccount,
a.deleteAccount:hover {
	color: #999;
}

.modal .modal-body {
	.wraptxt {
		color: $primary;
		line-height: 1.3;
	}
	@media screen and (max-width: 780px) {
		padding: 0 1.5rem;
		margin-bottom: 0.2rem;
	}
}

.wrapSwitch.toggleSwitch {
	.form-check-input {
		width: 2.8rem;
		height: 1.3rem;
		font-size: 1.5rem;
		position: relative;
		background: #eee;
		border: 0px solid $primary;
		border-radius: 5rem;
		transition: background 200ms linear;
		cursor: pointer;
		&:active {
			filter: brightness(100%);
		}
	}

	.form-check-input ~ h6 {
		color: #999;
		max-width: calc(100% - 2.6rem);
		@media screen and (max-width: 780px) {
			width: calc(100% - 3.3rem);
			max-width: 100%;
		}
	}

	.form-check-input:after {
		visibility: visible;
		content: "\eb63";
		font-family: "remixicon" !important;
		font-size: 0.8rem;
		color: $white;
		width: 0.9rem;
		height: 0.9rem;
		background: $white;
		border-radius: 50rem;
		position: absolute;
		top: 0;
		bottom: 0;
		margin: auto;
		right: 3px;
		border: 0;
		box-shadow: -2px 3px 7px rgba(150, 150, 150, 0.52);
		transition: transform 200ms linear;
		transform: translateX(0%);
		display: flex;
		justify-content: center;
		align-items: center;
		font-weight: bold;
	}

	.form-check-input:checked {
		transition: background 200ms linear;
		&:after {
			content: "\eb63";
			text-indent: -1.3rem;
			color: transparent;
			font-weight: bold;
			font-family: "remixicon" !important;
			transform: translateX(-100%);
			transition: transform 200ms linear;
			border: 0;
			right: 13px;
		}
	}

	.form-check-input:checked ~ h6 {
		color: #4c4c4c;
	}
}

.header {
	//background: #0000 linear-gradient(89deg, $primary 0%, $secondary 100%) 0% 0% no-repeat padding-box;
	background: $jsmartGradientTriple;
	box-sizing: border-box;
	min-height: 100px;
	align-items: center;
	padding-inline-end: 113px;
	flex-direction: row;
	justify-content: space-between;
	border-bottom-right-radius: 0;
	color: $white;
	top: 0;
	left: 0;
	right: 0;
	position: fixed;
	width: 100%;
	display: flex;
	z-index: 1010;
	box-sizing: border-box;
	flex-shrink: 0;

	.innerHeader {
		display: flex;
		width: 100%;
		flex-direction: row-reverse;
		align-items: center;
		gap: 4rem;

		.siteLogo {
			width: 140px;
			img {
				width: 100%;
			}
			@media screen and (max-width: 1280px) {
				width: 130px;
			}
		}

		button.purchaseMinuyBtn {
			background-color: $success;
			color: $white;
			background-image: url("../../images/icons/like_active.svg");
			background-repeat: no-repeat;
			background-size: 22px auto;
			background-position: 87%;
			border: 0;
			border-radius: 10rem;
			font-weight: 500;
			padding: 0.5rem 1.2rem;
			padding-inline-end: 3.2rem;
			box-shadow: 0px 3px 1px -2px #00000033, 0px 2px 2px 0px #00000024, 0px 1px 5px 0px #0000001f;
			&:hover {
				background: $white;
				color: $success;
				background-image: url("../../images/icons/like_active_g.svg");
				background-repeat: no-repeat;
				background-size: 22px auto;
				background-position: 87%;
			}

			@media screen and (max-width: 780px) {
				display: none;
			}
		}

		.nav {
			display: flex;
			flex-wrap: wrap;
			padding-left: 0;
			margin-bottom: 0;
			list-style: none;
			justify-self: flex-end;
			margin-inline-start: 0;
			margin-inline-end: auto;
			gap: 1.5rem;
			align-items: center;

			@media screen and (max-width: 1280px) {
				gap: 0;
			}
		}

		> li:nth-child(7) {
			display: none;
			justify-content: center;
			@media screen and (max-width: 780px) {
				display: flex;
				width: auto;
			}
		}

		> li:nth-child(3) {
			padding-inline-start: 0;
			@media screen and (max-width: 1450px) {
				padding-inline-start: 0;
			}
		}

		.nav-item {
			opacity: 1;
			@media screen and (max-width: 1280px) {
				transform: scale(0.8);
			}
			.nav-link {
				height: 72%;
				padding-top: 0.5rem;
				padding-bottom: 0;
				@media screen and (max-width: 1600px) {
					padding-inline: 0.2rem;
				}
				@media screen and (max-width: 1280px) {
					flex-direction: column;
					padding-inline: 1rem;
				}
				.wrapBadge {
					filter: $finvert;
				}
			}
			&:active,
			&:hover,
			&.active {
				opacity: 1;
				.nav-link {
					height: 72%;
					padding-top: 0.5rem;
					padding-bottom: 0;
					//background: $whitefff21;
					border-radius: 0.5rem;

					.wrapBadge {
						&.searchIconNav {
							background-image: url("../../images/icons/searchOpaq.svg");
							background-size: 100%;
							transition: background-image 300ms linear;
						}
						&.pairsIconNav {
							background-image: url("../../images/icons/pairsOpaq.svg");
							background-size: 100%;
							transition: background-image 300ms linear;
						}

						&.messagesIconNav {
							background-image: url("../../images/icons/chatOpaq.svg");
							background-size: 100%;
							transition: background-image 300ms linear;
						}

						&.likesIconNav {
							background-image: url("../../images/icons/heartOpaq.svg");
							background-size: 100%;
							transition: background-image 300ms linear;
						}

						&.viewsIconNav {
							background-image: url("../../images/icons/viewsOpaq.svg");
							background-size: 100%;
							transition: background-image 300ms linear;
						}
					}

					p {
						text-shadow: 1px 0 $white;
						letter-spacing: 1px;
						@media screen and (max-width: 780px) {
							text-shadow: none;
						}

						&:before {
							content: "";
							position: absolute;
							transform: scaleX(1);
							transition: transform 300ms linear;
							width: 100%;
							height: 1px;
							background: $white;
							bottom: -14px;
							@media screen and (max-width: 780px) {
								bottom: -6px;
							}
						}
					}
				}
			}

			.nav-link {
				color: $white;
				display: flex;
				align-items: center;
				height: 100%;

				.wrapBadge {
					display: flex;
					align-items: flex-start;
					height: 100%;
					gap: 0rem;
					margin-right: 0.4rem;
					position: relative;
					transform: scaleX(-1);
					&.mobileOnly {
						display: none;
					}
					span {
						position: absolute;
						left: 22px;
						background-color: $alert !important;
						filter: $finvert;
						width: auto;
						height: 1rem;
						display: flex;
						justify-content: center;
						align-items: center;
						z-index: 50;
						padding: 0.2rem 0.4rem;
						top: 0px;
						font-size: 10px;
						font-weight: 500;
						transform: scaleX(-1);
						&:empty {
							display: none;
						}
					}
					img {
						height: 100%;
						@media screen and (max-width: 780px) {
							height: 90%;
						}
					}

					&.searchIconNav {
						background-image: url("../../images/icons/searchUserNav.svg");
						background-size: 100%;
						transition: background-image 300ms linear;
						&:hover {
							background-image: url("../../images/icons/searchOpaq.svg");
							background-size: 100%;
							transition: background-image 300ms linear;
						}
						&:before {
							content: "";
							position: absolute;
							left: 0;
							top: 0;
							transform: translate(-137%, -73%) scaleX(1);
							background-image: url("../../images/icons/birdsHeader.svg");
							background-size: 100%;
							background-position: center;
							background-repeat: no-repeat;
							width: 120px;
							height: 100%;
							filter: $finvert;
							@media screen and (max-width: 780px) {
								display: none;
							}
						}
					}

					&.pairsIconNav {
						background-image: url("../../images/icons/pairsNav.svg");
						background-size: 100%;
						transition: background-image 300ms linear;
						&:hover {
							background-image: url("../../images/icons/pairsOpaq.svg");
							background-size: 100%;
							transition: background-image 300ms linear;
						}
						~ p {
							@media screen and (max-width: 780px) {
								letter-spacing: 0;
							}
						}
						span {
							left: 27px;
						}
					}

					&.messagesIconNav {
						background-image: url("../../images/icons/chatNav.svg");
						background-size: 100%;
						transition: background-image 300ms linear;
						&:hover {
							background-image: url("../../images/icons/chatOpaq.svg");
							background-size: 100%;
							transition: background-image 300ms linear;
						}
						@media screen and (max-width: 780px) {
							letter-spacing: 0;
						}
					}

					&.likesIconNav {
						background-image: url("../../images/icons/heartNav.svg");
						background-size: 100%;
						transition: background-image 300ms linear;
						&:hover {
							background-image: url("../../images/icons/heartOpaq.svg");
							background-size: 100%;
							transition: background-image 300ms linear;
						}
						@media screen and (max-width: 780px) {
							margin-right: 0.5rem !important;
							margin-left: 0.5rem !important;
						}
					}

					&.viewsIconNav {
						background-image: url("../../images/icons/viewsNav.svg");
						background-size: 100%;
						transition: background-image 300ms linear;
						&:hover {
							background-image: url("../../images/icons/viewsOpaq.svg");
							background-size: 100%;
							transition: background-image 300ms linear;
						}
						@media screen and (max-width: 780px) {
							margin-right: 0.5rem !important;
							margin-left: 0.5rem !important;
						}
					}

					&.userIconNav {
						background-image: url("../../images/icons/userIconNav.svg");
						background-size: 100%;
						transition: background-image 300ms linear;
						&:hover {
							background-image: url("../../images/icons/userOpaq.svg");
							background-size: 100%;
							transition: background-image 300ms linear;
						}
						&.mobileOnly ~ p {
							display: none;
							@media screen and (max-width: 780px) {
								display: block;
							}
						}
					}
				}

				p {
					color: $white;
					display: inline-flex;
					align-items: center;
					height: 100%;
					margin-bottom: 0;
					font-weight: 400;
					position: relative;
					letter-spacing: 1px;
					&:before {
						content: "";
						position: absolute;
						transform: scaleX(0);
						transition: transform 300ms linear;
						width: 100%;
						height: 1px;
						background: $white;
						bottom: -14px;
						@media screen and (max-width: 780px) {
							bottom: -6px;
						}
					}

					// &:after {
					// 	content: attr(data-text);
					// 	height: 0;
					// 	visibility: hidden;
					// 	overflow: hidden;
					// 	user-select: none;
					// 	pointer-events: none;
					// 	font-weight: 500;
					// 	transform: translateX(100%);
					// }
				}
			}
		}
	}

	.userTab {
		width: 350px;
		display: flex;
		align-items: center;
		padding-bottom: 1rem;
		gap: 0;
		justify-content: center;
		padding-inline-end: 3rem;
		margin-inline-end: calc(100px - 3rem);

		@media screen and (max-width: 1600px) {
			.nav-item .nav-link {
				padding: 0.3rem;
			}
		}

		@media screen and (max-width: 1280px) {
			width: 300px;
		}

		.hide {
			display: none;
		}
		.nav-item {
			a {
				padding: 0.3rem;
				button {
					padding-inline-end: 0.5rem;
					img {
						width: 1.3rem;
						height: 1.3rem;
						filter: invert(99%) sepia(6%) saturate(0%) hue-rotate(305deg) brightness(110%)
							contrast(100%);
					}
				}
				.userImage {
					width: 2.3rem;
					height: 2.3rem;
					overflow: hidden;
					border-radius: 10rem;
					pointer-events: none;

					img {
						object-fit: cover;
						width: 100%;
						height: 100%;
					}
				}

				.userDtails {
					font-size: 0.8rem;
				}
			}
		}
	}

	@media screen and (max-width: 1450px) {
		padding-inline-end: 50px;
	}

	@media screen and (max-width: 1200px) {
		.nav-link {
			flex-direction: column;
		}
	}

	@media screen and (max-width: 1100px) {
		.innerHeader .nav {
			grid-gap: 1rem;
			gap: 1rem;
		}
	}

	@media screen and (max-width: 1000px) {
		.innerHeader .nav .nav-item {
			display: flex;
			align-items: center;
			.nav-link {
				height: auto;
				padding-bottom: 0;
				p {
					//display: none;
				}
			}
		}
	}

	// @media screen and (max-width: 780px) {
	// 	height: 80px;
	// 	min-height: 0;
	// 	background: $white;
	// 	z-index: 1020;
	// 	box-shadow: 0px 3px 1px -2px #00000033, 0px 2px 2px 0px #00000024, 0px 1px 5px 0px #0000001f;
	// 	border-radius: 0;
	// 	padding-inline-end: 0;
	// 	background-color: $primary;
	// 	background-image: $mobileHeaderGradient;
	// 	padding-bottom: 1rem;
	// 	padding-top: env(safe-area-inset-top);
	// 	height: calc(80px + (env(safe-area-inset-top)));

	// 	&:before {
	// 		content: "";
	// 		position: absolute;
	// 		left: 0;
	// 		top: 4px;
	// 		transform: translate(192%, -13%) scaleX(1);
	// 		background-image: url("../../images/icons/birdsHeader.svg");
	// 		background-size: 67%;
	// 		background-position: center;
	// 		background-repeat: no-repeat;
	// 		width: 108px;
	// 		height: 48%;
	// 	}

	// 	.innerHeader {
	// 		.purchaseMinuy {
	// 			display: none;
	// 		}
	// 		.siteLogo {
	// 			display: none;
	// 		}

	// 		.nav {
	// 			width: 100%;
	// 			gap: 0;
	// 			justify-content: space-between;
	// 			padding-right: 0.5rem;
	// 			padding-left: 0.5rem;
	// 			margin-top: 1rem;

	// 			.userTab {
	// 				display: none;
	// 			}

	// 			li:nth-child(2) {
	// 				padding-inline-start: 0;
	// 			}

	// 			> li:nth-child(2) {
	// 				width: auto;
	// 				display: flex;
	// 			}

	// 			> li:nth-child(3) {
	// 				padding-inline-start: 0;
	// 			}

	// 			.nav-item {
	// 				opacity: 1;
	// 				.nav-link {
	// 					padding-inline: 0;
	// 					padding-block-end: 0;
	// 					.wrapBadge {
	// 						margin-right: 0;
	// 						&.mobileOnly {
	// 							display: flex;
	// 						}
	// 					}

	// 					img {
	// 						//filter: invert(32%) sepia(7%) saturate(21%) hue-rotate(349deg) brightness(99%) contrast(88%);
	// 						height: 80%;
	// 					}
	// 					p {
	// 						//display: none;
	// 					}
	// 				}
	// 				&.active {
	// 					img {
	// 						//filter: invert(44%) sepia(24%) saturate(1563%) hue-rotate(184deg) brightness(87%) contrast(86%);
	// 					}
	// 				}
	// 			}
	// 		}
	// 	}
	// }
}

@media screen and (max-width: 1000px) {
	.layout-wrapper.d-lg-flex {
		display: flex !important;
	}
}

.topBar {
	width: calc(100% - 350px);
	position: fixed;
	display: flex;
	padding-inline-start: 100px;
	height: fit-content;
	padding-inline-end: 170px;
	padding-block-end: 10px;
	align-items: center;
	justify-content: space-between;
	background: $white;
	z-index: 100;
	padding-top: 20px;
	top: 100px;
	right: 0;
	&.upSlide {
		transition: transform 500ms ease-out, visibility 500ms ease-out;
		transform: translateY(-175%);
		position: fixed;
	}
	&.downSlide {
		transition: transform 500ms ease-in, visibility 500ms ease-in;
		transform: translateY(0%);
		position: fixed;
		visibility: visible;
		pointer-events: all;
	}
	&.visibleNone {
		opacity: 0;
		pointer-events: none;
		transition: opacity 300ms ease-in;
	}
	&.likedMeTopBar {
		width: 100%;
		display: flex;
		justify-content: center;
		z-index: 501;
	}
	&:not(.staticBar):after {
		content: "";
		position: absolute;
		width: 100%;
		right: 0;
		height: 75%;
		bottom: 2px;
		background: $white;
		background: linear-gradient(0deg, #fff0 10%, #ffffffd6 53%, #ffffffed 59%, $white 100%);
		z-index: -1;
		pointer-events: none;
		transform: translateY(100%);
		@media screen and (max-width: 780px) {
			height: 25%;
		}
	}
	@media screen and (max-width: 1650px) {
		padding-inline-start: 100px;
	}

	@media screen and (max-width: 1300px) {
		padding-inline-start: 60px;
	}

	@media screen and (max-width: 1000px) {
		padding-inline-start: 50px;
		padding-inline-end: 20px;
	}

	@media screen and (max-width: 780px) {
		flex-direction: column;
		gap: 0.5rem;
		padding-inline-end: 1rem;
		padding-block-end: 0rem;
		padding-inline-start: 1rem;
		width: 100%;
		align-items: flex-end;
		padding-top: 0;
		margin-right: 0;
		margin-left: 0;
		position: fixed;
		top: 80px;
		padding-top: env(safe-area-inset-top);
		height: auto;
		z-index: 1011;
	}

	.searchBar {
		width: 50%;
		position: relative;
		padding: 0.5rem 0rem;
		@media screen and (max-width: 1200px) {
			width: 95%;
		}
		@media screen and (max-width: 780px) {
			width: 100%;
			padding: 1.5rem 0rem 0.5rem 0;
		}

		&:before {
			content: "\00a0";
			left: 0;
			right: 0;
			bottom: 0;
			position: absolute;
			border-bottom: 2px solid $primary;
			pointer-events: none;
		}

		&:after {
			content: "\00a0";
			left: 0;
			right: 0;
			bottom: 0;
			position: absolute;
			border-bottom: 0px solid $primary;
			transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
			pointer-events: none;
			transform: scaleX(0);
			transform-origin: 0;
		}

		&.focused:after {
			transform: scaleX(1);
			border-bottom: 2px solid $primary;
			transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
		}

		&.blurred:after {
			content: "";
			transform: scaleX(0);
			transition: all 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
			border-bottom: 2px solid $primary;
		}

		/*search input*/

		input[type="search"] {
			border-radius: 0;
			border: 0;
			padding: 0 0;
			width: 100%;
			// padding-inline-start:1rem;
			background-color: transparent;
			text-indent: 15px;
			&::placeholder {
				text-indent: 15px;
			}
		}

		.form-control::placeholder {
			text-indent: 0px;
			@media screen and (max-width: 780px) {
				text-indent: 0px;
			}
		}

		input::-webkit-textfield-decoration-container {
			direction: ltr;
			align-items: baseline;
		}

		input::-webkit-search-cancel-button {
			margin-inline-start: 20px;
			@media screen and (max-width: 780px) {
				margin-inline-start: 10px;
			}
		}

		/*end search input*/

		button {
			position: absolute;
			top: 0;
			right: -11px;
			bottom: 0;
			margin: auto;
			border: 0;
			width: 36px;
			height: 36px;
			border-radius: 20rem;
			filter: drop-shadow(0px 1px 0px $primary);
			@media screen and (max-width: 780px) {
				top: 14px;
				right: 0px;
			}

			&:hover {
				background-color: transparent;
				//border-radius: 20rem;
			}
			&:after {
				content: "";
				background-image: url("../../images/icons/search.svg");
				background-position: center;
				background-repeat: no-repeat;
				background-size: 45%;
				width: 36px;
				height: 36px;
				position: absolute;
				top: 0;
				left: 0;
				filter: $fprimary;
			}
		}

		button.searchX {
			&:after {
				background-image: none;
			}
			display: none;
			background-image: none;
			background-color: transparent;
			position: absolute;
			left: -655px;
			color: $primary;
			font-weight: 900;
			&.show {
				display: block;
			}
			@media screen and (max-width: 780px) {
				left: -350px;
			}
		}
	}

	Button.tagsBtn.newTag {
		display: none;
		@media screen and (max-width: 780px) {
			border-radius: 5rem;
			border: 0;
			padding: 0.1rem 1rem;
			justify-self: flex-end;
			margin-inline-end: 0;
			margin-inline-start: auto;
			width: 20%;
			display: block;
			// background-image: url("../../images/icons/filter.svg");
			height: 2.2rem;
			background-repeat: no-repeat;
			background-position: 100%;
			filter: none;
			&:hover {
				background-color: transparent;
			}
			&:active {
				box-shadow: none;
				background-color: #de5e9841;
			}
		}

		&.showSearcFilterhBar {
			background-size: 1.1rem;
			right: 0;
			width: 10%;
		}
	}

	.filterButtons {
		span {
			display: none;
		}
		&.searchFilter {
			display: flex;
			align-items: baseline;
			gap: 1rem;
			span {
				display: block;
				color: $primary;
				font-weight: bold;
				font-size: 0.8rem;
				@media screen and (max-width: 780px) {
					display: none;
				}
			}
			.btn-group {
				@media screen and (max-width: 780px) {
					width: 100%;
				}
			}
		}
		@media screen and (max-width: 780px) {
			width: 100%;
			&.likedMeTabs {
				padding-top: 13px;
			}
		}

		.btn-group {
			padding: 5px;
			background: $mint;
			margin-top: 10px;
			font-weight: 500;
			border-radius: 5rem;
			display: flex !important;

			.btn {
				border: 0;
				padding: 0px 20px;
				border-radius: 5rem !important;
				color: $primary;
				margin-left: 4px;
				font-size: 13px;
				background: $mint !important;
				font-weight: 500;
				@media screen and (max-width: 780px) {
					padding: 0px 3%;
					font-size: 13px;
				}

				&.active {
					border: 0;
					background: $primary !important;
					padding: 4px 30px;
					border-radius: 5rem !important;
					box-shadow: 0px 3px 1px -2px #00000033, 0px 2px 10px 0px #00000024,
						0px 1px 10px 0px #0000001f;
					overflow: visible;
					color: $white;
					margin-left: 0;
					@media screen and (max-width: 780px) {
						padding: 2px 30px;
					}
				}
			}
		}
	}

	.wrapTopPartBar {
		display: contents;
		@media screen and (max-width: 780px) {
			width: 100%;
			display: flex;
			align-items: flex-end;
			justify-content: space-between;
		}
	}
	.clearFilter,
	.showSearcFilterhBar {
		position: relative;
		@media screen and (max-width: 780px) {
			span.dot {
				position: absolute;
				width: 6px;
				height: 6px;
				z-index: 10;
				top: 5px;
				left: -1px;
				background: $alert;
				border-radius: 1rem;
			}
		}
	}

	&.mathesTopBar {
		padding-inline-start: 135px;
		padding-inline-end: 143px;
		transform: translateX(-50%);
		left: 50%;
		display: flex;
		justify-content: center;
		@media screen and (max-width: 780px) {
			padding-inline-start: 1rem;
			padding-inline-end: 1rem;
		}
	}
}

.searchPage,
.likeMePage,
.matchesPage,
.viewedMePage {
	width: calc(100vw - 350px);
	min-height: 100vh;
	background: $white;
	display: flex;
	position: relative;
	padding-top: 100px;
	overflow-y: scroll;
	flex-direction: column;

	@media screen and (max-width: 780px) {
		padding-inline-start: 0;
		width: 100%;
		position: relative;
		transition: 300ms ease-out transform;
		align-items: flex-start;
		padding-top: calc(185px + env(safe-area-inset-top));
		overflow-y: auto;
	}

	.emptySearch {
		width: 100%;
		display: flex;
		justify-content: flex-start;
		margin-top: 150px;
		padding-left: 150px;

		div {
			color: $primary;
			a {
				color: $secondary;
			}
		}

		@media screen and (max-width: 780px) {
			margin-top: 50px;
			justify-content: center;
			padding-right: 0;
			padding-left: 0;
			div {
				width: 80%;
			}
		}
	}

	.emptyLikedMe {
		width: 100vw;
		height: 60vh;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		div {
			width: 225px;
			display: flex;
			flex-direction: column;
			align-items: center;
			div {
				color: #aeaeae;
				text-align: center;
			}
		}
	}

	/* width */
	::-webkit-scrollbar {
		width: 0px;
		border-radius: 5rem;
		opacity: 0;
		@media screen and (max-width: 780px) {
			display: none;
			appearance: none;
			-webkit-appearance: none;
		}
	}

	/* Track */
	::-webkit-scrollbar-track {
		width: 0px;
		background: transparent;
		opacity: 0;
	}

	/* Handle */
	::-webkit-scrollbar-thumb {
		width: 0px;
		background: #bec6d600;
		opacity: 0;
	}

	/* Handle on hover */
	::-webkit-scrollbar-thumb:hover {
		width: 0px;
		background: #bec6d600;
		opacity: 0;
	}

	.wrapAllResults {
		width: calc(100% - 250px);
		position: relative;
		margin-inline-start: 100px;
		margin-inline-end: 100px;
		padding-block-end: 100px;
		height: fit-content;
		min-height: 100vh;
		margin-top: 110px;

		@media screen and (max-width: 1650px) {
			margin-inline-start: 100px;
			margin-inline-end: 50px;
			width: calc(100% - 150px);
		}

		@media screen and (max-width: 1300px) {
			margin-inline-start: 60px;
			margin-inline-end: 0px;
			width: calc(100% - 60px);
		}

		@media screen and (max-width: 1000px) {
			margin-inline-start: 50px;
			margin-inline-end: 20px;
			width: calc(100% - 70px);
		}

		@media screen and (max-width: 780px) {
			margin-block-end: 0;
			margin-inline-start: 0;
			margin-inline-end: 0;
			flex-flow: row wrap;
			justify-content: space-evenly;
			align-content: flex-start;
			padding-top: 15px;
			width: 100%;
			margin-top: 0px;

			&:after {
				content: "";
				flex: auto;
				width: 100%;
			}
		}

		&:after,
		&:before {
			//   content: ""; /*suits to three and below masonry*/
			//  flex-basis: 100%;
			//  width: 0;
			//  order: 3;
		}

		.card {
			width: 23%;
			padding: 4%;
			position: relative;
			box-shadow: 0 0.5rem 2rem #1b3f8e2e;
			box-sizing: border-box;
			//  height:615px;
			height: auto;
			border-radius: 20px;
			text-align: center;
			margin-bottom: 20px;
			cursor: pointer;

			&.imgContainer {
				width: 100%;
				margin-inline-end: 0;
			}

			@media screen and (max-width: 1650px) {
				width: 31.5%;
				padding: 1.5%;
				&.imgContainer {
					width: 100%;
					margin-inline-end: 0;
				}
			}

			@media screen and (max-width: 1300px) {
				width: 48%;
				&.imgContainer {
					width: 100%;
					margin-inline-end: 0;
				}
			}

			@media screen and (max-width: 780px) {
				width: 45%;
				padding: 0;
				box-sizing: border-box;
				min-height: 0;
				border-radius: 1rem;
				margin-bottom: 1rem;
				box-shadow: none;
				&.imgContainer {
					width: 100%;
					margin-inline-start: 0;
					margin-inline-end: 0;
				}
			}

			.cardImgBtnTxt {
				position: relative;
				margin-bottom: 0;
				display: flex;
				justify-content: center;
				align-items: flex-end;
				@media screen and (max-width: 780px) {
					margin-bottom: 0;
				}
				.vip1 {
					font-size: 20px;
					@media screen and (max-width: 780px) {
						&:after {
							line-height: 1.3;
							padding: 0;
							width: 93px;
						}
						&:after {
							transform: rotate(-135deg) translate(45%, -56px) scale(-1);
						}
					}
				}
				.statusBarCard {
					position: absolute;
					top: 0;
					width: 100%;
					right: 0rem;
					top: 0.3rem;
					left: 0;
					display: flex;
					align-items: center;
					gap: 0.4rem;
					padding-inline: 0.6rem;
					z-index: 5;
					justify-content: space-between;
					height: 2.2rem;
					.vip {
						top: -26px;
						left: -21px;
					}

					@media screen and (max-width: 780px) {
						top: 0.5rem;
						height: auto;
						.vip {
							top: -29px;
						}
					}

					.distance {
						width: auto;
						position: relative;
						display: flex;
						top: auto;
						right: auto;
						font-size: 16px;
						z-index: 1;
						background-color: #fff0;
						padding: 0;
						color: $white;
						gap: 0.3rem;
						align-items: center;
						//filter: drop-shadow(0px 1px 3px black);
						&:before {
							content: "\eea9";
							font-family: "remixicon";
							color: $white;
							font-size: 16px;
							line-height: 1.1;
							@media screen and (max-width: 780px) {
								font-size: 13px;
							}
						}
						@media screen and (max-width: 780px) {
							font-size: 10px;
							gap: 0.3rem;
							right: auto;
							top: auto;
						}
					}

					.birthDayStick {
						position: absolute;
						left: 10px;
						top: 10px;
						z-index: 1;
					}

					.wrapStatusDis {
						display: flex;
						align-items: center;
						gap: 0.5rem;
						line-height: 0.8;
					}
				}

				.user-status {
					position: relative;
					top: auto;
					right: auto;
					width: 15px;
					height: 15px;
					background-color: #adb5bd;
					border-radius: 50%;
					z-index: 1;
					&.offline {
						display: none;
					}
					&.online {
						background-color: $success;
					}
					@media screen and (max-width: 780px) {
						top: auto;
						right: auto;
						width: 11px;
						height: 11px;
					}
				}

				.time {
					position: relative;
					top: auto;
					left: 0;
					font-size: 16px;
					z-index: 1;
					color: $white;
					grid-gap: 0.3rem;
					gap: 0.3rem;
					display: flex;
					align-items: center;
					span {
						display: none;
					}
					@media screen and (max-width: 780px) {
						font-size: 12px;
					}
					&:before {
						content: "\f14b";
						font-family: "remixicon";
						font-size: 19px;
					}
				}

				.badgeMatch {
					position: absolute;
					z-index: 5;
					top: 2rem;
					right: 0;
					background: $secondary url("../../images/icons/match.svg");
					background-repeat: no-repeat;
					background-size: 22px;
					background-position: 7%;
					padding: 0.2rem 0.6rem;
					color: $white;
					border-radius: 2rem 0 0 2rem;
					box-shadow: 0 2px 5px #40404052;
					padding-inline-start: 2.3rem;
					@media screen and (max-width: 780px) {
						font-size: 9px;
						background-size: 12px;
						padding-inline-start: 1.3rem;
						top: 1.7rem;
						background-position: 93% 5px;
					}
				}

				.checkDelete {
					position: absolute;
					z-index: 50;
					top: 1.8rem;
					left: 1rem;

					@media screen and (max-width: 780px) {
						top: 0.5rem;
						left: 0.5rem;
					}

					input[type="checkbox"] {
						width: 2rem;
						height: 2rem;
						border-radius: 50rem;
						border: 3px solid $secondary;
						opacity: 0.6;
						filter: drop-shadow(0 2px 2px 6px #404040);
						position: relative;
						transition: background-color ease-out 200ms, border ease-out 200ms,
							opacity ease-out 200ms;
						/* Add if not using autoprefixer */
						-webkit-appearance: none;
						appearance: none;
						/* For iOS < 15 to remove gradient background */
						background-color: $white;
						/* Not removed via appearance */
						margin: 0;
						&:after {
							content: "";
							background-image: url("../../images/icons/checkW.svg");
							background-size: 100%;
							position: absolute;
							width: 100%;
							height: 100%;
							top: 0;
							left: 0;
							background-repeat: no-repeat;
							background-position: center;
							transition: transform ease 200ms;
							transform: scale(0);
							transform-origin: center;
						}
						&:checked {
							transition: background-color ease 200ms, border ease 200ms, opacity ease 200ms;
							opacity: 1;
							background-color: $secondary;
							border: 3px solid $white;
							filter: drop-shadow(0 2px 2px 6px #000);
							&:after {
								background-repeat: no-repeat;
								background-position: center;
								transition: transform ease 200ms;
								transform: scale(0.8);
								transform-origin: center;
							}
						}
					}
				}

				.badgeHello {
					position: absolute;
					z-index: 5;
					top: 14rem;
					left: 0;
					background: $white;
					padding: 0.2rem 1rem;
					color: $primary;
					border-radius: 0 2rem 2rem 0;
					box-shadow: 2px 2px 5px #40404052;
					width: 50%;
					overflow: hidden;
					text-overflow: ellipsis;
					@media screen and (max-width: 768px) {
						width: 43%;
						top: 1.5rem;
						padding: 0.2rem 0.6rem;
						font-size: 9px;
						z-index: 2;
						left: 0rem;
						border-radius: 5rem;
						font-weight: 500;
						overflow: visible;
						display: flex;
						height: 19px;
						align-items: center;
						&:after {
							content: "";
							width: 0.5rem;
							height: 0.5rem;
							background: $white;
							bottom: -20%;
							position: absolute;
							left: 44%;
							z-index: 50;
							transform: rotate(45deg);
						}
						span {
							display: inline-block;
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: nowrap;
							width: 100%;
							line-height: 1;
							padding-top: 0;
						}
					}
				}

				.cardImage {
					width: 100%;
					overflow: hidden;
					position: relative;
					padding-top: 120.5%;
					border-radius: 25px / 25px;
					&:before {
						content: "";
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						z-index: 1;
						background: linear-gradient(#00012020 15%, #0000 65%, #000000b0);
					}
					img {
						border-radius: 0px;
						width: 100%;
						height: 100%;
						object-fit: cover;
						object-position: center;
						position: absolute;
						top: 0;
						left: 0;
						right: 0;
						bottom: 0;
						transform: scale(1);
						transition: transform 500ms ease;
						// z-index: -1;
					}

					&:hover img {
						transform: scale(1.05);
						transition: transform 500ms ease;
						@media screen and (max-width: 780px) {
							transform: scale(1);
							transition: none;
						}
					}

					@media screen and (max-width: 780px) {
						border-radius: 13px;
					}
				}

				.wrapShortTxt {
					color: $white;
					bottom: 0;
					position: absolute;
					background: #0000 linear-gradient(180deg, #0000 0%, #00000080 79%, #000000bd 100%) 0% 0%
						no-repeat padding-box;
					padding-top: 50px;
					padding-bottom: 30px;
					border-radius: 15px/25px;
					width: 100%;
					pointer-events: none;
					z-index: 5;
					@media screen and (max-width: 780px) {
						padding-bottom: 10px;
						text-align: start;
						padding-inline-start: 0.5rem;
						border-radius: 13px;
					}
					h4 {
						color: $white;
						font-weight: 600;
						margin-bottom: 0;
						font-size: 1.08rem;
						@media screen and (max-width: 780px) {
							font-size: 1.1rem;
							display: inline-flex;
							gap: 0.5rem;
							max-width: 70%;
							font-weight: 500;
							span:first-child {
								display: inline;
								overflow: hidden;
								text-overflow: ellipsis;
								white-space: nowrap;
							}
						}
					}
					.wrapRow {
						display: flex;
						flex-direction: row;
						justify-content: center;
						gap: 0.5rem;
						flex-wrap: wrap;
						line-height: 0.6;
						padding-inline: 1rem;
						margin-top: 0.4rem;
						padding-bottom: 0.5rem;
						align-items: center;
						@media screen and (max-width: 780px) {
							justify-content: flex-start;
							padding-inline-start: 0;
							max-width: calc(100% - 40px);
							gap: 0.1rem;
							line-height: 1;
							margin-top: 0;
							text-overflow: ellipsis;
							overflow: hidden;
							white-space: nowrap;
						}

						p {
							color: $white;
							font-weight: 400;
							position: relative;
							margin-bottom: 0;
							font-size: 12px;
							@media screen and (max-width: 780px) {
								font-size: 14px;
								font-weight: 300;
								text-overflow: ellipsis;
								overflow: hidden;
								white-space: nowrap;
								display: inline;
							}
							&.city {
								text-overflow: ellipsis;
								overflow: hidden;
								white-space: nowrap;
								max-width: 110px;
								line-height: 1;
							}
							@media screen and (max-width: 780px) {
								&:first-child {
									line-height: 0.9;
								}
							}
						}
						p.bullet {
							&:after {
								content: "";
								width: 0.2rem;
								height: 0.2rem;
								background: $require;
								border-radius: 2rem;
								position: absolute;
								left: 0;
								top: 0;
								bottom: 0;
								margin: auto;
								transform: translateX(-50%);
								@media screen and (max-width: 780px) {
									content: ",";
									width: 0rem;
									height: 0rem;
									background: transparent;
									position: relative;
								}
							}
						}
					}

					.skeletonTxt {
						opacity: 0.8;

						.react-loading-skeleton:after {
							animation-iteration-count: 3;
						}
					}
				}

				.btn-group {
					position: absolute;
					transform: translateY(50%);
					z-index: 50;
					width: 92%;
					justify-content: space-between;
					@media screen and (max-width: 780px) {
						flex-direction: column;
						right: 0.5rem;
						align-items: flex-end;
						transform: translateY(-33%);
						width: auto;
					}
					.Pesah {
						img {
							width: 20px !important;
							height: 20px !important;
						}
					}
					.Shavuot {
						img {
							width: 20px !important;
							height: 24px !important;
							transform: rotate(10deg);
						}
					}
					.Hanuca {
						img {
							transform: translateY(1px);
						}
					}
					button {
						background: $white;
						// transition: all 0.4s ease-in;
						// transition: none;
						border-radius: 50rem;
						height: 38px;
						width: 38px;
						border: 0;
						display: flex;
						flex: none;
						justify-content: center;
						align-items: center;
						box-shadow: 0px 3px 1px -2px #00000033, 0px 2px 10px 0px #00000024,
							0px 1px 10px 0px #0000001f;
						img {
							width: 20px;
							height: 20px;
						}
						&:hover {
							// transition: all 0.4s ease-in;
							// transition: none;
						}

						&.btnText {
							transition: background 200ms linear;
							&.send-hello,
							&.hello-sent,
							&.chatButton {
								> div img {
									filter: invert(1);
								}
								p.wrapBtnTxt {
									font-size: 1rem;

									@media screen and (max-width: 1450px) {
										font-size: 0.55rem;
									}
									@media screen and (max-width: 1300px) {
										font-size: 1rem;
									}
									@media screen and (max-width: 1200px) {
										font-size: 1rem;
									}
									@media screen and (max-width: 1100px) {
										font-size: 1rem;
									}
									@media screen and (max-width: 1000px) {
										font-size: 1rem;
									}
									@media screen and (max-width: 780px) {
										font-size: 1rem;
									}
								}

								&:hover > div img {
									filter: invert(61%) sepia(72%) saturate(2074%) hue-rotate(137deg) brightness(95%)
										contrast(91%);
								}
							}

							&.send-hello,
							&.hello-sent {
								background: $white;
								color: $success;

								img {
									filter: invert(50%) sepia(96%) saturate(1114%) hue-rotate(80deg) brightness(106%)
										contrast(91%);
								}

								&:hover {
									transition: background 200ms linear;
									background: $success;
									color: $white;
									img {
										filter: invert(100%) sepia(100%) saturate(1%) hue-rotate(205deg)
											brightness(102%) contrast(101%);
									}
								}
							}

							// &.chatButton {
							// 	p.wrapBtnTxt {
							// 		color: $white;
							// 	}
							// 	&:hover {
							// 		img {
							// 			filter: invert(62%) sepia(82%) saturate(2131%) hue-rotate(136deg)
							// 				brightness(94%) contrast(91%);
							// 		}
							// 	}
							// }

							background: $secondary;
							color: $white;
							width: 100%;
							grid-gap: 0.5rem;
							gap: 0.5rem;
							font-size: 0.8rem;
							padding: 0 0.8rem;
							font-weight: 500;
							line-height: 0.9;
							&.chatButton.redirect {
								background: $gradientBtn;
								transition: opacity 200ms linear;
								position: relative;
								z-index: 1;
								@media screen and (max-width: 780px) {
									background: $success;
									span {
										display: none;
									}
								}
								> div {
									display: none;
									@media screen and (max-width: 780px) {
										display: block;
									}
								}
								&:before {
									content: "";
									position: absolute;
									top: 0;
									left: 0;
									right: 0;
									bottom: 0;
									background: $white;
									color: $success;
									opacity: 0;
									z-index: -1;
									transition: opacity 200ms linear;
									border-radius: 5rem;
									font-weight: 500;
									line-height: 0.9;
									> div img {
										filter: invert(77%) sepia(54%) saturate(3873%) hue-rotate(48deg) brightness(99%)
											contrast(79%);
									}
								}
								&:hover::before {
									opacity: 1;
								}
							}
							@media screen and (max-width: 780px) {
								border-radius: 50rem;
								width: 40px;
								height: 40px;
								gap: 0;
								padding: 0;
							}
							p.wrapBtnTxt {
								color: $success;
								font-size: 1rem;
								padding: 0;
								font-weight: 500;
								margin-bottom: 0;
								@media screen and (max-width: 780px) {
									display: none;
								}
							}
							img {
								transform: scaleX(1);
								// filter: invert(50%) sepia(96%) saturate(1114%) hue-rotate(80deg) brightness(106%) contrast(91%);
							}
							&:hover {
								// transition: all 0.4s ease-in;
								// transition: none;
								color: $secondary;
								background-color: $white;
								img {
									//filter: invert(100%) sepia(100%) saturate(1%) hue-rotate(205deg) brightness(102%) contrast(101%);
									filter: invert(53%) sepia(86%) saturate(463%) hue-rotate(133deg) brightness(98%)
										contrast(92%);
								}
								p.wrapBtnTxt {
									color: $success;
								}
							}

							&.hiSent {
								// transition: all 0.4s ease-in;
								// transition: none;
								color: $white;
								background-color: $success;
								img {
									filter: invert(0%) sepia(100%) saturate(1%) hue-rotate(205deg) brightness(102%)
										contrast(101%);
								}
							}
							&.sendBtn {
								width: auto;
								gap: 0.5rem;
								font-size: 1rem;
								padding: 0 1.5rem;
								font-weight: 500;
								// transition: all 0.4s ease-in;
								// transition: none;
								color: $white;
								background-color: $success;
								img {
									transform: scaleX(1);
									filter: invert(99%) sepia(73%) saturate(641%) hue-rotate(209deg) brightness(120%)
										contrast(101%);
								}
							}
							&:hover {
								img {
									//	filter: invert(99%) sepia(73%) saturate(641%) hue-rotate(209deg) brightness(120%) contrast(101%);
								}
							}
						}

						&.like {
							img {
								filter: invert(50%) sepia(70%) saturate(4981%) hue-rotate(328deg) brightness(114%)
									contrast(93%);
							}
							&.liked {
								background-color: $specialBtn;
								img {
									filter: invert(100%) sepia(100%) saturate(1%) hue-rotate(205deg) brightness(102%)
										contrast(101%);
								}
								// &:hover {
								// 	img {
								// 		filter: invert(50%) sepia(70%) saturate(4981%) hue-rotate(328deg)
								// 			brightness(114%) contrast(93%);
								// 	}
								// 	background: $white;
								// }
							}

							@media screen and (min-width: 991.98px) {
								&:hover {
									background-color: $specialBtn;
									img {
										filter: invert(100%) sepia(100%) saturate(1%) hue-rotate(205deg)
											brightness(102%) contrast(101%);
									}
								}
							}
						}
						&.dislike {
							img {
								width: 25px;
								height: 24px;
								filter: $fprimary;
							}
							&.skip {
								// transition: all 0.4s ease-in;
								// transition: none;
								background-color: $grey;
								img {
									// transition: all 0.4s ease-in;
									// transition: none;
									filter: none;
								}
							}
							&:hover {
								background: $primary;
								img {
									filter: invert(1);
								}
							}
						}

						@media screen and (max-width: 780px) {
							width: 40px;
							height: 40px;
						}
					}

					.helloAction {
						width: 52%;
					}
				}
			}
			.card-body {
				padding: 0;
				display: flex;
				flex-direction: column;
				justify-content: space-evenly;
				padding-bottom: 0;
				transition: padding ease-out 300ms;
				gap: 15px;
				margin-bottom: 0px;
				position: relative;
				align-items: center;
				margin-top: 0rem;
				padding-top: 40px;

				@media screen and (max-width: 780px) {
					display: none;
				}

				button.littleToggleIconBtn {
					z-index: 50;
					color: #0000;
					width: 100%;
					height: 100%;
					top: 0;
					border: 0;
					&:hover,
					&:focus,
					&:active {
						background-color: transparent;
						border: 0;
						box-shadow: none;
					}
				}

				.userDetailsCollapse {
					margin-top: 0;
					margin-bottom: 0;
					padding-bottom: 0.3rem;
					display: none;
				}

				.tags {
					max-height: 64px;
					overflow: hidden;
					position: relative;
					margin-bottom: 0rem;
					padding-right: 20px;
					padding-left: 20px;
					width: 100%;
					border-left: 0;
					border-right: 0;
					&.ellipsis:after {
						content: "";
						position: absolute;
						right: 0;
						bottom: 0px;
						font-size: 3rem;
						color: $secondary;
						line-height: 0.8;
						background: linear-gradient(270deg, $white 0%, $white 20%, #f1f6f700 100%);
						height: auto;
						padding-inline: 0;
						width: 50%;
						height: 33px;
						pointer-events: none;
					}
					.tagsInline {
						display: flex;
						flex-wrap: wrap;
						justify-content: center;
						gap: 0.4rem;
						padding-top: 0px;
						&:empty {
							padding: 0;
							//border-bottom: 1px solid #efefef;
						}
						button {
							border-radius: 5rem;
							padding: 0.2rem 0.5rem;
							background: $white;
							color: $primary;
							border-color: $primary;
							font-size: 12px;
							&.blur {
								background: #dddfe1;
								color: #dddfe1;
								border-color: #dddfe1;
							}
						}
					}
				}

				h4 {
					font-weight: 600;
					margin-bottom: 0;
					line-height: 1;
					/*Add for tags*/
					max-height: 65px;
					overflow: hidden;
					display: flex;
					flex-wrap: wrap;
					justify-content: center;
					gap: 0.5rem;
					button {
						border-radius: 5rem;
						padding: 0.1rem 0.5rem;
					}
				}

				.card-text {
					width: 100%;
					max-height: 70px;
					overflow: hidden;
					box-sizing: border-box;
					text-align: start;
					line-height: 1.2;
					padding-top: 0px;
					padding-left: 20px;
					padding-right: 20px;
					text-overflow: ellipsis;
					margin-bottom: 0;
					.form-label {
						margin-bottom: 0;
						display: inline-block;
						width: 100%;
						text-align: start;
						font-weight: 600;
					}
					span.plainTxtCard {
						color: $textGrey;
					}
				}

				hr {
					opacity: 0.1;
					margin: 0;
				}

				.lifeSentence {
					font-family: "gveret_levin_alefalefalefRg";
					font-size: 20px;
					line-height: 1;
					margin-bottom: 1rem;
					color: $primary;
				}
			}

			.searchBtn {
				background-color: $white;
				border-color: transparent;
				border: 0 solid;
				font-weight: 900;
				padding-top: 4px;
				padding-left: 1.5rem;
				border-radius: 5rem;
				padding-right: 1.5rem;
				padding-bottom: 5px;
				transition: background-color 0.4s ease-in;
				bottom: 0.8rem;
				width: auto;
				margin: auto;
				margin-bottom: 0;
				position: absolute;
				opacity: 0;
				transform: scale(0);
				transition: opacity ease-out 300ms, transform ease-out 300ms;
				transform-origin: center;
				height: 2.5rem;
				color: $secondary;

				&:hover {
					// transition: background-color 0.4s ease-in;
					// background-color: $secondary;
					color: $secondary;
				}

				.mdi-chevron-down {
					font-family: "Material Design Icons";
					font-size: 1.6rem;
					position: absolute;
					height: auto;
					top: 1px;
					transform: translateX(0);
					font-weight: bold;
				}
			}

			&:hover .card-body {
				padding-bottom: 4rem;
				transition: padding ease-out 300ms;
			}

			&:hover .searchBtn {
				opacity: 1;
				transform: scale(1);
				transition: opacity ease-out 300ms, transform ease-out 300ms, top ease-out 300ms;
				transform-origin: center;
			}

			&.blur {
				.cardImgBtnTxt {
					margin-bottom: 0;
				}

				.card-body {
					margin-bottom: 0;
					gap: 0;
					margin-top: 1.5rem;
					margin-bottom: 0rem;
					br {
						display: none;
					}

					.card-text {
						padding-top: 0;
						opacity: 0.5;
						max-height: 100%;
						> span {
							display: flex;
							flex-direction: column;
							gap: 0;
							> span {
								margin-bottom: 0.5rem;
								&.react-loading-skeleton:after {
									animation-iteration-count: 3;
								}
							}
						}
						&.tagsWrap span {
							display: flex;
							flex-direction: row;
							gap: 0.5rem;
							&:nth-child(even) {
								width: 50% !important;
							}
							&.react-loading-skeleton:after {
								animation-iteration-count: 3;
							}
						}
					}

					.joinBtn {
					}
				}
			}
		}

		.imgContainer.card.newCard {
			.cardImgBtnTxt {
				.statusBarCard {
					align-items: flex-start;
					top: 0.8rem;
					padding: 0 1rem;
					@media screen and (max-width: 780px) {
						align-items: flex-start;
						top: 0.8rem;
						padding: 0 0.3rem;
					}
					.time {
						font-weight: bold;
						display: flex;
						flex-direction: column;
						line-height: 0.8;
						align-items: baseline;
						span {
							display: block;
							@media screen and (max-width: 780px) {
								font-size: 10px;
							}
						}
						&:before {
							align-self: start;
							position: absolute;
							margin-inline-start: -1.5rem;
							@media screen and (max-width: 780px) {
								font-size: 15px;
								margin-inline-start: -1.2rem;
							}
						}
					}
				}
			}
		}
	}
}

.viewedMePage,
.likeMePage {
	.wrapAllResults .card {
		&.newCard {
			.cardImgBtnTxt {
				.vip1 {
					top: 47px;
				}
			}
		}
		@media screen and (max-width: 780px) {
			.cardImgBtnTxt {
				.statusBarCard {
					top: 0.2rem;
				}
				.vip1 {
					top: 23px;
				}
			}
			&.newCard {
				.cardImgBtnTxt {
					.statusBarCard {
					}
					.vip1 {
						top: 40px;
					}
				}
			}
		}
	}
}

.modal-dialog.bigSlideDialog.cardUserDialog {
	top: 0;
	bottom: 0;
	margin: auto;
	position: absolute;
	left: 0;
	right: 0;
	border-radius: 20px;
	width: 787px; //950
	max-width: none;
	min-height: 0;

	.modal-content {
		border-radius: 15px;
		overflow: hidden;
		.closeButton {
			top: 0;
			left: auto;
			right: 0;
			z-index: 50;
			padding-top: 0.7rem;
		}
		.modal-header {
			background: $jsmartGradientTriple;
			border-top-left-radius: 15px;
			border-top-right-radius: 15px;
			padding-bottom: 1rem;
			padding-inline-start: 2rem;
			padding: 0.55rem !important;

			.modal-title {
				color: $white;
				font-size: 25px;
				font-weight: 600;
				position: relative;
				flex-direction: row;
				align-items: baseline;
				.shareButton {
					padding: 0;
					width: 1.3rem;
					height: 1.3rem;
					position: relative;
					margin-inline-end: 0.9rem;
					align-self: center;

					img {
						object-fit: contain;
						width: 100%;
						height: 100%;
						position: absolute;
						top: 0;
						left: 0;
					}
				}
				.userPopupHeaderInfo {
					display: flex;
					flex-direction: row;
					align-items: center;
					gap: 0.5rem;

					span {
						color: $white;
						font-size: 22px;
						font-weight: 600;
						display: inline-block;
						text-indent: 0.2rem;
						margin-bottom: 0 !important;
					}

					.detailsProfile {
						margin-bottom: 0 !important;
						display: flex;
						align-items: center;
						gap: 0.3rem;

						.user-status {
							margin: 1px 10px 0 10px;
							width: 13px;
							height: 13px;
							background-color: $gray-500;
							border-radius: 50%;
							border: 0px solid $white;
							margin-inline-end: 0.5rem;
							&.offline {
								display: none;
							}
							&.online {
								background-color: $success;
							}
						}
						p {
							color: $white;
							font-size: 18px !important;
							font-weight: 400;
							display: inline-block;
							margin-bottom: 0 !important;
						}

						.shareButtonProfile {
							order: 1;
							background-color: transparent !important;
							background-image: url("../../images/icons/shareW.svg") !important;
							background-repeat: no-repeat !important;
							background-size: 70% !important;
							background-position: center !important;
							margin-left: 0.3rem;
							img {
								display: none;
							}
						}

						span {
							order: 2;
						}
					}
				}
			}
		}

		.modal-body {
			display: flex;
			flex-direction: row;
			padding: 15px 0;
			padding-bottom: 0;
			overflow-y: hidden;
			> div {
				width: 100%;
				position: relative;
			}

			.userDesc {
				height: 100%;
				padding: 0;
				overflow-y: auto;
				border-left: 1px solid #f2f2f2;
				width: 55%;

				.innerUserDesc {
					height: calc(100% - 100px);
					overflow-y: scroll;
					width: 93%;
					padding: 0 30px;

					h4 {
						font-weight: 800;
					}

					.oneLiner {
						font-family: "gveret_levin_alefalefalefRg";
						font-weight: normal;
						font-style: normal;
						text-align: center;
					}

					.wrapSumBtn {
						justify-content: flex-start;
						margin-top: 0;
						margin-bottom: 0;
						padding-bottom: 3px;
					}

					.userDetailsCollapse {
						button.littleToggleIconBtn {
							left: -30px;
							bottom: 0px;
						}

						.collapseMe.tags {
							width: 100%;
							padding: 0;
							border-right: 0;
							border-left: 0;
						}

						.collapseMe.tags.contentBig {
							width: 100%;
							padding: 0;
							border-right: 0;
						}

						&.tagsofProfile {
							.collapseMe.tags.contentBig {
								&.ellipsis:after {
									// content: "";
									// height: 35px;
								}
							}
						}
					}
				}
				.listOfDesc.reportUsers {
					display: flex;
					flex-direction: row;
					justify-content: space-evenly;
					position: absolute;
					bottom: 1.5rem;
					right: 0;
					width: 100%;
					p {
						color: $textGrey;
						font-weight: 200;
					}
				}
			}
			.galleryChat {
				display: flex;
				flex-direction: column;
				align-items: center;
				width: 45%;

				.switchDesc {
					.wrapSwitch {
						display: flex;
						flex-direction: row;
						align-items: center;
						grid-gap: 0.5rem;
						gap: 0.5rem;
						margin-bottom: 8px;
						margin-top: 12px;

						input {
							margin-bottom: 12px;
							&:checked {
								background-color: $secondary;
								border-color: $secondary;
							}
						}
					}
					h6 {
						max-width: 100%;
					}
				}

				.wrapImageGallery {
					position: relative;
					height: 380px;
					margin-top: 10px;

					.vip {
						top: -21.5px;
						left: -20.5px;
					}
					.distance {
						width: fit-content;
						position: absolute;
						display: flex;
						flex-direction: row;
						left: 10px;
						top: 13px;
						font-size: 14px;
						z-index: 3;
						background-color: #fff0;
						padding: 4px 7px;
						color: $white;
						gap: 0.4rem;
						&:before {
							content: "\eea9";
							font-family: "remixicon";
							color: $white;
						}
					}
					.image-gallery {
						height: 380px;

						div {
							height: 100%;

							img {
								height: 100%;
								aspect-ratio: auto 1 / 1.205;
								object-fit: cover;
							}
						}
						.image-gallery-content {
							display: flex;
							grid-gap: 10px;
							gap: 10px;
							align-items: flex-start;
							justify-content: center;
							&.fullscreen {
								.image-gallery-slide {
									img {
										width: auto;
										object-fit: contain;
									}
								}
							}

							.image-gallery-slide-wrapper {
								width: 100%;

								.image-gallery-left-nav .image-gallery-svg,
								.image-gallery-right-nav .image-gallery-svg {
									height: 60px;
									width: 20px;
								}
								.image-gallery-fullscreen-button {
									bottom: 0;
									top: 0;
									left: 0;
									right: 0;
									width: 100%;
									height: 100%;
									padding: 0;
									z-index: 3;
									svg {
										display: none;
									}
								}
								.image-gallery-slides {
									border-radius: 20px;
									aspect-ratio: auto 1 / 1.205;
									width: 100%;
									&:before {
										content: "";
										position: absolute;
										top: 0;
										left: 0;
										width: 100%;
										height: 100%;
										z-index: 1;
										background: linear-gradient(#00012020 15%, #0000 65%, #000000b0);
									}
									.image-gallery-slide {
										aspect-ratio: auto 1 / 1.205;
									}
								}
							}
							/* width */
							::-webkit-scrollbar {
								width: 1px;
								border-radius: 5rem;
							}

							/* Track */
							::-webkit-scrollbar-track {
								width: 1px;
								background: transparent;
							}

							/* Handle */
							::-webkit-scrollbar-thumb {
								width: 1px;
								background: #bec6d61c;
							}

							/* Handle on hover */
							::-webkit-scrollbar-thumb:hover {
								width: 1px;
								background: #bec6d61c;
							}

							.image-gallery-thumbnails-wrapper {
								.image-gallery-thumbnails {
									overflow: visible;
									overflow-x: hidden;
									overflow-y: auto;
									direction: ltr;
									.image-gallery-thumbnails-container {
										display: inline;
										.image-gallery-thumbnail {
											border: 0px solid transparent;
											border-radius: 5px 20px;
											overflow: hidden;
											box-sizing: border-box;
											transition: filter 500ms ease;
											margin-bottom: 13px;
											filter: grayscale(1);
											aspect-ratio: auto 1 / 1.205;
											&.active,
											&:hover,
											&:active {
												border: 0px solid $secondary;
												filter: grayscale(0);
												transition: filter 500ms ease;
											}
											.image-gallery-thumbnail-inner {
												height: 100%;
											}
										}
									}
								}
							}

							.image-gallery-bullets {
								height: 10px !important;
								overflow: hidden;
								bottom: 52px;

								.image-gallery-bullet {
									box-shadow: none;
									background: #ccc;
									border: 0;
									&.active {
										background: $secondary;
										transform: scale(1);
									}
								}
							}
						}
					}

					.btn-group {
						position: absolute;
						right: calc(50% - 67px / 2);
						bottom: -5%;
						gap: 30px;
						align-self: center;
						justify-self: center;
						margin-bottom: 0;

						.btn {
							background: $white;
							// transition: all 0.4s ease-in;
							// transition: none;
							z-index: 3;
							border-radius: 50rem;
							height: 67px;
							width: 67px;
							border: 0;
							display: flex;
							justify-content: center;
							align-items: center;
							box-shadow: 0px 2px 1px -2px #00000033, 0px 5px 10px 0px #00000024;
							&.like,
							&.like.liked:hover {
								img {
									filter: invert(50%) sepia(70%) saturate(4981%) hue-rotate(328deg) brightness(114%)
										contrast(93%);
									width: 70%;
								}
								background: $white;
							}

							&.like.liked,
							&.like:hover {
								// transition: all 0.4s ease-in;
								// transition: none;
								background-color: $specialBtn;
								img {
									color: white;
									filter: invert(93%) sepia(100%) saturate(0%) hue-rotate(248deg) brightness(106%)
										contrast(106%);
								}
							}

							&.dislike:hover {
								// transition: all 0.4s ease-in;
								// transition: none;
								background-color: $grey;
								img {
									filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(61deg) brightness(100%)
										contrast(100%);
								}
							}

							&.dislike.skip {
								// transition: all 0.4s ease-in;
								// transition: none;
								background-color: $grey;
								img {
									filter: none;
								}
							}
						}
					}
				}
				.user-chat {
					padding-top: 0;
					position: relative;
					margin-top: 21px;
					div.chat-conversation {
						height: calc(100vh - 668px);
						overflow-y: auto;
					}
					.chatFooter {
						&.p-lg-4 {
							padding: 0 !important;
						}
						.record {
							display: none;
						}
						input,
						textarea {
							margin: 8px 0;
						}
						.jsmartChatBtn {
							width: 43px;
							height: 43px;
							span {
								font-size: 0.7rem !important;
							}
						}
					}
					.alignCenterHeight {
						padding: 0 0.5rem !important;
					}
					.switchDesc {
						display: flex;
						align-items: center;
						justify-content: center;
						border-bottom: 1px solid #f0eff5 !important;
						margin-bottom: 5px;
						h6 {
							margin-top: 8px;
						}
						.wrapSwitch {
							margin: 0;
						}
					}
					.titleForChat {
						width: 100%;
						display: inline-block;
						text-align: center;
						padding: 0.2rem 0;
						background: $white;
						color: $primary;
						border-bottom: 1px solid #f2f2f2;
						font-weight: 600;
					}
				}

				.wrapBtnSend {
					display: flex;
					flex-direction: column;
					align-items: center;
					gap: 10px;
					padding: 30px;
					height: calc(100vh - 615px);
					width: 100%;
					border-bottom: 1px solid #f2f2f2;
					margin-top: 50px;

					.wrapTextDecoration {
						width: 100%;
						position: relative;
						display: flex;
						flex-direction: column;
						align-items: center;

						span {
							line-height: 1;
							color: $primary;
							font-weight: 400;
							font-size: 15px;
							width: 100%;
							text-align: center;
							display: inline-block;
							margin-bottom: 7px;
						}

						textarea.form-control {
							border-radius: 7px;
							border-color: $primary;
							width: 100%;
							font-size: 16px;
							color: $primary;
							line-height: 1;
							resize: none;
							background-color: #f1f6f7;
							// background-image: url("../../images/birdsOp.svg");
							background-position-y: -17px;
							font-family: "gveret_levin_alefalefalefRg";
							&::placeholder {
								font-size: 0.9rem;
								color: $primary;
								font-family: "gveret_levin_alefalefalefRg";
								font-weight: normal;
								font-style: normal;
							}
						}

						img {
							width: 22px;
							height: 22px;
							cursor: pointer;
							position: absolute;
							bottom: 6px;
							left: 6px;
						}

						button.dislike {
							background: $white;
							border-radius: 50rem;
							height: 38px;
							width: 38px;
							border: 0;
							display: flex;
							flex: none;
							justify-content: center;
							align-items: center;
							position: absolute;
							top: auto;
							right: 0rem;
							bottom: 0;
							transform: scale(0.8);
							img {
								position: absolute;
								z-index: 1000;
								width: 25px;
								height: 25px;
								cursor: pointer;
								filter: $fprimary;
							}
						}
						span {
							font-size: 18px;
							max-width: 100%;
							margin-bottom: 0.5rem;
						}
						textarea {
							// background: $mint url(../../images/birdsOp.svg);
							background-size: 20%;
						}
					}

					> .btn {
						align-self: flex-end;
						border-radius: 20rem;
						padding: 7px 15px;
						background-color: $secondary !important;
						border: 0;
						align-items: center;

						img {
							transform: scaleX(-1);
							margin-inline-start: 6px;
							width: 17px;
						}
					}
				}
			}
		}
	}

	.chat-conversation .conversation-list {
		max-width: 100%;
	}
}

.likeMePage,
.viewedMePage,
.matchesPage {
	width: 100vw;
	padding-top: 100px;
	display: flex;
	align-items: center;
	@media screen and (max-width: 780px) {
		padding-top: calc(140px + env(safe-area-inset-top));
		min-height: 100vh;
	}
	> div {
		width: calc(100vw - 350px);
		@media screen and (max-width: 780px) {
			width: 100vw;
		}

		.wrapAllResults {
			padding-inline: 0;
			justify-content: flex-start;
			align-items: flex-start;
			margin-top: 110px;
			margin-inline-start: 125px;
			margin-inline-end: 125px;
			@media screen and (max-width: 780px) {
				padding-inline: 0;
				justify-content: space-evenly;
				margin-top: 1rem;
				margin: 0;
			}
		}
	}

	.bottomPanel {
		position: fixed;
		bottom: 0%;
		background: #ffffff85;
		width: 100%;
		height: 7rem;
		backdrop-filter: blur(8px);
		display: flex;
		align-items: center;
		justify-content: center;
		transition: transform 200ms ease-out;
		transform: translateY(100%); /*hidden*/
		z-index: 50;
		@media screen and (max-width: 780px) {
			height: 7rem;
		}
		&.slideInto {
			transform: translateY(0%); /*show*/
		}
		button {
			background: $secondary;
			border-radius: 50rem;
			color: $white;
			border: 0;
			padding: 1.2rem 2.5rem 1.2rem 5rem;
			font-size: 17px;
			font-weight: 600;
			position: relative;
			box-shadow: #00000036 0 4px 10px;
			@media screen and (max-width: 780px) {
				padding: 0.6rem 5% 0.6rem 15%;
				font-size: 16px;
				width: 70%;
				line-height: 1.2;
				font-weight: 400;
			}
			&:after {
				content: "";
				width: 1.5rem;
				height: 1.5rem;
				background: url("../../images/icons/trash1.svg");
				background-size: contain;
				position: absolute;
				top: 0;
				bottom: 0;
				margin-top: auto;
				margin-bottom: auto;
				left: 2rem;
				background-repeat: no-repeat;
				background-position: top center;
				@media screen and (max-width: 780px) {
					left: 2rem;
					width: 1.7rem;
					height: 1.7rem;
				}
			}
		}
	}
}

.matchesPage {
	@media screen and (max-width: 780px) {
		padding-top: 135px;
	}
	.wrapAllResults {
		@media screen and (max-width: 780px) {
			margin-top: 1.6rem;
		}
	}
}

/*CSS Masonry*/
.my-masonry-grid {
	display: -webkit-box; /* Not needed if autoprefixing */
	display: -ms-flexbox; /* Not needed if autoprefixing */
	display: flex;
	margin-left: -30px; /* gutter size offset */
	width: auto;
}
.my-masonry-grid_column {
	padding-inline-end: 20px;
	background-clip: padding-box;
	@media screen and (max-width: 780px) {
		padding-inline-start: 1.5%;
		padding-inline-end: 1.5%;
		&:nth-child(odd) {
			padding-inline-start: 3%;
		}
		&:nth-child(even) {
			padding-inline-end: 3%;
		}
	}
}
/*End CSS Masonry*/

/*searchFilter*/
.searchFilterWrapper {
	box-sizing: border-box;
	padding: 1.5rem 0;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-direction: column;
	height: calc(100vh - 81px);
	// overflow-y: auto;
	@media screen and (max-width: 780px) {
		height: 100%;
		overflow-y: auto;
		padding-bottom: 0;
		right: 0;
		padding-right: 0;
		padding-left: 0;
		z-index: 50;
		padding-top: 0;
	}

	&:after {
		@media screen and (max-width: 780px) {
			// content: "";
			position: absolute;
			width: 100%;
			right: 0;
			left: 0;
			height: 10rem;
			bottom: 0;
			background: $mint;
			background: linear-gradient(0deg, $mint 0%, $mint 37%, #f1f6f700 100%);
			z-index: 10;
			pointer-events: none;
		}
	}

	.wrapInnerFilterSearch {
		height: 100%;
		width: 100%;
		overflow-y: auto;
		padding-inline: 1.5rem;
		padding-bottom: 120px;
		overscroll-behavior: none;
		@media screen and (max-width: 780px) {
			padding-bottom: 50px;
			height: calc(100% - 170px);
			padding-right: 1.5rem;
			padding-left: 1.5rem;
		}
	}

	h4 {
		font-weight: 600;
		color: $secondary;
		position: fixed;
	}

	span.tinyTitle {
		color: $grey;
		opacity: 0.7;
		font-size: 13px;
		line-height: 1.5;
		&.hideTinyTitle {
			display: none;
		}
		&.showTinyTitle {
			line-height: 1;
			font-size: 12px;
		}
	}

	.colorLabel {
		color: $primary;
		margin-top: 30px;
		margin-bottom: 0;
		font-weight: bold;
	}

	.moreFilters {
		color: $primary;
		margin-top: 50px;
		margin-bottom: 0;
		font-weight: bold;
		display: flex;
		flex-direction: column;
		justify-content: center;
		width: 90%;
		margin-right: 5%;
		margin-left: 5%;
		@media screen and (max-width: 780px) {
			// margin-bottom: 5rem;
		}
		.wrapTitleOFCollapse {
			display: flex;
			justify-content: center;
			gap: 0.5rem;
			h4 {
				position: relative;
				text-align: center;
				width: auto;
				margin-bottom: 0;
				cursor: pointer;
			}
			.littleToggleIconBtn {
				position: relative;
				bottom: 0;
				border: 0;
			}
		}

		.collapseMe {
			span {
				width: 100%;
				text-align: center;
			}
		}

		.dropdown .nav-link {
			border-radius: 0;
			border-top: 0;
			border-right: 0;
			border-left: 0;
			padding: 0;
			text-align: end;
			padding-bottom: 0.5rem;
		}
	}

	.btn-group {
		padding: 5px;
		background: #eef2fb;
		margin-top: 10px;
		font-weight: 500;
		border-radius: 5rem;

		.btn {
			border: 0;
			padding: 4px 10px;
			border-radius: 5rem !important;
			color: $primary;
			margin-left: 4px;

			&.active {
				border: 0;
				padding: 0px 20px;
				border-radius: 5rem !important;
				box-shadow: 0px 3px 1px -2px #00000033, 0px 2px 10px 0px #00000024,
					0px 1px 10px 0px #0000001f;
				overflow: visible;
				color: $white;
				margin-left: 0;
			}
		}
	}
	.form-range::-webkit-slider-thumb {
		background-color: $primary;
	}

	.form-range {
		height: 60px;
	}

	.section {
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		&.range {
			margin-top: 0rem;
			@media screen and (max-width: 780px) {
				margin-top: 0rem;
			}

			.colorLabel {
				margin-bottom: 40px;
				span.search-all {
					color: $secondary;
				}
				span.edit-location {
					cursor: pointer;
					.mdi-pencil-outline {
						font-size: 15px;
						&::before {
							text-decoration: underline;
						}
					}
				}
			}

			~ .range {
				margin-top: 0rem;
			}
		}

		&.fixed {
			position: absolute;
			top: 0;
			width: 100%;
			background: $white;
			padding: 2.5rem 0;
			z-index: 500;
			border-top-right-radius: 5rem;
			left: 0;
			right: 0;

			&.bottom {
				bottom: 0;
				top: auto;
				padding-top: 0;
				border-top-right-radius: 0;
				padding-bottom: 1rem;
				height: auto;
				&:after {
					content: "";
					background: linear-gradient(0deg, $white 0%, $white 37%, #fff0 100%);
					position: absolute;
					top: 0;
					width: 100%;
					height: 100%;
					z-index: -1;
					pointer-events: none;
					transform: translateY(-100%);
				}
			}
		}
	}

	.buttonsSection {
		width: 70%;
		display: flex;
		flex-wrap: wrap;
		gap: 8px;
		margin-top: 1rem;
		justify-content: center;
		align-items: center;

		.btn {
			width: 48%;
			border-radius: 5rem;
			background-color: $white;
			color: $primary;
			line-height: 0.9;
			padding: 0.5rem;

			&:hover {
				background: $primary;
				color: white;
			}
		}

		.btn.active {
			background-color: $primary;
			color: white;
		}
	}

	.closePanel {
		display: none;
		@media screen and (max-width: 780px) {
			width: 5rem;
			display: block;
			z-index: 600;
			position: relative;
			padding-inline: 1rem;
			align-self: flex-start;
		}
	}

	.wrapFilterSelect {
		margin-top: 30px;
		label {
			color: $primary;
			font-weight: bold;
			font-size: 15px;
		}
		.dropdown .dropdown-menu .dropdown-item {
			direction: ltr;
		}
	}

	.section.fixed.titleWithBtn {
		padding: 1.6rem 0;
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;
		@media screen and (max-width: 780px) {
			padding: 1.7rem 0;
			background: transparent;
		}
		.clearFilter.disabled {
			color: $disabled;
			opacity: 1;
		}
		.clearFilter,
		&.disabled {
			background: transparent;
			color: $primary;
			position: absolute;
			right: 1.5rem;
			width: 3rem;
			padding: 0;
			line-height: 1;
			font-size: 14px;
			@media screen and (max-width: 780px) {
				right: 2rem;
			}
		}
		.clearFilter {
			span.dot {
				position: absolute;
				width: 6px;
				height: 6px;
				z-index: 10;
				top: 5px;
				right: -1px;
				background: $alert;
				border-radius: 1rem;
			}
		}
	}
}

/*image loader*/

.loader,
.loader__figure {
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	-moz-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	-o-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	z-index: -1;
}

.loader {
	overflow: visible;
	padding-top: 2em;
	height: 0;
	width: 2em;
}

.loader__figure {
	height: 0;
	width: 0;
	box-sizing: border-box;
	border: 0 solid $secondary;
	border-radius: 50%;
	-webkit-animation: loader-figure 1.15s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
	-moz-animation: loader-figure 1.15s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
	animation: loader-figure 1.15s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
}

@-webkit-keyframes loader-figure {
	0% {
		height: 0;
		width: 0;
		background-color: $secondary;
	}
	29% {
		background-color: $secondary;
	}
	30% {
		height: 2em;
		width: 2em;
		background-color: transparent;
		border-width: 1em;
		opacity: 1;
	}
	100% {
		height: 2em;
		width: 2em;
		border-width: 0;
		opacity: 0;
		background-color: transparent;
	}
}
@-moz-keyframes loader-figure {
	0% {
		height: 0;
		width: 0;
		background-color: $secondary;
	}
	29% {
		background-color: $secondary;
	}
	30% {
		height: 2em;
		width: 2em;
		background-color: transparent;
		border-width: 1em;
		opacity: 1;
	}
	100% {
		height: 2em;
		width: 2em;
		border-width: 0;
		opacity: 0;
		background-color: transparent;
	}
}
@keyframes loader-figure {
	0% {
		height: 0;
		width: 0;
		background-color: $secondary;
	}
	29% {
		background-color: $secondary;
	}
	30% {
		height: 2em;
		width: 2em;
		background-color: transparent;
		border-width: 1em;
		opacity: 1;
	}
	100% {
		height: 2em;
		width: 2em;
		border-width: 0;
		opacity: 0;
		background-color: transparent;
	}
}

/*image loader*/

/*range Slider old

.sliderContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: calc(100% - 20px);
  height: 50px;

  input.thumb {
    transform: translate(10px);
  }

  input:first-child.thumb {
    transform: translate(-10px);
  }
}

.slider {
  position: relative;
  width: 100%;
}

.slider__track,
.slider__range,
.slider__left-value,
.slider__right-value {
  position: absolute;
}

.slider__track,
.slider__range {
  border-radius: 3px;
  height: 3px;
}

.slider__track {
  background-color: #446ac331;
  width: 100%;
  z-index: 1;
}

.slider__range {
  background-color: $primary;
  z-index: 2;
}

.slider__left-value,
.slider__right-value {
  color: #dee2e6;
  font-size: 12px;
  margin-top: 20px;
}

.slider__left-value {
  left: 6px;
}

.slider__right-value {
  right: -4px;
}*/

/* Removing the default appearance 
.thumb,
.thumb::-webkit-slider-thumb {
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
}

.thumb {
  pointer-events: none;
  position: absolute;
  height: 0;
  width: 100%;
  outline: none;
}

.thumb--zindex-3 {
  z-index: 3;
}

.thumb--zindex-4 {
  z-index: 4;
}

.thumb--zindex-5 {
  z-index: 5;
}*/

/* For Chrome browsers 
.thumb::-webkit-slider-thumb {*/
// background-color: $primary;
/* background-image: url("../../images/icons/pinkDrop.svg"), url("../../images/icons/blueDot.svg"),
    url("../../images/icons/blueDotHover.svg");
  background-size: 100%, 50%, 0%;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  height: 82px;
  width: 29px;
  margin-top: -46px;
  pointer-events: all;
  position: relative;
  background-position: center 30%, center 86%, center 96%;
  padding-bottom: 1rem;
  transition: background-size ease-out 300ms;

  &:hover {
    background-image: url("../../images/icons/pinkDrop.svg"), url("../../images/icons/blueDot.svg"),
      url("../../images/icons/blueDotHover.svg");
    background-size: 100%, 50%, 100%;
    background-position: center 30%, center 86%, center 96%;
    transition: background-size ease-out 300ms;
  }
}

/* For Firefox browsers 
.thumb::-moz-range-thumb {
  background-color: $primary;
  border: none;
  border-radius: 50%;
  box-shadow: 0 0 1px 1px #ced4da;
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin-top: 2px;
  pointer-events: all;
  position: relative;
}

.form-range::-webkit-slider-runnable-track {
  background-color: #446ac331;
  height: 3px;
  border-radius: 3px;
}

.form-range::-webkit-slider-thumb {
  margin-top: -0.4rem;
}*/

/*Range slider old*/

.thumb-val::before {
	content: attr(data-val);
}

.multi-range-slider {
	position: relative;
	width: 100%;
	//max-width: 500px;
}

.slider {
	position: relative;
	z-index: 1;
	height: 3px;
	margin: 0 15px;

	> {
		.track {
			position: absolute;
			z-index: 1;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			border-radius: 5px;
			background-color: $lightGrey;
		}

		.range {
			position: absolute;
			z-index: 2;
			left: 25%;
			right: 25%;
			top: 0;
			bottom: 0;
			border-radius: 5px;
			background-color: $primary;
		}

		.thumb {
			position: absolute;
			z-index: 3;
			width: 12px;
			height: 12px;
			background-color: $primary;
			border-radius: 50%;
			box-shadow: 0 0 0 5px rgb(46 80 134 / 13%);
			box-shadow: 0 0 0 5px rgb(64 64 64 / 10%);
			transition: box-shadow 0.3s ease-in-out;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;

			&.left {
				left: 25%;
				transform: translate(-5px, -4px);
			}

			&.right {
				right: 25%;
				transform: translate(5px, -4px);
			}

			&.hover {
				box-shadow: 0 0 0 5px rgb(64 64 64 / 10%);
			}

			&.active {
				box-shadow: 0 0 0 10px rgb(64 64 64 / 10%);
			}

			.drop {
				color: $white;
				color: #fff;
				border: none;
				cursor: pointer;
				height: 55px;
				width: 26px;
				margin-top: -51px;
				pointer-events: all;
				position: absolute;
				padding-bottom: 1rem;
				transition: background-size ease-out 300ms;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 10px;
				&:before {
					content: "";
					background-image: url("../../images/icons/drop.svg");
					background-size: 100%;
					background-repeat: no-repeat;
					border: none;
					cursor: pointer;
					height: 38px;
					width: 26px;
					pointer-events: all;
					position: absolute;
					background-position: center 30%;
					padding-bottom: 0;
					transition: background-size ease-out 300ms;
					display: flex;
					align-items: center;
					justify-content: center;
					z-index: -1;
					top: 4px;
					filter: $fsecondary;
				}
			}
		}
	}

	span.totalValue {
		color: $primary;
		font-size: 11px;
		position: absolute;
		top: 10px;
		&.max {
			right: -8px;
		}
		&.min {
			left: -2px;
		}
	}
}

input[type="range"] {
	position: absolute;
	pointer-events: none;
	-webkit-appearance: none;
	appearance: none;
	z-index: 2;
	height: 10px;
	width: 100%;
	opacity: 0;
	display: flex;
	align-items: flex-end;

	&::-webkit-slider-thumb {
		pointer-events: all;
		width: 30px;
		height: 30px;
		border-radius: 0;
		border: 0 none;
		background-color: red;
		-webkit-appearance: none;
		appearance: none;
		opacity: 0;
		padding-top: 4rem;
		cursor: pointer;
	}
}

.hello-img {
	width: 40px;
	height: 40px;
	border-radius: 100px;
}

.modal-dialog.simpleDialog.helloModal {
	button {
		background: $white;
		// transition: all 0.4s ease-in;
		// transition: none;
		border-radius: 50rem;
		height: 45px;
		width: 45px;
		border: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		box-shadow: 0px 3px 1px -2px #00000033, 0px 2px 10px 0px #00000024, 0px 1px 10px 0px #0000001f;
		img {
			width: auto;
			height: 20px;
		}
		&:hover {
			// transition: all 0.4s ease-in;
			// transition: none;
		}

		&.btnText {
			color: $secondary;
			width: auto;
			gap: 0.5rem;
			font-size: 1rem;
			padding: 0 1.5rem;
			font-weight: 500;
			img {
				transform: scaleX(-1);
				filter: invert(77%) sepia(42%) saturate(6156%) hue-rotate(76deg) brightness(97%)
					contrast(95%);
			}
			&:hover,
			&.hello-sent {
				// transition: all 0.4s ease-in;
				// transition: none;
				color: $white;
				background-color: $success;
				img {
					filter: invert(100%) sepia(100%) saturate(1%) hue-rotate(205deg) brightness(102%)
						contrast(101%);
				}
			}
		}

		&.like {
			img {
				filter: invert(50%) sepia(70%) saturate(4981%) hue-rotate(328deg) brightness(114%)
					contrast(93%);
			}
			&:hover,
			&.liked {
				background-color: $specialBtn;
				img {
					filter: invert(100%) sepia(100%) saturate(1%) hue-rotate(205deg) brightness(102%)
						contrast(101%);
				}
			}
		}

		&.dislike {
			img {
				filter: invert(44%) sepia(88%) saturate(437%) hue-rotate(184deg) brightness(78%)
					contrast(96%);
			}
			&:hover,
			&.skip {
				background-color: $primary;
				img {
					filter: invert(100%) sepia(100%) saturate(1%) hue-rotate(205deg) brightness(102%)
						contrast(101%);
				}
			}
		}
	}

	.modal-content {
		.modal-title {
			max-width: 70%;
			.thumbImg {
				width: 40px;
				height: 40px;
				border-radius: 100px;
				overflow: hidden;
				margin-right: 0.5rem;
				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}
			span {
				font-size: 16px;
				padding-inline: 0;
				flex: 1;
			}
		}

		.modal-body {
			align-items: center;
			display: flex;
			flex-direction: column;

			.messageTxt {
				background: $lightGrey;
				// background: #1dcf2329;
				padding: 0.7rem;
				margin-bottom: 0.3rem;
				border-radius: 7px;
				max-width: 90%;
			}

			.wrapBtns {
				display: flex;
				flex-direction: row;
				gap: 1rem;
				padding-top: 1rem;
			}
		}

		.modal-footer {
			.littleBtn {
				padding: 0.5rem 2rem !important;
				box-shadow: #446ac336 0 2px 10px;
				font-size: 17px;
				width: auto;
				height: auto;
			}
		}
	}
}

/*popup hello + say hi*/

.helloModal.sayHi,
.helloModal.hiSent {
	.wrapBtnSend {
		display: flex;
		flex-direction: column;
		align-items: center;
		grid-gap: 10px;
		gap: 10px;
		padding: 30px;
		height: auto;
		justify-content: space-evenly;
		width: 100%;
		border-top: 0px solid $lightGrey;
		margin-top: 0;
		position: relative;

		.btn-group {
			gap: 30px;
			align-self: center;
			justify-self: center;
			margin-bottom: 1.6rem;
			.btn {
				background: $white;
				// transition: all 0.4s ease-in;
				// transition: none;
				border-radius: 50rem;
				height: 67px;
				width: 67px;
				border: 0;
				display: flex;
				justify-content: center;
				align-items: center;
				box-shadow: 0px 2px 1px -2px #00000033, 0px 5px 10px 0px #00000024;
				&.like img {
					filter: invert(50%) sepia(70%) saturate(4981%) hue-rotate(328deg) brightness(114%)
						contrast(93%);
					width: 70%;
				}
				&.like:hover {
					// transition: all 0.4s ease-in;
					// transition: none;
					background-color: $secondary;
					img {
						filter: invert(100%) sepia(100%) saturate(1%) hue-rotate(205deg) brightness(102%)
							contrast(101%);
					}
				}

				&.like.liked {
					// transition: all 0.4s ease-in;
					// transition: none;
					background-color: $secondary;
					img {
						filter: invert(100%) sepia(100%) saturate(1%) hue-rotate(205deg) brightness(102%)
							contrast(101%);
					}
				}

				&.dislike img {
					width: 55%;
				}
				&.dislike:hover {
					// transition: all 0.4s ease-in;
					// transition: none;
					background-color: $grey;
					img {
						filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(61deg) brightness(100%)
							contrast(100%);
					}
				}

				&.dislike.skip {
					// transition: all 0.4s ease-in;
					// transition: none;
					background-color: $grey;
					img {
						filter: none;
					}
				}
			}
		}

		.wrapTextDecoration {
			width: 100%;
			position: relative;
			span {
				line-height: 1;
				color: $primary;
				font-weight: 400;
				font-size: 15px;
				width: 100%;
				text-align: center;
				display: inline-block;
				margin-bottom: 7px;
			}

			textarea.form-control {
				//font-family: 'Just Another Hand', cursive;
				border-radius: 7px;
				border-color: $primary;
				width: 100%;
				font-size: 16px;
				color: $primary;
				line-height: 1;
				resize: none;
				background-color: $mint;
				&::placeholder {
					color: $primary;
				}
			}
		}

		> .btn {
			align-self: flex-end;
			border-radius: 20rem;
			padding-inline: 1.5rem 1.3rem;
			padding-block: 0.6rem 0.5rem;
			width: auto;
			height: auto;
			background-color: $success !important;
			border: 0;
			display: inline-flex;
			align-items: center;

			img {
				transform: scaleX(-1);
				margin-inline-start: 10px;
				width: 30px;
			}
		}

		h6 {
			text-align: center;
			color: $textGrey;
			font-size: 14px;
			margin-top: 2px;
		}
	}
}

.helloModal.sayHi .wrapBtnSend .wrapTextDecoration span {
	margin-bottom: 20px;
}

.user-profile-sidebar.user-profile-sidebarOpen {
	.btn-group.like-unlike {
		position: absolute;
		bottom: -17px;
		gap: 22px;
		z-index: 50;

		button {
			background: $white;
			// transition: all 0.4s ease-in;
			// transition: none;
			border-radius: 50rem;
			height: 48px;
			width: 48px;
			border: 0;
			display: flex;
			justify-content: center;
			align-items: center;
			box-shadow: 0px 3px 1px -2px #00000033, 0px 2px 10px 0px #00000024, 0px 1px 10px 0px #0000001f;
			img {
				width: auto;
				height: 22px;
			}
			&:hover {
				// transition: all 0.4s ease-in;
				// transition: none;
			}

			&.btnText {
				color: $secondary;
				width: auto;
				gap: 0.7rem;
				font-size: 1.2rem;
				padding: 0 2.2rem;
				font-weight: 500;

				img {
					transform: scaleX(1);
					filter: invert(50%) sepia(96%) saturate(1114%) hue-rotate(80deg) brightness(106%)
						contrast(91%);
				}
				&:hover {
					// transition: all 0.4s ease-in;
					// transition: none;
					color: $success;
					background-color: $white;
					img {
						filter: invert(50%) sepia(96%) saturate(1114%) hue-rotate(80deg) brightness(106%)
							contrast(91%);
					}
				}
				&.hello-sent {
					// transition: all 0.4s ease-in;
					// transition: none;
					color: $white;
					background-color: $success;
					img {
						filter: invert(100%) sepia(100%) saturate(1%) hue-rotate(205deg) brightness(102%)
							contrast(101%);
					}
				}

				&.hiSent {
					// transition: all 0.4s ease-in;
					// transition: none;
					color: $white;
					background-color: $success;
					img {
						filter: invert(0%) sepia(100%) saturate(1%) hue-rotate(205deg) brightness(102%)
							contrast(101%);
					}
				}
				&.sendBtn {
					width: auto;
					gap: 0.5rem;
					font-size: 1rem;
					padding: 0 1.5rem;
					font-weight: 500;
					// transition: all 0.4s ease-in;
					// transition: none;
					color: $white;
					background-color: $success;
					img {
						transform: scaleX(1);
						filter: invert(99%) sepia(73%) saturate(641%) hue-rotate(209deg) brightness(120%)
							contrast(101%);
					}
				}
				&:hover {
					img {
						filter: invert(50%) sepia(96%) saturate(1114%) hue-rotate(80deg) brightness(106%)
							contrast(91%);
					}
				}

				&.mouseDownBtn {
					color: $white;
					background-color: $success;
					img {
						filter: invert(99%) sepia(73%) saturate(641%) hue-rotate(209deg) brightness(120%)
							contrast(101%);
					}
				}

				p.wrapBtnTxt {
					color: $success;
					font-size: 1rem;
					padding: 0;
					font-weight: 500;
					margin-bottom: 0;
				}
			}

			&.like {
				img {
					filter: invert(50%) sepia(70%) saturate(4981%) hue-rotate(328deg) brightness(114%)
						contrast(93%);
				}
				// &:hover,
				&.liked {
					background-color: $specialBtn;
					img {
						filter: invert(100%) sepia(100%) saturate(1%) hue-rotate(205deg) brightness(102%)
							contrast(101%);
					}
				}
			}

			&.dislike {
				img {
					filter: invert(41%) sepia(0%) saturate(0%) hue-rotate(61deg) brightness(94%) contrast(86%);
					height: 18px;
				}
				// &:hover {
				//  transition: all 0.4s ease-in;
				//  background-color: $grey;
				//   img {
				//    transition: all 0.4s ease-in;
				//     filter: invert(100%) sepia(100%) saturate(1%) hue-rotate(205deg) brightness(102%) contrast(101%);
				//  }
				// }
				&.skip {
					background-color: $grey;
					img {
						// transition: all 0.4s ease-in;
						// transition: none;
						filter: none;
					}
				}
			}
		}
	}
}

.buttonsActions {
	.btn-group {
		position: absolute;
		gap: 1rem;
		transform: translateY(-50%);
		z-index: 50;
		display: flex;
		align-items: center;

		button {
			border-radius: 50rem !important;
			background: $white;
			// transition: all 0.4s ease-in;
			// transition: none;
			border-radius: 50rem;
			height: 57px;
			width: 57px;
			border: 0;
			display: flex;
			justify-content: center;
			align-items: center;
			box-shadow: 0px 3px 1px -2px #00000033, 0px 2px 10px 0px #00000024, 0px 1px 10px 0px #0000001f;
			img {
				width: auto;
				height: 22px;
			}
			&:hover {
				// transition: all 0.4s ease-in;
				// transition: none;
			}

			&.btnText {
				color: white;
				background: $secondary;
				width: auto;
				gap: 0.7rem;
				font-size: 1.2rem;
				padding: 29px 35px;
				font-weight: 500;

				img {
					transform: scaleX(1);
				}
				&:hover {
					// transition: all 0.4s ease-in;
					// transition: none;
					color: $success;
					background-color: $white;
					img {
						filter: invert(73%) sepia(76%) saturate(3267%) hue-rotate(134deg) brightness(88%)
							contrast(103%);
					}
				}
				&.hello-sent {
					// transition: all 0.4s ease-in;
					// transition: none;
					color: $white;
					background-color: $success;
					img {
						filter: invert(100%) sepia(100%) saturate(1%) hue-rotate(205deg) brightness(102%)
							contrast(101%);
					}
				}

				&.hiSent {
					// transition: all 0.4s ease-in;
					// transition: none;
					color: $white;
					background-color: $success;
					img {
						filter: invert(0%) sepia(100%) saturate(1%) hue-rotate(205deg) brightness(102%)
							contrast(101%);
					}
				}
				&.sendBtn {
					width: auto;
					gap: 0.5rem;
					font-size: 1rem;
					padding: 0 1.5rem;
					font-weight: 500;
					// transition: all 0.4s ease-in;
					// transition: none;
					color: $white;
					background-color: $success;
					img {
						transform: scaleX(1);
						filter: invert(99%) sepia(73%) saturate(641%) hue-rotate(209deg) brightness(120%)
							contrast(101%);
					}
				}
				&:hover {
					img {
						filter: invert(50%) sepia(96%) saturate(1114%) hue-rotate(80deg) brightness(106%)
							contrast(91%);
					}
				}

				&.mouseDownBtn {
					color: $white;
					background-color: $success;
					img {
						filter: invert(99%) sepia(73%) saturate(641%) hue-rotate(209deg) brightness(120%)
							contrast(101%);
					}
				}

				p.wrapBtnTxt {
					color: $success;
					font-size: 1rem;
					padding: 0;
					font-weight: 500;
					margin-bottom: 0;
				}
			}

			&.like {
				img {
					width: 31px;
					height: 31px;
					filter: invert(50%) sepia(70%) saturate(4981%) hue-rotate(328deg) brightness(114%)
						contrast(93%);
				}
				&.liked {
					background-color: $specialBtn;
					img {
						color: white;
						filter: invert(93%) sepia(100%) saturate(0%) hue-rotate(248deg) brightness(106%)
							contrast(106%);
					}
				}
			}

			&.dislike {
				img {
					width: 40px;
					height: 38px;
				}
				&.skip {
					// transition: all 0.4s ease-in;
					// transition: none;
					background-color: $grey;
					img {
						// transition: all 0.4s ease-in;
						// transition: none;
						filter: none;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 991.98px) {
	.user-profile-sidebar.user-profile-sidebarOpen {
		height: 100vh;
		max-height: 100%;
		padding-top: env(safe-area-inset-top);

		.user-profile-desc {
			height: 100vh;
		}

		.simplebar-content {
			padding-bottom: 5rem !important;
		}

		.detailsProfile {
			text-align: center;
			padding-inline-end: 0;
		}

		.buttonsActions {
			position: fixed;
			bottom: 0;
			top: auto;
			background: $primary;
			width: 100%;
			height: 70px;
			left: 0;
			z-index: 500;
			display: flex;
			justify-content: center;
			box-shadow: 0px -5px 29px #4040404d;

			.btn-group {
				position: absolute;
				gap: 1.23rem;
				transform: translateY(-50%);
				z-index: 50;
				display: flex;
				align-items: center;

				button {
					background: $white;
					// transition: all 0.4s ease-in;
					// transition: none;
					border-radius: 50rem;
					height: 57px;
					width: 57px;
					border: 0;
					display: flex;
					justify-content: center;
					align-items: center;
					box-shadow: 0px 3px 1px -2px #00000033, 0px 2px 10px 0px #00000024,
						0px 1px 10px 0px #0000001f;
					img {
						width: 23.8px;
						height: 23.8px;
					}
					&:hover {
						// transition: all 0.4s ease-in;
						// transition: none;
					}

					&.btnText {
						color: white;
						background: $success;
						font-size: 20px;
						padding: 0 28.5px;
						width: auto;
						color: white;
						display: flex;
						flex-direction: row;
						align-items: center;
						img {
							filter: invert(1);
						}

						&:hover {
							color: $success;
							background-color: $white;
							img {
								filter: invert(50%) sepia(96%) saturate(1114%) hue-rotate(80deg) brightness(106%)
									contrast(91%);
							}
						}
						&.hello-sent {
							color: $white;
							background-color: $success;
							img {
								filter: invert(100%) sepia(100%) saturate(1%) hue-rotate(205deg) brightness(102%)
									contrast(101%);
							}
						}

						&.hiSent {
							color: $white;
							background-color: $success;
							img {
								filter: invert(0%) sepia(100%) saturate(1%) hue-rotate(205deg) brightness(102%)
									contrast(101%);
							}
						}
						&.sendBtn {
							width: auto;
							gap: 0.5rem;
							font-size: 1rem;
							padding: 0 1.5rem;
							font-weight: 500;

							color: $white;
							background-color: $success;
							img {
								transform: scaleX(1);
								filter: invert(99%) sepia(73%) saturate(641%) hue-rotate(209deg) brightness(120%)
									contrast(101%);
							}
						}
						&:hover {
							img {
								filter: invert(50%) sepia(96%) saturate(1114%) hue-rotate(80deg) brightness(106%)
									contrast(91%);
							}
						}

						&.mouseDownBtn {
							color: $white;
							background-color: $success;
							img {
								filter: invert(99%) sepia(73%) saturate(641%) hue-rotate(209deg) brightness(120%)
									contrast(101%);
							}
						}

						p.wrapBtnTxt {
							color: $success;
							font-size: 1rem;
							padding: 0;
							font-weight: 500;
							margin-bottom: 0;
						}

						&.chatButton.redirect {
							background: $gradientBtn;
							@media screen and (max-width: 780px) {
								font-size: 18px;
								flex-direction: column !important;
								align-items: center;
								justify-content: center;
								gap: 0;
								&:hover {
									background: $gradientBtn;
									color: $white;
								}
								> div > img {
									width: 20px;
									height: 20px;
									filter: invert(1);
									display: none;
									&:hover {
										filter: invert(1);
									}
								}
							}
						}
					}

					&.like {
						img {
							width: 31px;
							height: 31px;
							filter: invert(50%) sepia(70%) saturate(4981%) hue-rotate(328deg) brightness(114%)
								contrast(93%);
						}
						&.liked {
							background-color: $specialBtn;
							img {
								color: white;
								filter: invert(93%) sepia(100%) saturate(0%) hue-rotate(248deg) brightness(106%)
									contrast(106%);
							}
						}
					}

					&.dislike {
						align-items: self-end;
						img {
							width: 38px;
							height: 38px;
							filter: invert(27%) sepia(28%) saturate(1344%) hue-rotate(177deg) brightness(93%)
								contrast(86%);
						}
						&:hover {
							background: $primary;
							img {
								filter: invert(1);
							}
						}
						&.skip {
							background-color: $grey;
							img {
								filter: none;
							}
						}
					}
				}
			}
		}
	}
}

@media screen and (max-width: 780px) {
	.user-profile-sidebar.user-profile-sidebarOpen {
		border-right: 0px solid $lightGrey;
		border-left: 0px solid $lightGrey;
	}
}

.modal-dialog.cropImagesModal {
	min-height: 100vh;
	display: flex;
	align-items: center;
	width: 1200px;
	max-width: 1200px;
	position: relative;
	@media screen and (max-width: 780px) {
		min-height: 85vh;
		padding-top: env(safe-area-inset-top);
	}
	.backBtn {
		display: flex;
		background-color: transparent;
		position: absolute;
		z-index: 50;
		border: 0;
		right: 0;
		font-size: 15px;
		@media screen and (max-width: 780px) {
			font-size: 13px;
		}
		&:hover {
			color: $secondary;
		}
	}
	@media screen and (max-width: 780px) {
		width: 100%;
		max-width: 100%;
	}
	.modal-content {
		height: 80vh;
		overflow: visible;
		@media screen and (max-width: 780px) {
			height: 93vh;
		}
		.modal-body {
			height: 100%;
			padding: 0;
			.App {
				height: 100%;
				overflow: hidden;
				display: flex;
				justify-content: center;
				background: $black;
				border-radius: 0.4rem;
				@media screen and (max-width: 780px) {
					border-radius: 0rem;
				}
				.ReactCrop {
					height: 100%;

					div {
						height: 100%;
						@media screen and (max-width: 780px) {
							height: auto;
						}
						img {
							@media screen and (max-width: 780px) {
								height: auto !important;
							}
						}
					}
				}
			}
		}
		.modal-footer {
			border-top: 0px solid $lightGrey;
			display: flex;
			justify-content: center;
			@media screen and (max-width: 780px) {
				position: fixed;
				bottom: 0;
				width: 100%;
				background: $white;
			}
			.buttonCrop {
				min-width: 15%;
				font-weight: 500;
				@media screen and (max-width: 780px) {
					min-width: 30%;
				}
			}
		}
	}

	.validation {
		position: absolute;
		top: 50%;
		color: $white;
		width: 50%;
		text-align: center;
		font-size: 1.5rem;
		right: 50%;
		left: 50%;
		background: #ffffff4b;
		padding: 2rem;
		transform: translate(50%, -50%);
		@media screen and (max-width: 780px) {
			width: 90%;
			right: 5%;
			left: 5%;
			transform: translate(0%, -50%);
		}
	}
}

button.btn:active:focus,
button.btn.active:active:focus,
button.btn.active:focus,
.btn-light:focus,
.btn-light:active:focus,
.btn-light:active,
.btn-check:checked + .btn-outline-primary:focus,
.btn-check:active + .btn-outline-primary:focus,
.btn-outline-primary:active:focus,
.btn-outline-primary.active:focus,
.btn-outline-primary.dropdown-toggle.show:focus {
	box-shadow: none !important;
}

.loginPagesWrapper {
	width: 100%;
	height: 100vh;
	overflow: hidden;
	padding: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background: $white url("../../images/icons/bgLogin.png");
	background: none;
	background-repeat: no-repeat;
	background-size: auto 86%;
	background-position: 0 bottom;
	position: relative;
	@media screen and (max-width: 780px) {
		background: none;
		height: 93vh;
		padding-top: env(safe-area-inset-top);
	}
	&::before {
		content: "";
		background: url("../../images/icons/logoGrad.svg");
		background: none;
		position: absolute;
		top: 0;
		left: 0;
		width: 14rem;
		height: 6rem;
		background-position: bottom;
		background-repeat: no-repeat;
		background-size: 70%;
		@media screen and (max-width: 780px) {
			background: none;
		}
	}

	.loginPopup {
		width: 550px;
		height: 80vh;
		overflow: hidden;
		padding: 0;
		background: $white;
		box-shadow: 0px 10px 15px #436cc31c;
		position: relative;
		border-radius: 25px;
		border: 1px solid $primary;
		@media screen and (max-width: 780px) {
			width: 100%;
			height: 100%;
			border-radius: 0px;
			border: 0px solid $primary;
		}
		.rsw_2Y,
		.rsw_2f {
			height: 100%;
		}
		.loginHolder {
			width: 100%;
			height: 100%;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-items: center;
			padding: 4rem;
			position: relative;
			overflow: auto;
			textarea {
				resize: none;
				direction: ltr;
				text-align: start;
				font-size: 1.5rem;
			}
			.form-control {
				font-size: 1.5rem;
			}

			.wrapOneImage.empty span {
				font-size: 2rem;
			}
			@media screen and (max-width: 780px) {
				width: 100%;
				height: 100%;
				border: 0px solid $primary;
				border-radius: 0px;
				padding: 2rem;
				@supports (-webkit-touch-callout: none) {
					&.nameHolder,
					&.verifyHolder {
						height: 65%;
					}
				}
				&.nameHolder .innerLoginWrapper > div {
					width: 90%;
				}
			}

			.ri-arrow-right-s-line:before {
				content: "\ea6c";
				font-weight: bold;
				font-size: 2.4rem;
			}

			button.btn.backBtn {
				position: absolute;
				left: 1rem;
				background-color: #0000 !important;
				border: 0;
				color: $primary !important;
				padding: 0;
				font-size: 24px;
				transform: scaleX(-1);
				z-index: 50;

				&.resend {
					position: relative;
					font-weight: normal;
					border-bottom: 1px solid $primary !important;
					width: auto;
					border-radius: 0;
					justify-self: center;
					right: 0;
					align-self: flex-start;
					font-size: 18px;
					transform: scaleX(1);
					left: 0;
					margin: auto;
				}
			}

			button.skipBtn {
				border: 0;
				position: absolute;
				top: 1rem;
				right: 2rem;
				color: $textGrey;
				z-index: 500;
				font-size: 1.5rem;
				&:hover {
					color: $primary;
					background: transparent;
				}
			}

			.imageForPage {
				width: 100%;
				height: 60%;
				position: relative;
				margin-top: 1rem;
				@media screen and (max-width: 780px) {
					height: 44%;
				}
				img {
					width: 100%;
					height: 100%;
					position: absolute;
					top: 0;
					left: 0;
					object-fit: contain;
					object-position: center;
				}

				&.fullWidth {
					width: calc(100% + 6rem);
					margin: 0 -6rem;
					top: 0rem;
					@media screen and (max-width: 780px) {
						width: calc(100% + 4rem);
						margin: 0 -4rem;
					}
					img {
						object-fit: contain;
						object-position: center;
					}
					&.left {
						img {
							object-position: left;
						}
					}
				}
			}

			.innerLoginWrapper {
				width: 100%;
				height: 100%;
				display: grid;
				position: relative;
				align-items: center;
				justify-items: center;
				.input-group {
					direction: ltr;
					width: 100%;

					span {
						border-radius: 7px 0 0 7px;
						border-color: $primary;
						color: $primary;
						font-size: 16px;
						background-color: $white;
					}

					input {
						border-radius: 0 7px 7px 0;
						border-color: $primary;

						&.form-control,
						&::placeholder {
							color: $primary;
							font-size: 1.6rem;
							padding: 1rem 1.2rem;
						}
					}
				}

				.dropdown .nav-link,
				input[type="date" i] {
					padding: 1.5rem 1.2rem;
					font-size: 1.6rem;
				}

				h1 {
					font-weight: 600;
					margin-bottom: 1.5rem;
					text-align: center;
					font-size: 3rem;
				}

				h5 {
					color: $textGrey;
					font-size: 1.8rem;
					text-align: center;

					.phoneVer {
						color: $grey;
						font-size: 1.4rem;
						margin-top: 1rem;
						direction: ltr;
						@media screen and (max-width: 780px) {
							font-size: 1.8rem;
						}
					}
				}

				button {
					border-radius: 50rem;
					width: 60%;
					align-self: center;
					justify-self: flex-end;
					font-weight: 600;
					font-size: 2rem;
				}

				.vi__container--default {
					height: 6rem;
					width: 100%;
					display: flex;
					gap: 1.5rem;
					padding: 0;
					direction: ltr;

					.vi__character--default {
						line-height: 60px;
						color: $primary;
						margin: 0;
						border-radius: 0 1rem;
						font-size: 2.4rem;
						border-color: $primary;
					}

					.vi__character--inactive--default {
						color: $primary;
						background-color: $white;
						border-color: $primary;
						opacity: 0.5;
					}

					.vi__character--selected--default {
						outline: 0px solid $secondary;
						background-color: $mint;
					}
				}

				.form-inline {
					width: 100%;
				}

				@media screen and (max-width: 780px) {
					.wrapTitleOfQuest {
						margin-bottom: 2rem;
					}
				}
			}

			label {
				margin-top: 0;
				line-height: 1.4;
				font-size: 1.3rem;
				color: $textGrey;
				font-weight: 400;
				margin-top: 0.8rem;
			}

			.wrapButtonSocial {
				margin-bottom: 20px;
				align-self: baseline;
				display: flex;
				flex-direction: column;
				width: 100%;
				gap: 1rem;
				justify-content: flex-start;
				align-items: center;
				button {
					width: 100%;
					padding: 0.9rem 1rem;
					box-shadow: 0 3px 10px #4068c042;
					padding-inline-end: 3.5rem;
					background-repeat: no-repeat;
					background-position: calc(100% - 0.9rem) center;
					&.faceBtn {
						background-image: url("../../images/icons/facebook.svg");
					}
					&.googBtn {
						background-image: url("../../images/icons/google.svg");
					}
				}
			}
			.link-to-other-phone {
				cursor: pointer;
				font-size: 17px !important;
				align-self: baseline;
			}

			&.phoneEnter,
			&.verifyHolder,
			&.nameHolder {
				@media screen and (max-width: 780px) {
					.imageForPage {
						display: none;
					}
					.innerLoginWrapper {
						height: auto;
						min-height: 0;
						align-items: flex-start;
						gap: 0;
						.phone-container {
						}
						button {
							margin-top: 3rem;
						}
					}
				}
			}

			&.phoneEnter,
			&.verifyHolder {
				@media screen and (max-width: 780px) {
					height: auto;
					padding: 1.8rem;
					padding-top: 7.5rem;
					background-size: 50%;
					background-repeat: no-repeat;
					background-position: 111% -18%;
					justify-content: flex-start;
					.imageForPage {
						display: none;
					}
					.innerLoginWrapper {
						height: auto;
						h1 {
							font-size: 2.7rem;
						}
					}
				}
			}
		}
	}

	&.no-image .innerLoginWrapper {
		height: auto;
		top: 0;
		display: flex;
		flex-direction: column;
		gap: 2rem;
		@media screen and (max-width: 780px) {
			height: 100vh;
			justify-content: space-evenly;
		}
	}
}

.loginStepsClass.loginPopup {
	.loginHolder .innerLoginWrapper div {
		width: 100%;
		.input-group input {
			border-radius: 7px 7px 7px 7px;
			&::placeholder {
				padding: 0;
			}
		}
	}

	.imageForPage {
		width: 70%;
		height: 40%;
		position: relative;
		img {
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			object-fit: contain;
			object-position: center;
		}
		&.fullWidth {
			width: calc(100% + 6rem);
			margin: 0 -6rem;
			height: 60%;
			margin-top: 3rem;
			img {
				object-fit: contain;
				object-position: left;
			}
		}

		&.bottom {
			img {
				object-position: bottom;
			}
		}
	}

	.loginHolder .buttonGroup {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		gap: 1.5rem;
		align-items: center;
		justify-content: flex-start;
		button {
			width: 70%;
			align-self: center;
			justify-self: center;
			font-weight: 500;
			font-size: 2.1rem;
			padding: 0.7rem;
		}
	}

	.collapseBtnGroup {
		justify-content: flex-start;
		.innerLoginWrapper {
			align-items: flex-start;
			height: auto;
			margin-top: 3rem;
			.buttonGroup {
				height: 18rem;
				overflow: hidden;
				margin-top: 2rem;
				transition: height ease 300ms;
				overflow-y: hidden;
				padding-bottom: 10vh;
				justify-self: flex-start;
				margin-bottom: auto;
				@media only screen and (max-width: 780px) {
				}
			}
		}

		.buttonToOpen {
			border: 0;
			margin-top: 1.5rem;
			position: relative;
			color: $primary;
			height: 3rem;
			z-index: 5;
			cursor: pointer;
			span {
				font-family: "Rubik";
				font-size: 1.5rem;
			}
			&:before {
				position: absolute;
				width: 100%;
				text-align: center;
				top: 2rem;
				font-size: 2rem;
			}
			&.ri-arrow-up-s-line {
				margin-top: 2rem;
				&:before {
					top: -1.9rem;
				}
				&:after {
					content: "";
					position: absolute;
					width: 1200%;
					height: 12rem;
					z-index: -1;
					background: linear-gradient(3deg, $white 0%, $white 37%, #fff0 100%);
					left: -600%;
					bottom: 0;
					pointer-events: none;
				}
			}
		}
	}

	.loginHolder .innerLoginWrapper div.wrapOneImage {
		width: 70%;
		aspect-ratio: auto 1/1.205;
		height: auto;
		@media only screen and (max-width: 780px) {
			width: 80%;
		}
	}
}

.progress.loginSteps {
	display: flex;
	height: 5px;
	overflow: hidden;
	font-size: 0.70313rem;
	background-color: $mint;
	border-radius: 0;
	width: 100%;
	position: absolute;
	.progress-bar {
		background: $primary;
	}
}

.modal {
	.subscriptionDialog {
		width: 100%;
		max-width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		min-height: 100vh;
		@media only screen and (max-width: 428px) {
			min-height: 0;
			height: 100%;
		}
		.modal-content {
			height: 54rem;
			border-radius: 23px;
			align-items: center;
			border: 0px solid $primary;
			overflow: hidden;
			aspect-ratio: auto 1/1;
			width: auto;
			justify-content: space-between;
			padding-top: 3rem;
			@media only screen and (max-width: 1200px) {
				height: 95vh;
			}
			@media only screen and (max-width: 1000px) {
				height: 100vh;
				border-radius: 0;
				border: 0px solid $primary;
			}
			@media only screen and (max-width: 780px) {
				height: 100vh;
				border-radius: 0;
				padding-top: calc(2rem + env(safe-area-inset-top));
				// @media only screen and (min-height: 830px) {
				// 	padding-top: calc(3rem + env(safe-area-inset-top));
				// }
			}
			@media only screen and (max-width: 428px) {
				height: 100%;
				padding-top: calc(0rem + env(safe-area-inset-top));
			}
			.closeButton {
				position: absolute;
				color: $primary;
				font-size: 1.2rem;
				right: 1rem;
				top: 0.7rem;
				@media only screen and (max-width: 780px) {
					right: 0rem;
					top: env(safe-area-inset-top);
				}
			}
			.wrapTopPopupSub {
				height: 70vh;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				gap: 4vh;
				@media only screen and (max-width: 428px) {
					// height: 60vh;
					// margin-top: 3vh;
					height: 80vh;
					margin-top: -2vh;
				}
				@media only screen and (max-width: 428px) and (min-height: 900px) {
					// height: 60vh;
					// margin-top: 5vh;
				}
				@media only screen and (max-width: 420px) and (max-height: 899px) {
					// height: 65vh;
				}
				@media only screen and (max-width: 390px) {
					// height: 60vh;
				}
				@media only screen and (max-width: 390px) and (max-height: 850px) {
					// height: 65vh;
				}
				.modal-header {
					border-bottom: 0;
					padding-bottom: 0;
					h5.modal-title {
						text-align: center;
						font-weight: 500;
						font-size: 40px;
						width: 100%;
						@media only screen and (max-width: 780px) {
						}
						@media only screen and (max-width: 428px) {
							font-size: 2.7rem;
							font-size: 10vw;
							margin-bottom: 0.5rem;
							margin-bottom: 0.5vh;
							// @media only screen and (min-height: 830px) {
							// 	margin-bottom: 1.5rem;
							// }
						}
					}
				}

				.imageForSub {
					width: 30%;
					@media only screen and (max-width: 1000px) {
						width: 50%;
						padding-top: 2rem;
						padding-bottom: 2rem;
					}
					@media only screen and (max-width: 780px) {
						width: 40%;
						padding-top: 1rem;
					}
					@media only screen and (max-width: 428px) {
						width: 46%;
						padding-top: 0rem;
						padding-bottom: 0rem;
					}

					img {
						width: 100%;
					}
				}
			}

			.loopSentences {
				width: 100%;
				display: flex;
				height: 100%;
				@media only screen and (max-width: 780px) {
					margin-bottom: 2vh;
					height: auto;
				}
				@media only screen and (max-width: 428px) {
					margin-bottom: 1vh;
					margin-top: -2vh;
					// @media only screen and (min-height: 830px) {
					// 	margin-bottom: 2rem;
					// 	height: 14rem;
					// }
				}
				@media only screen and (max-width: 375px) {
				}
				.carousel.slide {
					width: 100%;
					display: flex;
					flex-direction: column-reverse;
					height: auto;
					@media only screen and (max-width: 780px) {
						height: auto;
						padding-inline: 2rem;
						justify-content: center;
					}
					.carousel-indicators {
						position: relative;
						bottom: auto;
						margin-top: 0.7vh;
					}
					.carousel-inner {
						width: 100%;
						height: 100%;
						.carousel-item {
							height: 100%;
							margin-top: 0rem;
							z-index: 50;
							img {
								width: 27% !important;
								margin: 0 auto;
								display: none !important;
							}
							.carousel-caption {
								color: $primary;
								margin-top: 0rem;
								right: 0;
								left: 0;
								height: 100%;
								padding-top: 0;
								bottom: 0;
								position: relative;
								padding-bottom: 0;
								&.d-md-block {
									@media only screen and (max-width: 780px) {
										display: block !important;
									}
								}
								h3 {
									font-weight: 500;
									font-size: 1.4rem;
									@media only screen and (max-width: 375px) {
										font-size: 1rem;
									}
									.contentCarousel {
										display: flex;
										flex-direction: column;
										justify-content: flex-start;
										align-items: center;
										h5 {
											font-size: 28px;
											line-height: 1;
											@media only screen and (max-width: 428px) {
												font-size: 1.5rem;
												font-size: 6vw;
												// @media only screen and (min-height: 830px) {
												// 	margin-bottom: 1rem;
												// 	margin-top: 1rem;
												// }
											}
											@media only screen and (max-width: 380px) {
												font-size: 5vw;
											}
										}
										h3 {
											font-weight: bold;
											margin-top: 10px;
											@media only screen and (max-width: 375px) {
												margin-bottom: 0;
											}
										}
										h4 {
											padding-inline-start: 2rem;
											background-image: url(../../images/icons/checkMark.svg);
											background-repeat: no-repeat;
											background-position: 100%;
											line-height: 1;
											font-weight: bold;
											font-size: 1.5rem;
											@media only screen and (max-width: 428px) {
												font-size: 1.3rem;
												font-size: 5vw;
												background-position: 100% -3px;
												background-size: 1rem;
												position: relative;
												padding-inline-start: 8%;
											}
											@media only screen and (max-width: 380px) {
												font-size: 4vw;
											}
											@media only screen and (max-width: 375px) {
												margin-bottom: 0;
											}
										}
									}
								}
							}
						}
					}

					.carousel-indicators {
						button {
							background-color: $disabled;
							width: 6px;
							height: 6px;
							border-radius: 5rem;
							border: 0;
							&.active {
								background-color: $primary;
							}
						}
					}
				}
			}

			.modal-body {
				width: 100%;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				align-items: center;
				flex: none;
				padding: 0;
				margin-top: -1vh;
				@media only screen and (max-width: 1000px) {
				}
				@media only screen and (max-width: 780px) {
					margin-bottom: 0;
					padding: 0;
					margin-top: 1rem;
					flex: none;
				}
				@media only screen and (max-width: 428px) {
					padding: 0;
					margin-top: 0;
				}
				.wrapPlans {
					width: 100%;
					display: flex;
					justify-content: center;
					align-items: center;
					height: auto;
					gap: 2%;
					margin: 0 auto;
					padding-top: 0;
					margin-top: 0;
					padding: 0;
					padding-top: 0;
					padding-bottom: 0;
					@media only screen and (max-width: 1200px) {
						padding: 4%;
						margin-top: 0;
						padding-top: 0;
					}
					@media only screen and (max-width: 1000px) {
						margin-top: 3rem;
					}
					@media only screen and (max-width: 780px) {
						width: 100%;
						margin-top: 0;
						padding: 0;
						margin-bottom: 1rem;
						padding-top: 0rem;
						margin-top: -2rem;
					}
					@media only screen and (max-width: 428px) {
					}
					.onePlan {
						text-align: center;
						box-shadow: 0 6px 9px #00000030;
						border-radius: 10px;
						width: 10rem;
						height: auto;
						aspect-ratio: auto 1/1;
						position: relative;
						display: flex;
						justify-content: center;
						align-items: center;
						flex-direction: column;
						color: $primary;
						line-height: 1.2;
						border: 3px solid transparent;
						transition: all ease 300ms;
						transform-origin: center;
						transform: scale(1);
						cursor: pointer;
						@media only screen and (max-width: 1000px) {
							width: 25%;
						}
						@media only screen and (max-width: 780px) {
							transform: scale(1);
							aspect-ratio: auto 1/1;
							height: auto;
							width: 20%;
						}
						@media only screen and (max-width: 600px) {
							width: 40%;
							aspect-ratio: auto 1.3/1;
						}
						&:hover {
							border: 3px solid $primary;
							transition: all ease 300ms;
							transform: scale(1);
							transform-origin: center;
							height: auto;
							aspect-ratio: auto 1/1;
							justify-content: center;
							padding-bottom: 0rem;
							width: 10rem;
							background: $mint;
							@media only screen and (max-width: 1000px) {
								width: 25%;
							}
							@media only screen and (max-width: 780px) {
								transform: scale(1);
								aspect-ratio: auto 1/1;
								height: auto;
								width: 20%;
							}
							@media only screen and (max-width: 600px) {
								width: 27%;
							}
						}
						&.activePlan {
							border: 3px solid $primary;
							transform: scale(1);
							transform-origin: center;
							transition: all ease 300ms;
							height: auto;
							aspect-ratio: auto 1/1;
							justify-content: center;
							padding-bottom: 0rem;
							font-size: 17px;
							width: 10rem;
							background: $mint;
							@media only screen and (max-width: 1000px) {
								width: 25%;
							}
							@media only screen and (max-width: 780px) {
								transform: scale(1);
								aspect-ratio: auto 1/1;
								height: auto;
								width: 20%;
							}
							@media only screen and (max-width: 600px) {
								width: 40%;
								aspect-ratio: auto 1.3/1;
							}
						}
						.launchPrice {
							display: none;
						}
						&.wrapLaunchPlan {
							.launchPrice {
								position: absolute;
								top: 0;
								transform: translateY(-50%);
								display: flex;
								flex-direction: row-reverse;
								grid-gap: 1%;
								gap: 0.6rem;
								width: 100%;
								justify-content: center;

								span {
									background: $success;
									display: inline-block;
									padding: 3px 11px;
									border-radius: 5rem;
									color: $white;
									@media only screen and (max-width: 780px) {
										font-size: 0.8rem;
										line-height: 1.5;
									}
									@media only screen and (max-width: 428px) {
										font-size: 0.7rem;
									}
									@media only screen and (max-width: 375px) {
										padding: 3px 10px;
									}
								}
								img {
									@media only screen and (max-width: 780px) {
										width: 15%;
									}
								}
							}

							@media only screen and (max-width: 1200px) {
							}
							@media only screen and (max-width: 1000px) {
							}
							@media only screen and (max-width: 780px) {
							}
							@media only screen and (max-width: 600px) {
							}

							.monthPrice {
								font-size: 1.3rem;
							}
							.period {
								font-size: 1rem;
								@media only screen and (max-width: 780px) {
									width: 60%;
								}
							}
						}

						.numOfMon {
							font-size: 3rem;
							font-weight: 600;
							line-height: 1;
							margin-bottom: 0.2vh;
							@media only screen and (max-width: 428px) {
								font-size: 3.5vh;
							}
						}
						.nameOfMon {
							font-size: 1.5rem;
							line-height: 1;
							font-weight: 500;
							margin-bottom: 1vh;
						}
						.monthPrice {
							font-size: 1.3rem;
							display: inline-flex;
							gap: 0.5rem;
							justify-content: center;
							align-items: baseline;
						}
						.period {
							font-size: 1rem;
							@media only screen and (max-width: 780px) {
								width: 60%;
							}
						}

						@media only screen and (max-width: 428px) {
							aspect-ratio: auto 1.3/1;
							padding-top: 0;
							&.wrapLaunchPlan,
							&.activePlan {
								aspect-ratio: auto 1.3/1;
								padding-top: 0;
							}
						}
					}
				}

				.conditions {
					position: relative;
					bottom: auto;
					height: auto;
					text-align: center;
					width: 95%;
					padding-top: 0;
					color: $primary;
					right: 0;
					font-size: 17px;
					line-height: 1.4;
					display: flex;
					align-items: center;
					justify-content: flex-start;
					flex-direction: column;
					font-weight: 500;
					margin-top: 2vh;
					@media screen and (min-width: 780px) {
						margin-bottom: 2vh;
					}
					@media screen and (max-width: 780px) {
						height: 84%;
					}
					@media screen and (max-width: 428px) {
						font-size: 15px;
						font-size: 3.5vw;
						height: auto;
						padding-top: 0rem;
						justify-self: self-start;
						margin-bottom: auto;
						margin-top: 1rem;

						br {
							display: none;
						}
					}
					> span {
						display: inline-block;
						clear: both;
						font-weight: 400;
						&:empty {
							display: none;
						}
						@media screen and (max-width: 780px) {
							line-height: 1.2;
						}
					}
					a {
						color: $secondary;
						text-decoration: underline !important;
					}
					.morePay {
						display: flex;
						justify-content: center;
						align-items: center;
						height: 100%;
						.wrapTitleOFCollapse {
							position: relative;
							display: flex;
							justify-content: center;
							align-items: center;
							h4 {
								font-weight: bold;
								font-size: 1rem;
								margin-top: 0;
							}
							.littleToggleIconBtn {
								position: relative;
								margin-inline-start: 1rem;
								bottom: 6px;
							}
						}
						.morePayContent {
							h6 {
								font-size: 0.8rem;
								@media screen and (max-width: 428px) {
									font-size: 0.7rem;
								}
							}
						}
						@media screen and (max-width: 780px) {
							display: none;
						}
					}
				}

				iframe {
					border: 0;
					min-height: 90vh;
					min-width: 350px;
					position: absolute;
					right: 0;
					width: calc(100% + 11px);
					top: 0;
					padding-top: 2rem;
				}

				button.backBtn.btn {
					position: absolute;
					left: 2rem;
					background-color: #0000 !important;
					border: 0;
					color: $primary !important;
					padding: 0;
					font-size: 24px;
					z-index: 50;
					display: flex;
					align-items: center;
					top: 1rem;
					&:before {
						content: "\ea6c";
						transform: scaleX(-1);
					}
					span {
						font-family: "Rubik";
						font-size: 1rem;
					}
				}
			}
		}

		.bottomPart {
			position: relative;
			bottom: 0;
			width: calc(100% + 2rem);
			right: 0;
			bottom: -1px;
			background: $primary;
			height: 140px;
			border-radius: 0 0 19px 19px;
			display: flex;
			flex-direction: column;
			align-items: center;
			margin: -1rem;
			justify-content: center;
			padding-top: 2rem;
			@media only screen and (max-width: 1000px) {
				width: 100%;
				margin: 0;
				border-radius: 0;
			}
			@media screen and (max-width: 780px) {
				height: 20vh;
				border-radius: 0;
				padding-top: 4rem;
				justify-content: space-evenly;
			}
			@media only screen and (max-width: 428px) {
			}
			@media only screen and (max-width: 375px) {
			}

			button {
				width: 30%;
				font-size: 24px;
				font-weight: 500;
				border-radius: 30rem;
				transform: translateY(-50%);
				filter: drop-shadow(0px 4px 7px $primary);
				padding: 0.5rem;
				position: absolute;
				top: 0;
				opacity: 1;
				@media screen and (max-width: 780px) {
					width: 40%;
				}
				@media only screen and (max-width: 428px) {
					width: 60%;
					font-size: 6.5vw;
				}
				@media only screen and (max-width: 375px) {
					padding: 0.3rem;
				}
			}

			h5 {
				color: $white;
				display: flex;
				width: 80%;
				justify-content: center;
				align-items: center;
				text-align: center;
				font-size: 15px;
				position: relative;
				margin-bottom: 1rem;
				@media only screen and (max-width: 428px) {
					margin-bottom: 1vh;
					font-size: 13px;
					font-size: 3.5vw;
					width: 95%;
				}
			}

			.morePay {
				display: none;
				&.mobileMorePay {
					@media screen and (max-width: 780px) {
						display: flex;
						justify-content: center;
						align-items: center;
						height: auto;
						.wrapTitleOFCollapse {
							position: relative;
							display: flex;
							justify-content: center;
							align-items: center;
							h4 {
								font-weight: 400;
								font-size: 1rem;
								margin-top: 0;
								color: #fff !important;
								margin-bottom: 0;
							}
							.littleToggleIconBtn {
								position: relative;
								margin-inline-start: 1rem;
								bottom: 0;
								width: 1.5rem;
								height: 1.5rem;
								transform: translateY(0%);
								background: transparent;
							}
						}
						.morePayContent {
							h6 {
								font-size: 0.8rem;
								@media screen and (max-width: 428px) {
									font-size: 0.7rem;
									color: #fff;
									padding: 1rem;
								}
							}
						}
					}
				}
			}

			.payTrigSubs {
				display: none;
				@media screen and (max-width: 780px) {
					color: #fff;
					font-weight: bold;
					border-bottom: 1px solid;
					display: inline-block;
					width: auto;
				}
			}
		}
	}
}

//popover of pay conditions only mobile
.payConditionsPopover {
	.popover {
		max-width: 100vw;
		padding-top: 1rem;
		.user-chat-nav {
			position: absolute;
			right: 0;
			top: 0;
		}
		.popover-body {
			width: 80vw !important;
		}
	}
}

/*global datepicker*/

.form-control {
	border-radius: 7px;
	border-color: $primary;
}

input[type="date" i] {
	display: flex;
	flex-direction: row;
}

input[type="date" i].datepickerRtl {
	flex-direction: row-reverse;
}

input[type="date" i]::-webkit-calendar-picker-indicator {
	background-image: url("../../images/icons/calB.svg");
	cursor: pointer;
	margin: 0;
	width: 100%;
	background-position: 100%;
}

input[type="date" i].datepickerRtl::-webkit-calendar-picker-indicator {
	background-image: url("../../images/icons/calB.svg");
	background-position: 100%;
}

input::-webkit-datetime-edit {
	position: absolute;
	z-index: 0;
	pointer-events: none;
	margin-inline-start: 0;
	text-align: left;
}

input.datepickerRtl::-webkit-datetime-edit {
	text-align: left;
}

/*Upload image input*/

.wrapOneImage {
	border-radius: 0 50px;
	padding: 0.3rem;
	overflow: visible;
	width: 220px;
	height: 261.9px;
	position: relative;
	filter: drop-shadow(0x 0px 0px #446ac300);
	transition: ease-out all 300ms;
	background: $white;
	opacity: 0.9;
	cursor: pointer;
	border: 1px solid $lightGrey;

	@media screen and (max-width: 780px) {
		border-radius: 0 30px;
		padding: 0.5vw;
		height: auto;
		justify-content: center;
		width: 25vw;
		height: 30.125vw;
	}

	img {
		filter: brightness(1);
		transition: ease-out filter 400ms;
		object-fit: cover;
		width: 100%;
		height: 100%;
		border-radius: 0 45px;
		@media screen and (max-width: 780px) {
			border-radius: 0 25px;
		}
	}

	&:hover {
		filter: drop-shadow(2px 4px 6px #4068c04d);
		opacity: 1;
		transition: ease-out all 300ms;
		background: $white;
	}

	&.mainImg {
		border: 3px solid $secondary;
		@media screen and (max-width: 780px) {
			border: 2px solid $secondary;
		}
	}

	&.empty {
		border: 1px dashed $primary;
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		line-height: 3;
		span {
			font-size: 1.5rem;
			position: absolute;
			width: 100%;
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			margin-top: 0;
			flex-direction: column;
			color: $primary;
			@media screen and (max-width: 780px) {
				font-size: 1.3rem;
				height: 3.2rem;
				pointer-events: none;
			}

			&:before {
				content: "+";
				font-size: 2rem;
				position: relative;
				width: 3rem;
				height: 3rem;
				border: 1px solid;
				display: flex;
				justify-content: center;
				align-items: center;
				border-radius: 30rem;
				top: 0;
				right: 0;
				left: 0;
				margin: auto;
				margin-bottom: 0;
				margin-top: 0;
				@media screen and (max-width: 780px) {
					font-size: 2rem;
					width: 3rem;
					height: 3rem;
					font-weight: 300;
				}
			}
		}

		input#contained-button-file {
			position: absolute;
			height: 100%;
			width: 100%;
			top: 0;
			left: 0;
			z-index: 100;
			opacity: 0;
			cursor: pointer;
			max-width: 100%;
		}
	}

	&.waiting {
		position: relative;
		&:after {
			content: "";
			position: absolute;
			background-image: url("../../images/icons/waiting.svg");
		}
	}

	&.blocked {
		overflow: visible;
		position: relative;

		h6 {
			color: $white;
			position: absolute;
			width: calc(100% - 25px);
			line-height: 1;
			display: inline-block;
			background: $grey;
			left: 0.3rem;
			top: 0.3rem;
			display: flex;
			justify-content: center;
			align-items: center;
			height: 2rem;
			z-index: 2;

			@media screen and (max-width: 780px) {
				left: calc(1% + 2px);
				top: calc(1% + 2px);
				font-size: 0.5rem;
				text-align: center;
				width: calc(100% - (4px + 2%));
				padding-right: 1rem;
				padding-left: 0.5rem;
				height: 1.3rem;
			}

			&:after {
				content: "";
				width: 50px;
				height: 50px;
				background-image: url("../../images/icons/blocked.svg");
				background-size: 60%;
				background-color: $alert;
				background-position: center;
				background-repeat: no-repeat;
				border-radius: 50rem;
				right: 0;
				z-index: 4;
				top: 0;
				transform: translate(50%, -3px);
				position: absolute;
				@media screen and (max-width: 780px) {
					width: 1.5rem;
					height: 1.5rem;
					transform: translate(50%, 0);
					bottom: 0;
					margin: auto;
				}
			}
		}

		img {
			filter: grayscale(1) opacity(0.5);
		}

		input#contained-button-file {
			position: absolute;
			height: 100%;
			top: 0;
			left: 0;
			z-index: 100;
			opacity: 0;
			cursor: pointer;
		}
	}

	&.waiting {
		overflow: visible;
		position: relative;

		h6 {
			color: $white;
			position: absolute;
			width: calc(100% - 25px);
			line-height: 1;
			display: inline-block;
			background: $grey;
			left: 0.3rem;
			top: 0.3rem;
			display: flex;
			justify-content: center;
			align-items: center;
			height: 2rem;
			z-index: 2;

			@media screen and (max-width: 780px) {
				left: calc(1% + 2px);
				top: calc(1% + 2px);
				font-size: 0.5rem;
				text-align: center;
				width: calc(100% - (4px + 2%));
				padding-right: 1rem;
				padding-left: 0.5rem;
				height: 1.3rem;
			}

			&:after {
				content: "";
				width: 50px;
				height: 50px;
				background-image: url("../../images/icons/waiting.svg");
				background-size: 60%;
				background-color: $require;
				background-position: center;
				background-repeat: no-repeat;
				border-radius: 50rem;
				right: 0;
				z-index: 4;
				top: 0;
				transform: translate(50%, -3px);
				position: absolute;

				@media screen and (max-width: 780px) {
					width: 1.5rem;
					height: 1.5rem;
					transform: translate(50%, 0);
					bottom: 0;
					margin: auto;
				}
			}
		}
	}

	/*  .editButtons {
    position: absolute;
    z-index: 50;
    // top: 0;
    // left: 0;
    width: 100%;
    height: 3rem;
    background: #0000;
    display: flex;
    flex-direction: row;
    gap: 1rem;
    justify-content: center;
    //  transform: translateY(0%);
    opacity: 0;
    transition: ease-out opacity 300ms;*/

	.roundBtn {
		z-index: 50;
		position: absolute;
		width: 3rem;
		height: 3rem;
		background-color: rgba(255, 255, 255, 0.7);
		filter: drop-shadow(2px 3px 5px #00000050);
		border-radius: 5rem;
		left: 1rem;
		bottom: 1rem;
		border: 0;

		@media screen and (max-width: 780px) {
			width: 1.8rem;
			height: 1.8rem;
			left: 0.6rem;
			bottom: 0.6rem;
		}

		&.edit {
			background-image: url("../../images/icons/edit.svg");
			background-size: 50%;
			background-position: center;
			background-repeat: no-repeat;
			transition: all ease-in 200ms;
			&:hover {
				background-color: $white;
				transition: all ease-in 200ms;
			}
		}
	}
	/*}*/

	&.active {
		filter: drop-shadow(2px 4px 6px #4068c04d);
		transition: ease-out all 200ms;
		background: $white;

		.editButtons {
			// transform: translateY(-110%);
			opacity: 1;
			transition: ease-out all 200ms;
		}

		img {
			filter: brightness(0.8);
			transition: ease-in filter 200ms;
		}
	}
}
/*End upload image input*/

body.modal-open {
	overflow-y: hidden;
	height: 100vh;
	position: relative;
}

@media screen and (max-width: 780px) {
	.infinite-scroll-component__outerdiv {
		width: 100vw;
		min-height: 100vh;
		//  position: relative;
		//  top: 1rem;
		//  overflow-y: scroll;
		//  height:auto;

		/* width */
		::-webkit-scrollbar {
			width: 0px;
			border-radius: 5rem;
			opacity: 0;
			@media screen and (max-width: 780px) {
				display: none;
				appearance: none;
				-webkit-appearance: none;
			}
		}

		/* Track */
		::-webkit-scrollbar-track {
			width: 0px;
			background: transparent;
			opacity: 0;
		}

		/* Handle */
		::-webkit-scrollbar-thumb {
			width: 0px;
			background: transparent;
			opacity: 0;
		}

		/* Handle on hover */
		::-webkit-scrollbar-thumb:hover {
			width: 0px;
			background: transparent;
			opacity: 0;
		}
	}
}

input::-webkit-datetime-edit-day-field:focus {
	background-color: transparent;
	color: $primary;
}

label.termsPrivacy {
	.brDown {
		clear: both;
		display: inline-block;
	}

	a {
		color: $primary !important;
		// border-bottom: 1px solid;
		cursor: pointer;
		@media screen and (max-width: 780px) {
			&[href^="tel:"] {
			}
		}
	}
}

.freezeWrapper {
	width: 100%;
	min-height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;

	.freeze {
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		height: 70vh;
		@media screen and (max-width: 780px) {
			height: 90vh;
		}
		.lockIcon {
			background: $mint;
			width: 15rem;
			height: 15rem;
			border-radius: 50rem;
			display: flex;
			justify-content: center;
			align-items: center;
			img {
				width: 49%;
				margin-top: -9px;
			}
			&.blockedIcon {
				img {
					width: 58%;
				}
			}
		}
		.helloUser {
			text-align: center;
			line-height: 2.5;
			h1 {
				font-weight: 500;
				display: inline;
			}
		}

		.wrapButtons {
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
			gap: 2.5rem;
			button.freezeBtn {
				width: 100%;
				border-radius: 50rem;
				border-width: 2px;
				font-size: 20px;
				font-weight: 500;
				padding: 0.7rem;
				position: relative;
				padding-inline-start: 2rem;
				&:before {
					content: "";
					width: 1rem;
					height: 1.2rem;
					position: absolute;
					background: url("../../images/icons/lockOpen.svg");
					background-size: contain;
					background-repeat: no-repeat;
					left: 15%;
					top: 0;
					bottom: 0;
					margin-top: auto;
					margin-bottom: auto;
				}
				&:hover {
					background: $white;
					color: $primary;
				}
			}
			button.logoutBtn {
				width: 100%;
				border-radius: 50rem;
				border-width: 0px;
				font-size: 20px;
				font-weight: 500;
				padding: 0.7rem;
				position: relative;
				padding-inline-start: 2rem;
				&:before {
					content: "";
					width: 1.3rem;
					height: 1.2rem;
					position: absolute;
					background: url("../../images/icons/sign-out.svg");
					background-size: contain;
					background-repeat: no-repeat;
					left: 13%;
					top: 0;
					bottom: 0;
					margin-top: auto;
					margin-bottom: auto;
				}
				&:hover {
					background: $white;
					color: $primary;
				}
			}
		}
	}
}

.sideBarPanel {
	// overflow:hidden;
	.chat-message-list {
		.noMessages {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			background-color: $white;
			width: 71%;
			margin-top: 70px;
			margin-left: 12.5%;
			@media screen and (max-width: 780px) {
				margin-top: 35px;
			}
			.hiText {
				color: $secondary;
				font-size: 35px;
				position: relative;
				bottom: 94px;
			}
			.noMessagesText {
				color: $textGrey;
				font-size: 18px;
				text-align: center;
			}
		}
		.chat-list li {
			display: flex;
			padding-inline-start: 1rem;
			position: relative;
			overflow: hidden;
			.checkDelete {
				align-items: center;
				display: flex;
				input[type="checkbox"] {
					width: 1rem;
					height: 1rem;
					border-radius: 50rem;
					border: 1px solid $grey;
					opacity: 0.6;
					filter: drop-shadow(0 2px 2px 6px $textGrey);
					position: relative;
					transition: background-color ease-out 200ms, border ease-out 200ms, opacity ease-out 200ms;
					appearance: none;
					background-color: $white;
					margin: 0;
					&:after {
						content: "";
						background: url("../../images/icons/checkW.svg");
						background-size: 100%;
						position: absolute;
						width: 100%;
						height: 100%;
						top: 0;
						left: 0;
						background-repeat: no-repeat;
						background-position: center;
						transition: transform ease 200ms;
						transform: scale(0);
						transform-origin: center;
					}
					&:checked {
						transition: background-color ease 200ms, border ease 200ms, opacity ease 200ms;
						opacity: 1;
						background-color: $secondary;
						border: 1px solid $white;
						&:after {
							background-repeat: no-repeat;
							background-position: center;
							transition: transform ease 200ms;
							transform: scale(0.8);
							transform-origin: center;
						}
					}
				}
			}
			.vip {
				top: -20px;
				left: -19px;
				.stack-top {
					top: -21px;
					left: 10px;
					height: 16px;
					font-size: 10px;
				}
			}
			.vip1 {
				transform: scale(1);
				background-image: url("../../images/icons/chatVipBadge.svg");
				background-repeat: no-repeat;
				width: 81px;
				height: 45px;
				top: 11px;
				@media screen and (max-width: 780px) {
					background-image: url("../../images/icons/chatVipMobile.svg");
					width: 71px;
					height: 43px;
				}
			}
			.massletter {
				color: $secondary;
				font-weight: 500;
				position: absolute;
				top: 5rem;
			}
			a {
				width: 100%;
				padding-inline-start: 0.5rem;
			}
		}
	}

	.deletePanel {
		width: 100%;
		height: 10rem;
		position: absolute;
		backdrop-filter: blur(5px);
		background-color: #9a9a9a4d;
		border-top-right-radius: 1.5rem;
		top: 0;
		left: 0;
		right: 0;
		z-index: 50;
		display: flex;
		align-items: center;
		justify-content: center;
		transform: scale(0%);
		transition: transform ease-in 200ms, opacity ease-in 200ms;
		opacity: 0;
		@media screen and (max-width: 780px) {
			border-top-right-radius: 0rem;
		}
		&.slideBottom {
			transform: scale(100%);
			transition: transform ease-out 200ms, opacity ease-out 200ms;
			top: 0;
			left: 0;
			right: 0;
			opacity: 1;
		}
		button.deleteChatUsers {
			position: relative;
			background: $white url("../../images/icons/trashR.svg");
			background-repeat: no-repeat;
			background-position: 12% center;
			background-size: 1rem;
			border: 0;
			filter: drop-shadow(0 3px 3px #9190907e);
			padding: 0.5rem 2rem;
			padding-inline-end: 3.5rem;
			border-radius: 0.7rem;
			font-weight: 500;
			color: $alert;
			&:hover {
				color: $alert;
			}
		}
	}

	.chatPanel .title {
		text-align: start;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		h4 {
			font-weight: 600;
			color: $secondary;
		}
		label.mb-4 {
			color: $primary;
			margin-bottom: 0 !important;
		}
	}
}

.modal-dialog.simpleDialog.sendMessagePopup {
	.modal-content {
		.modal-body {
			display: flex;
			flex-direction: column;
			align-items: center;
			.wrapTextDecoration {
				position: relative;
				margin-bottom: 1rem;
				display: flex;
				flex-direction: column;
				align-items: center;
				width: 90%;
				button.dislike {
					background: $white;
					border-radius: 50rem;
					height: 38px;
					width: 38px;
					border: 0;
					display: flex;
					flex: none;
					justify-content: center;
					align-items: center;
					position: absolute;
					top: auto;
					right: 0rem;
					bottom: 0;
					transform: scale(0.8);
					img {
						position: absolute;
						z-index: 1000;
						width: 25px;
						height: 25px;
						cursor: pointer;
						filter: $fprimary;
					}
				}
				span {
					font-size: 18px;
					max-width: 100%;
					margin-bottom: 0.5rem;
					font-weight: 500;
				}
				textarea {
					background: $mint url(../../images/birdsOp.svg);
					background-size: 20%;
				}
			}
			button {
				border-radius: 5rem;
				padding: 0.3rem 1rem;
				display: flex;
				gap: 0.5rem;
				align-items: center;
				img {
					width: 16px;
					height: 16px;
				}
			}
			@media screen and (max-width: 780px) {
				padding-block-end: 1.5rem;
			}
		}
	}
}

/*always at the end */

.desktopOnly {
	display: block;
	@media screen and (max-width: 780px) {
		display: none;
	}
}

.mobileOnly {
	display: none;
	@media screen and (max-width: 780px) {
		display: block;
	}
}

.modal-backdrop.show {
	opacity: 0.8;
}

.carousel.galleryCarousel .carousel-inner .carousel-item {
	border-radius: 7px;
}

.wrapRequiredPoint {
	position: relative;
	width: 100%;
	.requiredPoint {
		position: absolute;
		width: 8px;
		height: 8px;
		background: $require;
		border-radius: 50%;
		left: -14px;
		top: 7px;
	}
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
	-webkit-appearance: none;
}

#root.fixedBackground {
	overflow-y: hidden;
	height: 100vh;
}

.form-check-input:focus {
	box-shadow: none;
}

.floatingBtnGoUp {
	position: fixed;
	right: 120px;
	left: auto;
	bottom: 20px;
	font-size: 40px;
	z-index: 1;
	filter: drop-shadow(0px 5px 5px rgba(153, 153, 153, 0.5647058824));
	cursor: pointer;
	border-radius: 5rem;
	background: $secondary;
	display: flex;
	width: 3rem;
	height: 3rem;
	align-items: center;
	justify-content: center;
	span {
		color: white;
		font-family: "Material Design Icons";
		display: flex;
		height: 100%;
		align-items: center;
		justify-content: center;

		&:before {
			font-size: 2rem;
		}
	}
	@media screen and (max-width: 780px) {
		right: 10px;
		bottom: 60px;
	}
}

@media screen and (max-width: 780px) {
	.dimmer {
		position: fixed;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		background: $lightGrey;
		opacity: 0;
		mix-blend-mode: multiply;
		transition: 300ms opacity linear;
		pointer-events: none;
		&.showDimmer {
			opacity: 1;
			transition: 300ms opacity linear;
			z-index: 4000;
		}
	}
	.toBlur {
		transition: 300ms filter linear;
		filter: blur(0px);
		&.blurMe {
			transition: 300ms filter linear;
			filter: blur(4px);
		}
	}
	.header {
		transition: 300ms filter linear;
		filter: blur(0px);
		&.blurMe {
			transition: 300ms filter linear;
			filter: blur(4px);
		}
	}
}

/*פופאובר השתדלות*/

.endeavorWrapper {
	.popover {
		border: 0;
		max-width: 470px;
		box-shadow: 0 3px 6px #00000026;
		border-radius: 1.2rem;
		z-index: 1200;
		.popover-inner {
			.popover-header {
				background-color: $mint;
				padding: 1rem 1.5rem;
				border-block-end-radius: 1.2rem;
				border-block-start-radius: 1.2rem;
				h4 {
					font-size: 22px;
					font-weight: 500;
					color: $primary;
				}
				h5 {
					font-size: 18px;
					font-weight: 500;
					color: $primary;
				}
				.user-chat-nav {
					display: none;
				}
			}
			.popover-body {
				.wrapEndeavorButtons {
					button {
						text-align: start;
						border: 0;
						width: 100%;
						color: $textGrey;
						padding-inline-start: 2.5rem;
						background: url(../../images/icons/quoteTLtr.svg);
						background-repeat: no-repeat;
						background-position: 8px 0.47rem;
						background-size: 1.3rem auto;
						border-bottom: 1px solid $lightGrey;
						&:last-child {
							border-bottom: 0px solid $lightGrey;
						}
						&:hover {
							//background-color: #eaeaea;
							color: $primary;
						}
					}
				}
			}
		}
		.popover-arrow {
			display: none;
		}
	}
	&.popoverUser {
		.popover {
			max-height: 67vh;
			.popover-inner {
				.popover-body {
					&::-webkit-scrollbar {
						width: 5px;
						overflow: hidden;
						display: block;
					}
					&::-webkit-scrollbar-track {
						background-color: #ffffff00 !important;
						border-radius: 7px;
					}
					&::-webkit-scrollbar-thumb {
						background-color: #00000025 !important;
						border-radius: 7px;
					}
					&::placeholder {
						font-size: 17px;
					}

					height: 28rem;
					overflow-y: auto;
				}
			}
		}
	}
}

/*פופאפ השתדלות*/
.modal-dialog.endeavorWrapper {
	border: 0;
	max-width: 470px;
	box-shadow: 0 3px 6px rgb(0 0 0 / 15%);
	border-radius: 1.2rem;
	z-index: 1200;
	text-align: start;
	@media screen and (max-width: 780px) {
		top: auto;
		bottom: 0;
		align-items: flex-end;
	}
	.modal-content {
		@media screen and (max-width: 780px) {
			height: 65vh;
			border-radius: 1.2rem 1.2rem 0 0;
			padding-bottom: env(safe-area-inset-bottom);
		}
		.user-chat-nav {
			display: none;
		}
		.modal-header {
			background-color: $mint;
			padding: 1rem 1.5rem;
			border-top-right-radius: 1.2rem;
			border-top-left-radius: 1.2rem;
			text-align: start;
			.modal-title {
				flex-direction: column;
				align-items: flex-start;
				text-align: start;
				@media screen and (max-width: 780px) {
					max-width: 95%;
				}
				h4 {
					font-size: 22px;
					font-weight: 500;
					color: $primary;
					text-align: start;
					@media screen and (max-width: 780px) {
						font-size: 20px;
					}
				}
				h5 {
					font-size: 18px;
					font-weight: 500;
					color: $primary;
					text-align: start;
					@media screen and (max-width: 780px) {
						font-size: 16px;
					}
				}
				.user-chat-nav {
					position: absolute;
					top: 0.4rem;
					right: 0.5rem;
					display: block;
				}
			}
		}
		.modal-body {
			padding-top: 1rem;
			@media screen and (max-width: 780px) {
				&::-webkit-scrollbar {
					width: 5px;
					overflow: hidden;
					display: block;
				}
				&::-webkit-scrollbar-track {
					background-color: #ffffff00 !important;
					border-radius: 7px;
				}
				&::-webkit-scrollbar-thumb {
					background-color: #00000025 !important;
					border-radius: 7px;
				}
				&::placeholder {
					font-size: 17px;
				}
				overflow-y: auto;
			}
			.wrapEndeavorButtons {
				button {
					text-align: start;
					border: 0;
					width: 100%;
					color: $textGrey;
					padding-inline-start: 2.5rem;
					background: url(../../images/icons/quoteTLtr.svg);
					background-repeat: no-repeat;
					background-position: 8px 0.47rem;
					background-size: 1.3rem auto;
					border-bottom: 1px solid $lightGrey;
					&:last-child {
						border-bottom: 0px solid $lightGrey;
					}
					&:hover {
						//background-color: #eaeaea;
						color: $primary;
					}
				}
			}
		}
	}
}

.modal-backdrop.fade.show.fadeBlurEndeavor {
	@media screen and (max-width: 780px) {
		backdrop-filter: blur(6px);
		background: #000000;
	}
}

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wdth,wght@75,400;75,500;75,600;75,700;75,800&display=swap");

.loginPagesWrapper.homeConnect {
	padding: 4rem;
	padding-top: 80px;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	flex-direction: column;
	padding-inline-start: 9%;
	position: fixed;
	position: relative;

	&.homeAsBG {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100vh;
		&:after {
			content: "";
			transition: background-color 300ms linear;
			background-color: #000000b9;
			position: fixed;
			width: 100%;
			height: 100%;
			left: 0;
			top: 0;
			z-index: 2000;
			pointer-events: none;
		}
		@media screen and (max-width: 780px) {
			display: none;
		}
	}

	@media screen and (max-width: 1300px) {
		padding-inline-start: 6%;
	}

	.siteLogo {
		width: 16%;
		margin-top: -3rem;
		z-index: 40;
		@media screen and (max-width: 1445px) {
			width: 13%;
			margin-top: -4rem;
		}
	}
	.textForDesktop,
	label,
	.h1,
	.h2,
	.h3,
	.h4,
	.h5,
	.h6,
	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	a {
		font-family: "Open Sans", sans-serif;
	}

	.homeContent {
		width: 100%;
		display: flex;
		flex-direction: row;
		.wrapContentLP {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			width: 50%;
			z-index: 5;

			.textForDesktop {
				margin-top: 0;
				h1 {
					font-size: 12rem;
					font-weight: 800;
					line-height: 0.9;
					white-space: pre-line;
					@media screen and (max-width: 1445px) {
						font-size: 8rem;
					}

					@media screen and (max-width: 1200px) {
						font-size: 6rem;
					}

					.specialColor {
						color: $secondary;
						display: inline;
						position: relative;
						padding-inline-start: 1.4rem;
						&:before {
							content: "";
							position: absolute;
							width: calc(100% + 25px);
							height: 1rem;
							background-image: url("../../images/underline.svg");
							bottom: -4px;
							background-repeat: no-repeat;
							left: -25px;
						}
					}
				}
				h2 {
					font-size: 4.2rem;
					@media screen and (max-width: 1445px) {
						font-size: 2.5rem;
					}
				}
				h3 {
					font-weight: 700;
					font-size: 4.2rem;
					margin-top: 6.4rem;
					@media screen and (max-width: 1445px) {
						font-size: 3rem;
					}
				}
				h6 {
					font-size: 2.2rem;
					white-space: pre-line;
					margin-bottom: 4rem;
				}
				.phoneLP {
					margin-top: 2rem;
					display: flex;
					flex-direction: row;
					width: 100%;
					.phone-container {
						margin-bottom: 0 !important;
						.phone-inner-container {
							flex-direction: row-reverse;
						}
						input[type="tel"] {
							font-size: 3rem;
							border: 1px solid $primary;
							padding: 0.7rem 1rem;
							border-radius: 0 15px 15px 0;
							text-align: start;
							direction: ltr;
							color: $primary;
							width: auto;
							&:focus-visible {
								border: 1px solid $primary;
								outline: 0;
							}
							@media screen and (max-width: 1445px) {
								font-size: 2rem;
							}
						}
						input[type="text"],
						span {
							font-size: 3rem;
							border: 1px solid $primary;
							color: $primary;
							padding: 0.7rem 1.7rem;
							border-radius: 15px 0 0 15px;
							text-align: center;
							direction: ltr;
							border-right: 0;
							width: auto;
							&:focus-visible {
								border: 1px solid $primary;
								outline: 0;
							}
							@media screen and (max-width: 1445px) {
								font-size: 2rem;
							}
						}
					}
					button {
						width: auto;
						background: $specialBtn !important;
						font-size: 3rem;
						font-weight: 600;
						font-family: "Open Sans", sans-serif;
						color: #fff;
						border: 0;
						border-radius: 15px;
						padding: 0 4%;
						margin-inline-start: 2rem;
						@media screen and (max-width: 1445px) {
							font-size: 2rem;
						}
					}
				}
			}
		}

		.wrapstats {
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: center;
			position: absolute;
			width: 100%;
			height: calc(100% - 8rem);
			bottom: 0;
			left: 0;
			z-index: -1;
			@media screen and (max-width: 780px) {
				display: none;
			}
			.stats {
				width: auto;
				color: #fff;
				text-align: center;
				border-radius: 4rem 4rem 4rem 0;
				padding-top: 4rem;
				padding-bottom: 6rem;
				position: relative;
				right: 0rem;
				padding-right: 6%;
				padding-left: 6%;
				span {
					font-size: 4rem;
					font-weight: 900;
					font-family: "Open Sans", sans-serif;
					@media screen and (max-width: 1445px) {
						font-size: 3rem;
					}
				}
				h6 {
					color: $white;
					font-size: 2rem;
					line-height: 0;
				}
				&.pinky {
					background: $specialBtn;
					width: 100%;
				}
				&.whitey {
					background: $white;
					border-radius: 4rem 4rem 0 4rem;
					width: auto;
					padding-top: 3rem;
					padding-bottom: 3.5rem;
					color: $primary;
					top: -2rem;
					right: -5rem;
					padding-right: 4%;
					padding-left: 4%;
					h6 {
						color: $primary;
					}
					span {
						font-size: 4rem;
						@media screen and (max-width: 1445px) {
							font-size: 3rem;
						}
					}
				}
			}
			.pinkyWhite {
				position: absolute;
				width: 23rem;
				transform: translate(-96%, 33%);
				@media screen and (max-width: 1445px) {
					width: 13%;
					transform: translate(-83%, 33%);
				}
			}
			.imageTwo {
				position: absolute;
				top: 0;
				width: 100%;
				height: 100%;
				left: 0;
				z-index: 0;
				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
					object-position: center;
				}
				@media screen and (max-width: 780px) {
					display: none;
				}
			}
		}
	}

	.headerHome {
		position: fixed;
		top: 0;
		width: 100%;
		height: 80px;
		left: 0;
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 5rem;
		justify-content: flex-end;
		padding-inline-end: 9%;
		font-size: 20px;
		color: $primary;
		z-index: 10;
		background-color: #fff;
		&:before {
			content: "";
			width: 100%;
			height: 0.5rem;
			background: $jsmartGradientTriple;
			top: 0;
			bottom: auto;
			position: absolute;
			left: 0;
		}
		a {
			font-family: "Open Sans", sans-serif;
			font-weight: 700;
			font-size: 2.2rem;
			&:nth-child(4) {
				color: $specialBtn;
			}
		}
	}
	label {
		color: $grey;
		width: 54%;
		&.labelPrivacy {
			width: 100%;
		}
	}
	a {
		font-weight: 800;
	}
	.imgBtn,
	.title {
		display: none;
	}
	&::before {
		display: none;
	}
	label.termsPrivacy {
		font-size: 1.7rem;
	}
	@media screen and (max-width: 780px) {
		justify-content: space-between;
		height: 93vh;
		background-image: none;
		padding: 0;
		padding-top: env(safe-area-inset-top);
		@media screen and (max-width: 380px) {
			height: 89vh;
		}
		.textForDesktop,
		.headerHome {
			display: none;
		}
		.siteLogo {
			width: 100%;
			height: 25vh;
			display: flex;
			align-items: center;
			justify-content: center;
			margin-top: 0;
			img {
				width: 65%;
				object-fit: contain;
				object-position: center;
			}
		}
		.title {
			width: 100%;
			display: flex;
			text-align: center;
			justify-content: center;
			align-items: center;
			padding: 0 5%;
			h3 {
				font-size: 3rem;
				@media screen and (max-width: 420px) {
					font-size: 2.4rem;
				}
			}
		}
		.wrapImgBtn {
			height: 70%;
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: flex-end;
			flex-direction: column;
			.wrapGradient {
				background: $jsmartGradientTop;
				width: 100vw;
				height: 60%;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				align-items: center;
				.imgBtn {
					width: 100%;
					display: flex;
					justify-content: center;
					align-items: flex-end;
					height: 70%;
					position: relative;
					top: -46px;
					&:after {
						content: "";
						height: 34vh;
						width: 34vh;
						top: 0;
						box-shadow: 0 0 0 1px $primary, 0 0 0 10px $white, 0 0 0 11px $primary,
							0 0 0 21px $white, 0 0 0 22px $primary, 0 0 0 32px $white, 0 0 0 33px $primary;
						border-radius: 50rem;
						position: absolute;
						transform: translateY(-93px);
						border: 10px solid $white;
						z-index: -8;
						@media screen and (max-width: 420px) {
							height: 29vh;
							width: 29vh;
						}
					}
					.circleImage {
						height: 34vh;
						width: 34vh;
						border-radius: 50rem;
						overflow: hidden;
						position: absolute;
						transform: translateY(-92px);
						border: 10px solid $white;
						top: 0;
						@media screen and (max-width: 420px) {
							height: 29vh;
							width: 29vh;
						}

						img {
							object-fit: cover;
							object-position: center;
							width: 100%;
							height: 100%;
						}
					}
					button.btn.btn-secondary {
						z-index: 50;
						border-radius: 15rem;
						background-color: $white !important;
						color: $secondary !important;
						font-size: 2.5rem;
						font-weight: 600;
						border: 0;
						padding: 0.7rem 4rem;
						filter: drop-shadow(0px 4px 3px #00000050);
						margin-bottom: 0;
						position: absolute;
						bottom: 0;
					}
				}

				.termsPrivacy {
					color: $white;
					width: 95%;
					font-size: 1.8rem;
					padding-bottom: 1.5rem;
					position: relative;
					bottom: 10px;
					text-align: center;
					line-height: 1.5;
					font-weight: 400;
					a,
					a strong {
						color: $white !important;
						font-weight: 400;
					}
					label {
						width: 100%;
						font-weight: 400;
						color: $white !important;
					}
					a {
						border-bottom: 1px solid;
						font-weight: 400;
					}
				}
			}
		}
	}
}

.termsPage {
	padding: 30px;
}

.select-reason-delete-account {
	cursor: pointer;
	height: 26px;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	&:hover {
		background-color: $primary;
		color: white;
	}
}

.modal-dialog.recievedFilter {
	.modal-content {
		.modal-header {
			padding-top: 0.5rem;
			.modal-title {
				h4 {
					font-weight: 500;
				}
			}
		}

		.modal-body {
			h5 {
				color: $textGrey;
				display: inline-block;
				&.boldTitle {
					font-weight: bold;
					border-bottom: 2px solid;
				}
			}
			.section.range.rangeFormailFilter {
				display: flex;
				flex-direction: row;
				.ageRangeBtn {
					display: flex;
					flex-direction: column;
					align-items: center;
					margin-bottom: 0 !important;
					.input-group {
						display: flex;
						flex-direction: column;
						justify-content: center;
						width: 80%;
						label {
							color: $textLightGrey;
							font-weight: 400;
							margin-bottom: 0;
							margin-top: 0.5rem;
						}
						.wrapInputNumber {
							display: flex;
							flex-direction: row;
							position: relative;
							.form-control {
								border: 1px solid $textLightGrey;
								border-radius: 5rem;
								text-align: center;
								font-size: 0.9rem;
								font-weight: 500;
							}
							button {
								position: absolute;
								&.plus {
									left: 0;
									right: auto;
									background-color: #0000 !important;
									border: 0;
									color: $secondary !important;
									font-size: 1.5rem;
									padding-top: 0;
									padding-bottom: 0;
									height: 100%;
								}
								&.minus {
									right: 0;
									left: auto;
									background-color: #0000 !important;
									border: 0;
									color: $secondary !important;
									font-size: 1.5rem;
									padding-top: 0;
									padding-bottom: 0;
									height: 100%;
								}
							}
						}
					}
				}
			}
			.section {
				display: flex;
				flex-direction: column;
				align-items: center;
				label {
					color: $textLightGrey;
					font-weight: 400;
					margin-bottom: 0;
					margin-top: 1rem;
				}
				.tinyTitle {
					font-size: 12px;
					margin-bottom: 0.5rem;
				}

				.buttonsSection {
					display: flex;
					flex-wrap: wrap;
					justify-content: center;
					gap: 0.6rem;
					button {
						border: 0px solid $textLightGrey;
						border-radius: 5rem;
						text-align: center;
						padding: 0.5rem 1rem;
						font-size: 0.9rem;
						font-weight: 500;
						filter: drop-shadow(0px 2px 3px #ccc);
						background: #fff;
						&:hover,
						&.active {
							background: $primary;
						}
					}
				}
			}
		}

		.modal-footer {
			button.littleBtn {
				padding: 0.2rem 1.5rem !important;
				border: 0;
				box-shadow: none;
				color: $primary;
				&:hover {
					background-color: transparent;
					color: $primary;
				}

				&.saveBtn {
					background: $secondary;
				}
			}
			.alert {
				color: $alert;
				text-align: center;
				line-height: 1.1;
				padding-top: 0;
			}
		}
	}
}

div.editDistance {
	margin-bottom: 10px;
}

.chatFooter,
.sendMessageModal,
.cardUserDialog {
	&.sendMessageModal .grow-wrap,
	&.cardUserDialog .grow-wrap {
		width: 100%;
	}
	.grow-wrap {
		display: grid;

		&::after {
			content: attr(data-replicated-value) " ";
			white-space: pre-wrap;
			visibility: hidden;
		}
		&.inputAndQuote > textarea {
			resize: none;
			overflow: hidden;
			&.multiRows {
				border-radius: 7px;
			}
			padding: 0.5rem 1rem;
		}
		& > textarea,
		&::after {
			border-radius: 7px;
			border-color: $primary;
			white-space: pre-wrap;
			padding: 0.5rem;
			font-family: inherit;
			grid-area: 1 / 1 / 2 / 2;
		}
	}
}

.dropdown-datePicker {
	display: flex;
	flex-direction: row-reverse;
	.dropdown-menu {
		max-height: 33rem;
	}
	.wraptxt {
		margin: 5px;
		button.dropdown-item {
			width: 100% !important;
			border-radius: 7px !important;
		}
	}
}

.promotionMessage {
	max-width: 250px;
	.specificMessage {
		color: #959492;
		text-align: center;
	}
	.generalMessage {
		color: $secondary;
		text-align: center;
	}
}
.userTagsPopover {
	.popover {
		max-width: 350px;
		border: 0;
		background: $primary;
		span.tagText {
			font-size: 16px;
			font-weight: bold;
		}
		.popover-header {
			border-top-left-radius: 7px;
			border-top-right-radius: 7px;
			background-color: #ffffff4a;
			color: #fff;
			position: relative;
			.closeButton {
				right: 0;
				top: -4px;
				position: absolute;
				font-size: 1rem;
				color: white;
				&:hover {
					color: white;
				}
				.tagsPopoverHeader {
					font-size: 16px;
				}
			}

			.popover-body {
				display: flex;
				flex-direction: column;
				justify-content: flex-start;
				align-items: flex-start;
				button {
					background-color: transparent !important;
					border-color: transparent !important;
					padding-inline-start: 2rem;
					.innerBtnTxt {
						position: relative;
						&:before {
							content: "";
							width: 1.5rem;
							height: 1.5rem;
							background-color: #ffffff4a;
							position: absolute;
							transform: translateX(132%);
							border-radius: 5rem;
						}
						&:after {
							content: "";
							left: 0;
							width: 1.5rem;
							height: 1.5rem;
							position: absolute;
							transform: translateX(132%);
							border-radius: 5rem;
							background-size: 50%;
							background-repeat: no-repeat;
							background-position: center;
						}
					}
					&.searchTag {
						.innerBtnTxt:after {
							background-image: url(../../images/icons/search.svg);
							filter: $finvert;
						}
					}
					&.addTag {
						.innerBtnTxt:after {
							background-image: url(../../images/icons/plusTag.svg);
							filter: $finvert;
						}
					}
				}
			}

			&.bs-popover-auto > .popover-arrow::after {
				border-top-color: $primary;
			}
		}
	}
}

.feedbackButton {
	z-index: 2000;
	position: fixed;
	top: 219px;
	left: 0;
	height: 71px;
	width: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	span {
		display: inline-block;
		transform: rotate(90deg);
		font-size: 15px;
	}
}

.bigSlideDialog.ContactFeedbackModal {
	min-height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #ffffff00;
	top: 0;
	max-width: 600px;

	@media screen and (max-width: 780px) {
		max-width: 350px;
	}
	.modal-content {
		border-radius: 20px;
		height: min-content;
		.ContactModalContainer {
			padding: 20px;

			.mainTitle {
				font-weight: 600;
				margin-bottom: 40px;
			}
			.form-inline {
				position: relative;
				.form-label {
					margin-bottom: 0.2rem;
					color: $secondary;
				}
				.numrest {
					float: right;
					font-size: 0.6rem;
					margin-top: -0.8rem;
					color: $textGrey;
					text-align: end;
					position: absolute;
					bottom: -15px;
					left: 5px;
				}
				#email {
					@media screen and (min-width: 780px) {
						width: 50%;
					}
				}
			}
			.wrapMainBtn {
				display: flex;
				justify-content: center;
				align-items: center;
				margin: 40px;
				.mainAccountBtn {
					width: 50%;
					border-radius: 25px;
					min-width: 200px;
				}
			}
			.wrapUploadImg {
				display: flex;
				justify-content: space-between;
				align-items: start;
				flex-direction: column;
				gap: 10px;
				img {
					max-width: 300px;
				}
			}
		}
	}
}

.conversation-list p.massletter-text,
.conversation-list span.massletter-text {
	color: #bdbbbb;
}

.unsubscribe-page {
	text-align: center;
	max-width: 560px;
	padding: 12px;
	h5 {
		margin-top: 20px;
	}
	.buttons {
		margin-top: 15px;
		width: 180px;
		display: flex;
		justify-content: space-around;
		button {
			min-width: 65px;
		}
	}
	.switchDesc {
		.wrapSwitch {
			display: flex;
			flex-direction: row;
			align-items: center;
			gap: 0.5rem;
			padding-top: 0.5rem;
			padding-bottom: 0.5rem;
			h6 {
				margin-bottom: 0;
				color: #999;
				text-align: start;
			}

			.form-check-input:checked {
				background-color: $primary;
				border-color: $primary;
				~ h6 {
					color: $primary;
				}
			}

			.form-check-input:focus {
				box-shadow: none;
			}
		}
	}
}
