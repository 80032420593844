.wrapArticlePage {
	width: 100%;
	height: 100%;
	padding-top: 100px;
	min-height: calc(100vh - 100px);
	position: relative;

	.skeleton {
		transform: scale(1);
		width: 100%;
		height: 100%;
	}

	.bigImg {
		display: block;
		width: 100%;
		min-height: 40vh;
		position: relative;
		padding: 0;
		margin: 0;

		.imgSkeleton {
			border-radius: 0;
			min-height: 40vh;
		}
		& > img {
			width: 100%;
			height: 100%;
			max-height: 40vh;
			object-fit: cover;
			animation: fadeIn 0.5s forwards;
		}
	}

	.articlePage {
		height: 100%;
		min-height: calc(60vh - 70px);
		width: 100%;
		display: flex;
		justify-content: center;
		background-color: #f8f8f8;
		position: relative;

		.articleWrapper {
			display: flex;
			flex-direction: column;
			gap: 30px;
			position: relative;
			top: -120px;
			width: 85%;
			max-width: 1150px;
			min-height: max-content;
			color: #000;
			text-align: right;
			font-family: "Assistant", sans-serif !important;
			font-style: normal;
			font-weight: 600;

			.wrapBackBlog {
				position: absolute;
				top: 0;
				right: -75px;
			}
			.wrapHeaderContent {
				width: 100%;
				height: 100%;
				padding: 70px 60px;
				border-radius: 20px;
				background-color: #fff;
				color: #000 !important;
				display: flex;
				flex-direction: column;
				.descText {
					font-size: 24px;
					font-weight: 600 !important;
					margin-bottom: 25px;
				}

				& > h2 {
					font-size: 70px;
					line-height: 100%;
					letter-spacing: -0.7px;
					margin: 0;
					margin-bottom: 25px;
					animation: fadeIn 0.5s forwards;
					color: #000;
				}

				.authorAndShare {
					animation: fadeIn 0.5s forwards;
					display: flex;
					justify-content: space-between;
					font-size: 20px;
					font-weight: 400;
					line-height: 1;

					.authorArticle {
						display: flex;
						gap: 16px;
						align-items: center;

						.name {
							border-radius: 5px;
							background: #e1e9f2;
							padding: 8px;
						}
						.withImg {
							display: flex;
							cursor: pointer;
							.wrapPublicUserImg {
								display: flex;
								justify-content: center;
								align-items: center;
								position: relative;
								margin: 0 0 0 10px;
								width: 40px;
								img {
									position: absolute;
									width: 45px;
									height: 45px;
									border-radius: 50%;
									object-fit: cover;
								}
							}
						}
						.wrapViewNDate {
							display: flex;
							gap: 16px;
						}
					}

					.btn {
						box-shadow: none;
						outline: none;
						background-color: #fff;
						border-radius: 20px;
						padding: 0px 10px;
						color: #000;
						font-size: 16px;
						font-weight: 400;
					}
				}

				.titleSkeleton {
					height: 50px;
					margin-bottom: 50px;
				}

				.authorSkeleton {
					height: min-content;
					width: 60%;
				}
			}

			.wrapContentAndMoreArt {
				display: flex;
				width: 100%;
				height: 100%;
				gap: 30px;
				& > div {
					border-radius: 20px;
					background-color: #fff;
				}

				.wrapContent {
					width: 100%;
					height: fit-content;
					padding: 70px 60px;
					// line-height: normal;
					font-size: 20px;
					font-weight: 400;
					.descText {
						font-size: 24px;
						font-weight: 600 !important;
					}
					.contentText {
						color: #000;
						font-size: 20px !important;
						font-weight: 500 !important;
						a {
							color: #000;
							text-decoration: underline 1px;
							font-family: "Assistant", sans-serif !important;
							font-weight: 500 !important;
						}
						p {
							color: #000;
							font-family: "Assistant", sans-serif !important;
							font-weight: 400 !important;
							width: fit-content;
							text-align: start !important;

							@media screen and(min-width:780px) {
								&:has(.side) {
									float: left;
									margin: 0px 20px 20px 0px;
								}
							}
						}
						img {
							max-width: 100%;
							object-fit: contain;
							height: min-content;
						}
					}
					.wrapFooterTags {
						display: flex;
						gap: 10px;
						flex-wrap: wrap;
						.tag {
							cursor: pointer;
							border-radius: 4px;
							background: #386ba826;
							border-radius: 5px;
							background: #e1e9f2;
							padding: 2px 12px;
							font-size: 18px;
							font-weight: 400;
							color: #5a5a5a;
							border: 0;
						}
					}
				}
				.moreArticle {
					background-color: #f8f8f8;
					display: flex;
					flex-direction: column;
					gap: 20px;
					width: 270px;
					height: 100%;
					min-width: 270px;
					position: relative;
					width: 100%;
					max-width: 350px;
					.landingBlog {
						padding: 0;
						.wrapBlogs {
							flex-direction: column;
							.cardBlog {
								cursor: pointer;
							}
						}
						.fadeInBlogs {
							animation: fadeIn 1.5s forwards;
						}
					}
				}
			}
			.wrapSuppliers {
				padding: 30px 0;
				background-color: #f8f8f8;
				.suppliersTitle {
					margin: 0;
					font-size: 20px;
				}
				.landingSatisfiedSuppliers {
					background-color: #f8f8f8;
					margin: 0;
					padding: 0;
					.swiper .swiper-slide .TalentBox {
						cursor: pointer;
						z-index: initial;
					}
				}
			}
		}
	}

	@media screen and (max-width: 780px) {
		.bigImg {
			position: relative;
			min-height: 200px;
			img {
				height: 200px;
			}
			.imgSkeleton {
				min-height: 200px;
			}

			.wrapShareAndBack {
				width: 100%;
				position: absolute;
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 25px;
				top: 0;
				z-index: 1;

				.wrapBackBlog {
					display: block;
					width: 30px;
					height: 30px;
					img {
						width: 30px;
						height: 30px;
						cursor: pointer;
						position: fixed;
					}
				}
				.btn {
					box-shadow: none;
					outline: none;
					cursor: pointer;
					background-color: #fff;
					border-radius: 20px;
					padding: 0px 10px;
					color: #000;
					font-size: 16px;
					font-weight: 400;
				}
			}
		}
		.articlePage {
			min-height: calc(100vh - 284px);
			background: #fff;
			.articleWrapper {
				top: 0;
				width: 100%;
				max-width: 100%;

				.wrapHeaderContent {
					padding: 24px;

					& > h2 {
						font-size: 32px;
						font-weight: 600;
						margin-bottom: 16px;
					}
					.descText {
						margin-bottom: 16px;
						font-size: 18px;
					}
					.authorAndShare {
						.authorArticle {
							color: #717171;
							flex-direction: column-reverse;
							align-items: start;
							.name {
								color: #000;
							}
						}
					}
				}

				.wrapContentAndMoreArt {
					flex-direction: column;
					padding-bottom: 30px;
					.wrapContent {
						padding: 0 24px;
						font-size: 18px;
						.contentText {
							font-size: 18px !important;
						}
					}
					.moreArticle {
						display: none;
					}
				}
				.wrapSuppliers {
					.suppliersTitle {
						text-align: center;
					}
					.swiper {
						padding-right: 24px;
					}
				}
			}
		}
	}
}
