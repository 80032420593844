.wrapEmailPopup {
	.wrapTitle {
		div {
			font-size: 1.063rem;
			color: #8b8b8d;
		}
		h1 {
			margin-top: 10px;
			text-align: center;
			font-weight: 600;
			margin-bottom: 1.5rem;
			font-size: 3rem;
		}
		h2 {
			margin-top: 10px;
			text-align: center;
		}
	}
}
.editMail {
	div {
		font-size: 1.063rem;
		color: #5863be;
	}
}
.wrapMailVerified {
	.wrapInputValidation {
		position: relative;
		display: flex;
		flex-direction: column;
		cursor: pointer;
		align-items: center;

		.form-inline {
			width: 100%;
			.form-label {
				font-size: 1.5rem;
				color: #5863be;
			}
			.mb-3 {
				font-size: 1.5rem;
				color: #5863be;
			}
		}
		.validation {
			position: absolute;
			bottom: 30px;
			width: 100%;
			float: left;
			width: max-content;

			span.error {
				color: red;
				padding-inline-start: 20px;
				font-size: 12px;
				&:before {
					content: "";
					background-image: url("../../../images/icons/error.svg");
					width: 13px;
					height: 13px;
					position: absolute;
					right: 5px;
					left: auto;
					background-size: 100%;
					background-repeat: no-repeat;
					background-position: center;
				}

				&.email:before {
					content: "\ee85";
					background-image: none;
					width: auto;
					height: auto;
					font-family: "remixicon" !important;
					font-size: 12px;
					top: 3px;
					line-height: 1;
				}
			}
		}
	}
	.emailDidntSent {
		.validation {
			bottom: -7px;
			align-self: start;
		}
	}
}

.wrapInputValidation {
	.form-inline {
		.mb-3 {
			input {
				color: #5863be !important;
			}
		}
	}
	.validation {
		.email {
			font-weight: 400 !important;
		}
	}
}

@media screen and (min-width: 780px) {
	.loginHolder > .wrapTitle {
		display: flex;
		height: 100%;
		.emailDesc {
			align-items: center;
			justify-content: center;
			display: flex;
			width: 100%;
			text-align: center;
		}
	}
}
