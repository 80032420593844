$font-family-secondary: "Rubik", sans-serif;
// stylelint-disable value-keyword-case
$font-family-sans-serif: "Rubik", sans-serif;
$font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New",
	monospace;
// stylelint-enable value-keyword-case
$font-family-base: $font-family-sans-serif;
$font-family-code: var(--#{$variable-prefix}font-monospace);
// Font Weight
$font-weight-normal: 400;
$font-weight-thin: 300;
//Shadows
$box-shadow: 0 0rem 36px #e1e6f1;
$big-box-shadow: 0 3rem 36px #e1e6f1;

/*=========================================================================*/
/*colors map*/
$primary: #5863be;
$secondary: #f7239a;
$midBlue: #6a5dbb;
$primaryGradint: #446bc3;
$success: #67c41b;
$require: #f6a122;
$alert: #ff0000;
$specialBtn: $secondary;
//$specialBtn: #ff445c;
$black: #000000;
$darkGrey: #4c4c4c;
$grey: #676767;
$textGrey: #8b8b8d;
$textLightGrey: #cccccc;
$disabled: #cccccc;
$lightGrey: #e4e4e4;
$lightBlue: #eef2fb;
$mint: #f1f6f7;
$lightBlue: #eef2fb;
$white: #ffffff;
//Gradients
$jsmartGradient: linear-gradient(89deg, $primary 0%, $secondary 100%);
$jsmartGradientTop: linear-gradient(0deg, $primary 60%, $secondary 95%);
$jsmartGradientDiagonal: linear-gradient(40deg, $secondary 0%, $secondary 50%, $primary 80%);
$jsmartGradientTriple: linear-gradient(
	89deg,
	$primary 0%,
	$primary 70%,
	$midBlue 90%,
	$secondary 100%
);

$jsmartGradientHeader: linear-gradient(89deg, $primaryGradint 0%, $secondary 100%);
$jsmartGradientRtl: linear-gradient(89deg, $secondary 0%, $primaryGradint 100%);
$jsmartGradientTripleRtl: linear-gradient(
	89deg,
	$secondary 0%,
	$midBlue 28%,
	$primaryGradint 49%,
	$primaryGradint 100%
);

$jsmartGradientHeaderRtl: linear-gradient(89deg, $secondary 0%, $primaryGradint 100%);

$mobileHeaderGradient: radial-gradient(circle at 35% 94px, $secondary, $primaryGradint 26%);
$mobileHeaderGradientRtl: radial-gradient(circle at 68% 94px, $secondary 3%, $primaryGradint 26%);

$gradientBtn: linear-gradient(89deg, $success 0%, $success 60%, $secondary 100%);
$gradientBtnRtl: linear-gradient(89deg, $secondary 0%, $success 40%, $success 100%);
$gradientGold: linear-gradient(
	90deg,
	rgba(249, 187, 87, 1) 0%,
	rgba(245, 226, 195, 1) 50%,
	rgba(249, 187, 87, 1) 100%
);

$jsmartGradientMain: linear-gradient(-38deg, #5863be 43%, #f7239a 86%);

//Filters

$fprimary: invert(37%) sepia(14%) saturate(3683%) hue-rotate(203deg) brightness(95%) contrast(77%);
$fsecondary: invert(32%) sepia(99%) saturate(4223%) hue-rotate(309deg) brightness(97%)
	contrast(101%);
$fmint: invert(91%) sepia(9%) saturate(301%) hue-rotate(172deg) brightness(110%) contrast(93%);
$finvert: invert(100%);
$fdarkGrey: invert(29%) sepia(0%) saturate(2708%) hue-rotate(133deg) brightness(120%) contrast(61%);
$fgrey: invert(44%) sepia(0%) saturate(763%) hue-rotate(157deg) brightness(91%) contrast(96%);
$fspecialBtn: $fsecondary;
$fsuccess: invert(58%) sepia(7%) saturate(6122%) hue-rotate(51deg) brightness(115%) contrast(79%);

// scss-docs-start colors-map
$colors: (
	"primary": $primary,
	"secondary": $secondary,
	"grey": $grey,
	"success": $success,
);
// scss-docs-end colors-map

// scss-docs-start theme-colors-map
$theme-colors: (
	"primary": $primary,
	"secondary": $secondary,
	"grey": $grey,
	"success": $success,
);
