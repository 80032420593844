.isManager {
	border: 12px solid yellow;
}

.user-profile-sidebar {
	transition: transform ease-out 300ms;
	transform: translateX(100%);
	position: fixed;
	right: 0;
	flex-direction: column;
	display: flex;
	z-index: 1025;
	top: 100px;
	min-width: 500px;
	max-width: 500px;

	@media (max-width: 991.98px) {
		width: 100%;
		top: 0;
	}
}

.user-profile-sidebarOpen {
	transform: translateX(0%);
	@media (max-width: 991.98px) {
		height: 100vh;
		max-height: 100vh;
		max-width: 100%;
		z-index: 1025;
	}
}

ul.jsmartIconsMenu {
	align-items: center;
	display: flex;
	justify-content: space-between;
	gap: 0.4rem;

	@media screen and (max-width: 1024px) {
		gap: 0rem;
	}
}

.alignCenterHeight {
	align-items: center;
}

.dropMenuTop {
	.dropdown-menu {
		transform: translate3d(0px, 40px, 0px) !important;
	}
}

@media (max-width: 991.98px) {
	.wrapContactsPanel h6.mb-4 {
		margin-bottom: 0.5rem !important;
	}
}

@media (max-width: 991.98px) {
	.user-profile-sidebar {
		.simplebar-mask {
			height: calc(100vh - 56px) !important;
		}
	}
}

/*Modal for mobile*/

@media (max-width: 991.98px) {
	.modal {
		.modal-content {
			border-width: 0;
		}

		.modal-body {
			padding: 0rem;
		}

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}

		.carousel-control-prev,
		.carousel-control-next {
			.sr-only {
				display: none;
			}
		}
	}
}

.w-70.slide {
	width: calc(100% - 500px) !important;
}

@media (max-width: 1200px) {
	.wrapContactsPanel {
		width: 25rem;
	}

	.user-profile-sidebar.user-profile-sidebarOpen {
		min-width: 0;
		width: 19rem;
		border-right: 0px solid #f0eff5;
		border-left: 4px solid #f0eff5;
	}
	.w-70.slide {
		width: calc(100% - 19rem) !important;
	}

	.simplebar-content-wrapper {
		overflow-y: scroll !important;
		overflow-x: hidden !important;
	}

	.chat-conversation .conversation-list {
		max-width: 75%;
	}
}

@media (max-width: 780px) {
	.wrapContactsPanel {
		width: 100%;
		max-width: 100%;
	}
}

@media (max-width: 780px) {
	.user-profile-sidebar.user-profile-sidebarOpen {
		width: 100% !important;
	}
}

@media (max-width: 420px) {
	.w-70.slide {
		width: 100% !important;
	}

	.user-profile-sidebar.user-profile-sidebarOpen {
		width: 100% !important;
	}

	.chat-conversation .conversation-list {
		max-width: 100%;
	}
}

@media screen and (max-width: 991.98px) {
	.listOfDesc {
		margin-bottom: 4rem;
	}
}
.pac-container {
	z-index: 5000;
}
