//
// _buttons.scss
//

button,
a {
	outline: none !important;
	color: $primary;
	font-family: $font-family-base;
}

// Rounded Buttons
.btn-rounded {
	border-radius: 30px;
	border-radius: 30px;
}

.btn-dark,
.btn-secondary {
	color: $gray-200 !important;
}

.btn-outline-light {
	color: $gray-900;
}

/*Ayelet*/
.btn-outline-primary {
	color: $primary;
	border-color: $primary;

	&:hover,
	&:active {
		background: $primary;
		color: #fff;
	}

	&:active {
		border-color: $primary;
	}

	&:active:focus {
		box-shadow: 0 0 0 0.15rem #4068c080;
	}
}

//Ayelet-custom-btn
.littleToggleIconBtn {
	border-radius: 5rem;
	color: $secondary;
	padding: 0;
	width: 1.5rem;
	height: 1.5rem;
}
