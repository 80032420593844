.loginDialog {
	position: relative;
	max-width: none !important;
	min-width: 350px;
	width: calc(100vw - 50px);
	@media screen and (min-width: 780px) {
		width: 700px;
	}
	.modal-content {
		height: 90vh;
		overflow: scroll;

		.modal-body {
			padding: 0 1.5rem;
			.loginPagesWrapper {
				height: 100%;
				margin: 20px 0;
				&::before {
					display: none;
				}
				.loginPopup {
					border: none;
					width: 100%;
					height: 100%;
					font-size: 1.3rem;
					.loginHolder {
						padding: 30px 0 0;
						@media screen and (min-width: 780px) {
							padding: 0 4rem 4rem;
						}
						.vi__container--default {
							height: 4rem;
							@media screen and (max-width: 550px) {
								gap: 0.5rem;
							}
						}
						.vi__container--default .vi__character--default {
							font-size: 1.6rem;
						}

						.imageForPage {
							max-height: 200px;
						}
						.backBtn {
							display: none;
						}
						.innerLoginWrapper {
							h5 {
								font-size: 1.1rem;
							}
							.wrapTitleOfQuest {
								h5 {
									font-size: 1.5rem;
								}
								h1 {
									font-size: 1.7rem;
								}
							}
							button {
								font-size: 1.8rem;
								margin-top: 2rem;
							}
							.phone-container
								.phone-inner-container
								.PhoneInput
								.PhoneInputCountry
								.PhoneInputCountrySelect {
								width: 100px;
							}
						}
						label {
							font-size: 1rem;
						}
					}
				}
			}

			.loginStepsClass {
				padding: 50px 0 0;
				.backBtn {
					position: absolute;
					top: 0;
					right: 0;
					padding: 0 4px;
				}
			}
		}
	}
	.closePopup {
		position: absolute;
		top: 1.5rem;
		right: 1.5rem;
		cursor: pointer;
		z-index: 1;
	}
}

.loginStepsClass.loginPopup {
	.loginHolder .innerLoginWrapper {
		.dropdown-datePicker {
			display: flex;
			flex-direction: row-reverse;
			.dropdown-menu {
				max-height: 20rem;
				min-width: 0;
			}
			.wraptxt {
				margin: 5px;
				button.dropdown-item {
					width: 100% !important;
					border-radius: 7px !important;
				}
			}
		}
		.wraptxt {
			margin: 20px 0;
		}
		div {
			width: 100%;
		}
	}

	.imageForPage {
		margin: 0 0 20px !important;
		width: 100% !important;
		height: 40% !important;
		min-height: 200px;
		position: relative;
		img {
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			object-fit: contain;
			object-position: center;
		}
		&.fullWidth {
			width: calc(100% + 6rem);
			margin: 0 -6rem;
			height: 60%;
			margin-top: 3rem;
			img {
				object-fit: contain;
				object-position: left;
			}
		}

		&.bottom {
			img {
				object-position: bottom;
			}
		}
	}

	.loginHolder .buttonGroup {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		gap: 1.5rem;
		align-items: center;
		justify-content: flex-start;
		button {
			border-radius: 50px;
			font-size: 1.4rem !important;
		}
	}
}
