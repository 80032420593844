/*Ayelet*/
//@media (max-width: 576px) { 

            .carousel.galleryCarousel {
                height:100%;
                display: flex;
                justify-content: center;

                span.sr-only {
                    display:none;
                }

                .carousel-indicators {
                    gap:1rem;
                    transform: translate(0%,0%);
                    flex-direction: row-reverse;
                    flex-wrap: nowrap;
                    right:0;
                    left:0;
                    margin-bottom:1.5rem;

                    @media screen and (max-width: 780px) {
                        gap:0rem;
                        margin-right:0;
                        margin-left:0;
                    }

                    button {
                        width: 1rem;
                        height: 1rem;
                        background: #fff;
                        border-radius: 5rem;
                        padding:0;
                        border-top:0;
                        border-bottom:0;

                    }

                    button.active {
                        background: $secondary;
                    }
                }

                .carousel-control-prev {
                    transform: translateX(-100%);
                }

                .carousel-control-next {
                    transform: translateX(100%);
                }

                .carousel-inner {
                    height:100%;

                    .carousel-item {
                        height:100%;
                        overflow: hidden;
                        transition: transform 300ms ease;     
                        
                        img {
                            object-fit: cover;
                            object-position: center;                         
                            height: 100%;
                            width: 400px;
                        }
                    }

                    .jump {
                        transition: transform 0ms ease;
                }
            }


   }

//}
