/*Ayelet*/
@media (min-width: 576px) {

    .modal-dialog.galleryModal  {     
        width: 400px; 

        .modal-content {
            height:482px;
            border:0;
            border-radius: 0.5rem 2rem;
            background:transparent;

            .modal-body {
                padding:0;               
            }
        }
    }

}

@media screen and (max-width: 780px) { 
   
            .modal-backdrop {
                z-index:1550;
            }
        .modal {
            z-index:1560;

            }          
       }