//
// avatar.scss
//

.avatar-xs {
	height: 2.2rem;
	width: 2.2rem;
}

.avatar-sm {
	height: 3rem;
	width: 3rem;
}

/*Ayelet*/
.avatar-mm {
	height: 4.4rem;
	width: 4.4rem;
	@media screen and (max-width: 780px) {
		height: 5rem;
		width: 5rem;
	}
}

.avatar-md {
	height: 4.5rem;
	width: 4.5rem;
}

.avatar-lg {
	height: 6rem;
	width: 6rem;
}

.avatar-xl {
	height: 7.5rem;
	width: 7.5rem;
}

.avatar-title {
	align-items: center;
	background-color: $primary;
	color: $white;
	display: flex;
	font-weight: $font-weight-medium;
	height: 100%;
	justify-content: center;
	width: 100%;
}
