//
// user chat.scss
//

// user chat

.user-chat {
	background-color: $card-bg;
	transition: all 0.4s;
	padding-top: 100px;
	@media (max-width: 991.98px) {
		position: fixed;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		visibility: hidden;
		transform: translateX(100%);
		z-index: 99;
		padding-top: 0px;

		z-index: 1025;
		visibility: visible;
		transform: translateX(0);

		&.user-chat-show {
			visibility: visible;
			transform: translateX(0);
			z-index: 1025;
		}
	}
}

.user-chat-nav {
	.nav-btn {
		height: 40px;
		width: 40px;
		line-height: 40px;
		box-shadow: none;
		padding: 0;
		font-size: 20px;
		color: $gray-600;
		top: env(safe-area-inset-top);
	}
	/*Ayelet*/
	.nav-btn {
		position: relative;
	}
	@media (max-width: 575.98px) {
		display: flex;
		justify-content: flex-end;
	}
}

.chat-conversation {
	li {
		clear: both;
	}

	.chat-avatar {
		margin: 0 16px 0 0 /*rtl:0 0 0 16px*/;

		img {
			width: 36px;
			height: 36px;
			border-radius: 50%;
		}
	}

	.chat-day-title {
		position: relative;
		text-align: center;
		margin-bottom: 24px;
		margin-top: 12px;

		.title {
			background-color: $border-color;
			position: relative;
			font-size: 13px;
			z-index: 1;
			padding: 6px 12px;
			border-radius: 5px;
		}

		&:before {
			content: "";
			position: absolute;
			width: 100%;
			height: 1px;
			left: 0;
			right: 0;
			background-color: $border-color;
			top: 10px;
		}

		.badge {
			font-size: 12px;
		}
	}

	.conversation-list {
		margin-bottom: 24px;
		display: inline-flex;
		position: relative;
		align-items: flex-end;
		/*Ayelet*/
		max-width: 50%;
		@media (max-width: 991.98px) {
			max-width: 100%;
		}

		.ctext-wrap {
			display: flex;
			margin-bottom: 10px;
		}

		.ctext-wrap-content {
			padding: 12px 20px;
			background-color: $mint;
			border-radius: 8px 8px 8px 0;
			color: $white;
			position: relative;

			&:before {
				content: "";
				position: absolute;
				border: 5px solid transparent;
				border-left-color: $mint;
				border-top-color: $mint;
				left: 0 /*rtl:auto*/;
				right: auto /*rtl:0*/;
				bottom: -10px;
			}

			/*Ayelet*/
			&:before {
				content: "";
				border: 0;
				background-image: url("../../../images/icons/shpitz.svg");
				filter: $fmint;
				width: 14px;
				height: 14px;
				background-repeat: no-repeat;
				background-size: 100%;
				bottom: -9px;
				transform: scaleX(1);
			}
		}

		.conversation-name {
			font-weight: $font-weight-medium;
			font-size: 14px;
		}

		.dropdown {
			.dropdown-toggle {
				font-size: 18px;
				padding: 4px;
				color: $gray-600;

				@media (max-width: 575.98px) {
					display: none;
				}
			}
		}

		.chat-time {
			font-size: 12px;
			margin-top: 4px;
			text-align: right;
			color: $gray-600;
		}

		.message-img {
			border-radius: 0.2rem;
			position: relative;

			.message-img-list {
				position: relative;
			}

			img {
				max-width: 150px;
			}

			.message-img-link {
				position: absolute;
				right: 10px /*rtl:auto*/;
				left: auto /*rtl:0*/;
				bottom: 10px;

				li {
					> a {
						font-size: 18px;
						color: $white;
						display: inline-block;
						line-height: 30px;
						width: 30px;
						height: 30px;
						text-align: center;
					}
				}
			}
		}
	}

	.right {
		.chat-avatar {
			order: 3;
			margin-right: 0px;
			margin-left: 16px;
		}

		.chat-time {
			text-align: left;
			color: rgba($white, 0.5);
		}

		.conversation-list {
			float: right;
			text-align: right;

			.ctext-wrap {
				justify-content: flex-end;

				.ctext-wrap-content {
					order: 2;
					background-color: $primary;
					color: $dark;
					text-align: right;
					border-radius: 8px 8px 0px 8px;

					&:before {
						border: 5px solid transparent;
						border-top-color: $primary;
						border-right-color: $primary;
						left: auto;
						right: 0px;
					}

					/*Ayelet*/
					&:before {
						content: "";
						border: 0;
						background-image: url("../../../images/icons/shpitz.svg");
						filter: $fprimary;
						width: 14px;
						height: 14px;
						background-repeat: no-repeat;
						background-size: 100%;
						bottom: -9px;
					}
				}
			}

			.dropdown {
				order: 1;
			}
		}

		.dot {
			background-color: $dark;
		}
	}
}

div.chat-conversation {
	/*Ayelet*/
	height: calc(100vh - (12.1rem + 100px));
	@media (max-width: 991.98px) {
		height: 100vh;
		top: 0px;
		bottom: -76px;
		background: #fff;
		padding-bottom: 135px !important;
		padding-top: 73px !important;
	}
}

.animate-typing {
	.dot {
		display: inline-block;
		width: 4px;
		height: 4px;
		border-radius: 50%;
		margin-right: -1px;
		background-color: $primary;
		animation: wave 1.3s linear infinite;
		opacity: 0.6;

		&:nth-child(2) {
			animation-delay: -1.1s;
		}

		&:nth-child(3) {
			animation-delay: -0.9s;
		}
	}
}

@keyframes wave {
	0%,
	60%,
	100% {
		transform: initial;
	}

	30% {
		transform: translateY(-5px);
	}
}
